* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.Learning-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f6f4f2;
}

.Lmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.textzdiv {
    width: 77.15%;
    height: auto;

    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.ptd1 {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    width: 25.74%;

    height: auto;
    margin-top: 0px;
}

.ptd1g {
    display: none;
}

.ptd2 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    position: relative;
    width: 57.7%;
    height: auto;
}

.kinboxdiv1 {
    width: 89%;
    height: 625px;

    margin: auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    /* background: #000; */
    margin-bottom: 80px;
    border-radius: 11px;
    /* padding-bottom: 66px; */
}

.lngodiv {
    display: flex;
    color: #ffffff;
    align-items: center;
    /* height: 100px; */
    margin-top: auto;
    margin-left: 61px;
}

.loimg {
    width: 80px;
    height: 31px;
    object-fit: cover;
}

.vlinex {
    border: 1px solid #ffffff;
    height: 31px;
    margin-right: 5px;
}

.lngodiv > p {
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 40px;
    font-weight: 500;
    position: relative;
    /* width: 309px; */
    /* height: 54px; */
}

.acxss {
    color: #ffffff;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin-left: 75px;
}

.learning-container-e {
    width: 89%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin: auto;
    /* margin-top: 61px; */
    background: rgba(176, 181, 190, 0.05);
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.horiline-l {
    width: 96%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 29px;
    /* margin-bottom: 15px; */
    border-style: solid;
    border-color: #13c1dd;
    border-width: 1px 0 0 0;
    height: 0px;
}

.mntrends-l {
    width: 100%;
    height: 22px;

    /* margin-top: 24px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.kinlots-l {
    display: flex;
    align-items: center;
    width: 30%;
}

.logo1s-l {
    width: 46px;
    height: 22px;
    background: url(../Assests/ldg1.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.vlines-l {
    border: 1px solid black;
    height: 22px;
    margin-right: 10px;
}

.hubps-l {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.ptrnds-l {
    color: #13c1dd;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.imgtboxt1-l {
    width: 100%;
    height: auto;
    margin-top: 44px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

.imgtboxt2-l {
    width: 100%;
    height: auto;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

/* .rbt1 {
    font-size: 15px;
    font-family: "HelveticaMedium", sans-serif;
    font-weight: 500;
    word-wrap: break-word;
    color: #df0000;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    
  } */
.rbt2-l {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    line-height: 29px;
    /* line-height: 32px; */
    font-weight: 500;
    width: 761px;
    margin-top: 0px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.ravit-l {
    display: flex;
    width: auto;

    align-items: center;
    /* justify-content: space-between; */
    margin-top: 10px;
    /* margin-right: 20px; */
}

.ravit-l > p {
    color: #727272;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-right: 20px;
}

.piyug1-l {
    width: auto;
    height: 21px;
    background: #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 20px;
}

.piyug1-l > p {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
}

/* .rbt3 {
    font-size: 12px;
    font-family: "HelveticaRegular", sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    color: #727272;
   
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    
  } */
.rtimgbox-l {
    width: 232px;
    height: 179px;
    background: grey;
    border-radius: 10px;
}

.story-l {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    position: relative;
    width: 828px;
    margin-top: 15px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.toputr-l {
    display: none;
}

.tiputr-l {
    display: none;
}

.publishcard {
    width: 89%;
    /* height: 331px; */
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(242, 242, 242, 0.5);
    border-radius: 20px;
    padding: 38px 24px;
}

.publp1div {
    width: 278px;
    height: auto;

    display: flex;
    flex-direction: column;
}

.pu1 {
    color: #ff5914;

    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */

    margin-bottom: 13px;
}

.pu2 {
    color: #151515;

    font-family: 'Noto-medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    width: 220px;
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */

    margin-bottom: 11px;
}

.pu3 {
    color: #151515;

    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 278px;
    line-height: 18px;
}

.publp2div {
    width: calc(100% - 278px);
}

/* .publp2div > div {
    width: 31.64%;
    height: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 21px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.publp2div > div > div {
    height: 55.4%;
    width: 100%;
    background: #000000;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
} */

.pubt1 {
    color: #151515;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* width: 262px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    padding-top: 18px;
}

.pubt2 {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    margin-top: 5px;
}

.mdivxram {
    display: none;
}

.mdivx {
    width: 89%;
    height: 570px;

    margin: auto;
    /* background: #000000; */
    background: rgba(249, 249, 249, 0.5);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.vtp1 {
    color: #151515;

    font-family: 'Noto-semibold', sans-serif;
    font-size: 36px;
    font-weight: 600;
    position: relative;
    margin-left: 39px;
    margin-top: 28px;
}

.vtp2 {
    display: flex;
    width: 52.3%;
    justify-content: space-between;
    margin-top: 36px;
    margin-left: 39px;
}

.vtp2 > p {
    color: #151515;
    text-align: left;
    font-family: 'Noto-medium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    position: relative;
}

.vtp2 > p::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    /* Adjust padding from text bottom */
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.vtp2 > p:hover::after {
    background-color: #d83760;
    bottom: -14px;
}

.und1 {
    border-style: solid;
    border-color: #979797;

    border-width: 1px 0 0 0;
    width: 100%;
    height: 0px;
    position: relative;
    margin-top: 14px;
    /* transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1); */
}

.movi1 {
    width: 100%;

    height: 166px;
    margin-top: 19px;
    /* margin-left: 10px; */
    display: flex;
    justify-content: space-between;
    padding-left: 39px;
}

.movp1 {
    width: 23%;

    height: 100%;
    /* margin-right: 20px; */
    /* border-radius: 10px; */
    background: grey;
}

.movp2 {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.mot1 {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;

    width: 92%;
}

.mot2 {
    color: #979797;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    margin-top: 20px;
}

.tiputrchu {
    display: none;
}

.publishcardram {
    display: none;
}

#topupa {
    display: none;
}

.apexpandyour-l {
    width: 71.4%;
    height: 287px;

    display: flex;
    margin: auto;
    margin-bottom: 80px;
    justify-content: space-between;
}

.apexpand1-l {
    width: 64.7%;
    height: 100%;
    background: #ffffff;
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding-left: 74px;
}

.apexpand1p1-l {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    position: relative;
    width: 90%;

    /* margin: auto; */
    margin-top: 48px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apexpand1p2-l {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-left: 0px;
    margin-top: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apemailinputbox-l {
    display: flex;

    width: 62.85%;
    justify-content: space-between;
    margin-left: 0px;
    margin-top: 45px;
    height: 32px;
}

#apputbox-l {
    /* background: transparent; */
    /* background: none; */
    border: none;
    /* Hide all borders */
    border-bottom: 1px solid #13c1dd;
    width: 74.4%;
    color: #151515;

    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;

    padding-left: 15px;
}

.apemailinputbox-l > button {
    background: #13c1dd;
    border-radius: 16px;
    width: 83px;
    /* height: 32px; */
    position: relative;
    color: #ffffff;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    border: none;
}

.apexpand2-l {
    width: 29.4%;
    height: 100%;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    display: flex;
    flex-direction: column;
}

.apexpand2p1-l {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 25px;
    font-weight: 500;
    position: relative;
    width: 77.5%;

    margin-left: 30px;
    margin-top: 48px;
    margin-bottom: 29px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apexpand2p2-l {
    color: #13c1dd;
    text-align: left;
    font-family: 'HelveticaMedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    margin-left: 30px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    width: 78%;
}

.apexpand2p2-l::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    /* Initial thickness of underline */
    background-color: #13c1dd;
    /* Initial underline color */
    transition: height 0.3s ease;
    /* Transition for thickness change */
}

.apexpand2p2-l:hover::after {
    height: 2px;
    /* Increase thickness on hover */
    background-color: #13c1dd;
    /* Color of the thicker underline */
    bottom: -1px;
    width: 87%;
}

.product-form-l {
    display: none;
}

@media (max-width: 767px) {
    .apexpandyour-l {
        display: none;
    }

    .ptd1g {
        display: block;
    }

    .learning-container-e {
        display: none;
    }

    .animated-cursor {
        display: none;
    }

    .Learning-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f6f4f2;
    }

    .Lmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #f6f4f2;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .kinboxdiv1 {
        width: 100%;
        height: 662px;

        margin: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        background: grey;
        margin-bottom: 50px;
        border-radius: 0px;
    }

    .lngodiv {
        display: none;
    }

    .loimg {
        width: 100px;
        height: 110px;
    }

    .vlinex {
        border: 1px solid #ffffff;
        height: 30px;
        margin-right: 5px;
    }

    .lngodiv > p {
        display: none;
    }

    .acxss {
        display: none;
    }

    .textzdiv {
        width: 100%;
        height: auto;

        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        margin-top: 38px;
        /* padding-left: 23px; */
        margin-bottom: 46px;
    }

    .ptd1 {
        display: none;
    }

    .ptd1g {
        color: #1e1e1e;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 30px;
        font-weight: 500;
        position: relative;
        width: 88%;

        height: auto;
        margin: auto;
        margin-top: 0px;
    }

    .ptd2 {
        color: #1e1e1e;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        position: relative;
        width: 88%;
        height: auto;
        margin: auto;
        margin-top: 17px;
    }

    .xmoxdiv1 {
        display: none;
    }

    .publishcard {
        display: none;
    }

    .tiputr-l {
        display: block;
        width: 100%;
        height: auto;

        margin-top: 0px;
        display: flex;
        align-items: center;
    }

    .babuptrw-l {
        width: 88%;
        height: auto;

        margin: auto;
        background: rgba(242, 242, 242, 0.5);
        border-radius: 20px;
        display: flex;
        flex-direction: column;

        padding-top: 28px;
        margin-bottom: 40px;
    }

    .kinlotfxtr-l {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo1fxtr-l {
        width: 48px;
        height: 18px;
        object-fit: cover;
        margin-left: 0px;
    }

    .vlinefxtr-l {
        border: 1px solid black;
        height: 18px;
        margin-right: 10px;
    }

    .hubpfxtr-l {
        font-size: 24px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #151515;
        /* height: 33px; */
    }

    .Cxtrends-l {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;

        text-align: center;
        margin-top: 5px;
    }

    .bruline-l {
        border-style: solid;
        border-color: #13c1dd;
        border-width: 1px 0 0 0;
        width: 95%;
        height: 0px;
        position: relative;
        /* margin: auto; */
        margin-top: 10px;
        margin-left: 10px;
    }

    .brudiv1-l {
        width: 77%;

        background-color: #000000;
        height: 213px;
        margin: auto;
        margin-top: 20px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .brudte1-l {
        color: #1e1e1e;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        position: relative;
        width: 85%;
        margin-left: 0px;
        margin: auto;
    }

    .brudiv2-x-x-l {
        display: flex;
        width: 100%;

        padding-left: 8%;
        margin-top: 9px;
        gap: 8px;
    }

    .brd-1-0-l {
        display: flex;
        /* width: 100px; */

        /* justify-content: space-between; */
        align-items: center;
        gap: 5px;
        color: #727272;

        font-family: 'InterRegular', sans-serif;
        font-size: 10px;
        /* line-height: 32px; */
        font-weight: 400;
        position: relative;
    }

    .brudiv2-x-x-l > button {
        border: none;
        background: #ededed;
        border-radius: 10px;
        /* width: 100px; */
        height: 17px;
        position: relative;
        color: #151515;

        font-family: 'Newheromedium', sans-serif;
        font-size: 10px;
        font-weight: 500;
        position: relative;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .brudte3-l {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        width: 85%;
        margin-left: intial;
        margin: auto;

        margin-top: 15px;
        margin-bottom: 10px;
    }

    .publishcardram {
        width: 90%;
        height: auto;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;

        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        /* background: rgba(242, 242, 242, 0.5); */
        border-radius: 20px;
        padding-bottom: 20px;
    }

    #topupa {
        display: block;
    }

    .tipupa {
        width: 100%;
        height: auto;

        display: flex;
        /* padding-top: 10px;
      padding-bottom: 10px; */
    }

    .babuppa {
        width: 82.6%;
        height: 278px;

        margin: auto;
        position: relative;

        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        /* backdrop-filter: blur(2px); */
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .babdiv1pa {
        width: 100%;
        height: 154px;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: grey;
    }

    .babdiv2pa {
        width: 100%;
        height: 124px;

        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-top: 15px;
    }

    .babp1pa {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        /* line-height: 47px; */
        font-weight: 400;
        position: relative;
        width: 250px;
        /* height: 20px; */

        /* margin: auto; */
    }

    .babp2pa {
        color: #13c1dd;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        line-height: 15px;
        font-weight: 500;
        position: relative;
        width: 230px;

        margin-top: 10px;
        /* margin: auto; */
    }

    .babp2pa:hover {
        text-decoration: underline;
    }

    .publp1div {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        /* padding-left: 25px; */
        margin-bottom: 20px;
    }

    .pu1 {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        /* display: flex;
      align-items: center;
      justify-content: flex-start; */

        margin: auto;
        margin-top: 25px;
        width: 95%;
    }

    .pu2 {
        color: #151515;
        /* text-align: left; */
        font-family: 'Noto-medium', sans-serif;
        font-size: 18px;
        font-weight: 500;
        position: relative;
        width: 70%;
        /* display: flex;
      align-items: center;
      justify-content: flex-start; */

        margin-top: 10px;
        margin-left: 2.5%;
    }

    .pu3 {
        color: #151515;
        /* text-align: left; */
        font-family: 'Noto-regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 95%;
        margin: auto;
        line-height: 18px;
        margin-top: 10px;
    }

    .publp2div {
        width: 88%;
        height: 259px;

        display: flex;
        justify-content: space-between;
        background: #ffffff;
        border-radius: 21px;
        margin-bottom: 25px;
    }

    .publp2div > div {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        border-radius: 21px;
        background: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    }

    .publp2div > div > div {
        height: 59.5%;
        width: 100%;
        background: #000000;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
    }

    .pubt1 {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewHero-Regular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        /* width: 262px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: 15px;
    }

    .pubt2 {
        color: #13c1dd;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        font-weight: 500;
        /* text-decoration: underline; */
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: 15px;
    }

    .pubt2:hover {
        text-decoration: underline;
    }

    .mdivx {
        display: none;
    }

    .mdivxram {
        display: block;
        width: 90%;
        height: auto;

        margin: auto;
        /* background: #000000; */
        background: rgba(242, 242, 242, 0.5);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .vtp1 {
        color: #151515;
        /* text-align: left; */
        font-family: 'Newherobold', sans-serif;
        font-size: 25px;
        font-weight: 700;
        position: relative;
        margin-left: 25px;
        margin-top: 22px;
    }

    .vtp2 {
        display: flex;
        width: 88%;
        justify-content: space-between;
        margin-top: 15px;
        margin-left: 23px;
    }

    .vtp2 > p {
        color: #151515;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        display: inline-block;
        position: relative;
    }

    .vtp2 > p::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        /* Adjust padding from text bottom */
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .vtp2 > p:hover::after {
        background-color: #151515;
        bottom: -4px;
    }

    .und1 {
        border-style: solid;
        border-color: #979797;

        border-width: 1px 0 0 0;
        width: 88%;
        height: 0px;
        position: relative;
        margin-top: 4px;
        /* transform-origin: 0 0;
      transform: rotate(0deg) scale(1, 1); */
        margin-left: 23px;
    }

    .movi1 {
        width: 88%;

        height: auto;
        margin: auto;
        margin-top: 25px;
        /* margin-left: 10px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0px;
    }

    .movp1 {
        width: 100%;

        height: 166px;
        /* margin-right: 20px; */
        border-radius: 10px;
        background: grey;
    }

    .movp2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-top: 25px;
    }

    .mot1 {
        color: #1e1e1e;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 18px;
        font-weight: 500;
        position: relative;
    }

    .mot2 {
        color: #979797;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        margin-top: 5px;

        width: 60%;
    }

    .product-form-l {
        display: block;
        width: 88%;
        height: 464px;
        background: #ffffff;
        border-radius: 13px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin: auto;
        display: flex;
        flex-direction: column;

        padding-bottom: 27px;
        margin-bottom: 53px;
        margin-top: 51px;
    }

    .form-p1-l {
        width: 80%;
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin: auto;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .form-p2-l {
        color: #000000;
        text-align: left;
        font-family: 'NewHero-Regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 80%;
        margin: auto;

        margin-bottom: 15px;
    }

    .product-form-l > input {
        height: 30px;
        width: 80%;
        margin: auto;
        margin-bottom: 19px;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form-l > textarea {
        width: 80%;
        height: 68px;
        margin: auto;
        color: #1e1e1e;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form-l > button {
        border: none;
        background: #13c1dd;
        border-radius: 16px;
        width: 83px;
        height: 32px;
        color: #ffffff;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 10%;
    }
}
