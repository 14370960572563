* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#container {
    width: 100%;
    height: 100vh;
    display: flex;
    /* background-image: url('../Assests/bg2.jpg');
  
  background-size: cover;
  background-repeat: no-repeat;
  
  background-position: center; */
}

.bgimage {
    background-image: url('../Assests/bg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    background-position: center;
    /* cover
  center */
}

#box1 {
    width: 48%;
    height: 100%;
    /* border: 1px solid green; */
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(8px);
    /* border-top-right-radius: 70px;
  border-bottom-right-radius: 70px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

#Lbox {
    width: 100%;
    /* height: 60%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

#logobox {
    /* margin: auto; */
    display: flex;
    width: 100%;
    /* height: 6.5%; */

    align-items: center;
    justify-content: center;

    margin-bottom: 70px;

    /* margin-top: 40%; */
}

img {
    /* height: 69px; */
    width: 20%;
    height: 100%;
}

#logobox > p {
    font-size: 30px;
    /* font-weight: 450; */
    color: #1e1e1e;
    /* width: 600px; */

    font-family: 'NewHero', sans-serif;
    font-weight: 500;
}

.vertical-line12 {
    height: 45px;
    /* Adjust the height of the line as needed */
    width: 1px;
    /* Adjust the width of the line as needed */
    background-color: #000000;
    /* Color of the vertical line */
    margin: 0 10px;
    /* Adjust the margin between the line and text as needed */
}

.nutritext {
    /* border: 1px solid green; */
    font-size: 26px;
    font-weight: 550;
    color: #222222;
    text-align: center;
    width: 100%;
    /* margin: auto; */
    /* font-family: New Hero; */
    word-wrap: break-word;
    line-height: 35px;
    letter-spacing: 0.2px;
    font-family: 'NewHero', sans-serif;
}

.bottext {
    /* margin: auto; */
    color: #1e1e1e;
    font-size: 13px;
    /* font-family: New Hero; */
    font-weight: 400;
    line-height: 20px;
    /* word-wrap: break-word; */
    text-align: center;
    margin-top: 30px;

    width: 100%;
    font-family: 'NewHero', sans-serif;
}

.bottext1 {
    display: none;
}

#butbox {
    width: 20%;
    height: 35px;
    /* border: 1px solid green; */
    margin: auto;
    /* margin-top: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top: 60px;
}

.buton {
    border: 1px solid grey;
    width: 100%;
    height: 100%;
    /* box-shadow: 0px 8px 16px rgba(96, 97, 112, 0.16); */
    border-radius: 10px;
    color: #1e1e1e;
    font-size: 15px;
    /* background-color: white; */
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(8px);
}

.buton:hover {
    transform: scale(1.05);

    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */
}

@media (max-width: 767px) {
    #container {
        display: flex;
        height: auto;
        /* border: 1px  solid red; */
        align-items: last baseline;
    }

    .bgimage {
        background-image: url('../Assests/l.jpeg');
        background-size: 100% 46%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: left top;
    }

    #box1 {
        margin-top: 40vh;
        width: 100%;
        height: 45%;

        /* background: rgba(255, 255, 255, 0.28); */
        background: rgba(255, 255, 255, 0.28);
        backdrop-filter: blur(8px);
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #Lbox {
        padding: 20px;

        height: auto;
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
    }

    #logobox {
        margin-bottom: 40px;
    }

    img {
        width: 22%;
    }

    #logobox > p {
        font-size: 22.5px;

        color: #1e1e1e;
    }

    .vertical-line {
        display: none;
    }

    .nutritext {
        font-size: 16px;
        line-height: 20px;
        /* border: 1px solid green; */
        font-weight: 600;
        margin-bottom: 15px;
        color: #222222;
    }

    .bottext {
        display: none;
        font-size: 8px;
        line-height: 15px;
        margin-top: 10px;
        font-weight: 400;
        /* border: 1px solid green; */
        margin-bottom: 40px;
    }

    .bottext1 {
        display: initial;
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
        font-weight: 400;
        /* border: 1px solid green; */
        margin-bottom: 40px;
        width: 100%;
        text-align: center;
        color: #1e1e1e;
        font-family: 'NewHero', sans-serif;
    }

    #butbox {
        width: 50%;
        height: 50px;
        margin-top: 0px;
        /* border: 1px solid green; */
    }

    .buton {
        font-size: 15px;
        font-weight: 600;
        border-radius: 15px;
        border: 0.5px solid #1e1f1f;
        background: rgba(255, 255, 255, 0.28);
        backdrop-filter: blur(8px);
    }
}

/* @media (min-width : 200px) and (max-width:450px) {
 #container{
  border: 5px solid red;
 }

   
 } */

/* Adjust styles for standard desktop screens */
/* @media (min-width: 1040px) and (max-width: 1296px) {
   
  
  
  
  #logobox {
  
    height: 6.8%; 
   
    align-items: center;
    justify-content: center;
  
    margin-bottom: 35px;
  
    margin-top: 32vh;
    
  }
  img {
   
    width: 18%;
    height: 100%;
  }
  #logobox > p {
    font-size: 30px;
    font-weight: 500;
    color: #1e1e1e;
    
    
  }
  .vertical-line {
    height: 100%; 
    width: 1px;
   
    margin: 0 10px; 
  }
  
  .nutritext {
   
    font-size: 23px;
    font-weight: 500;
    color: #222222;
    text-align: center;
    width: 100%;
    margin: auto;
   
    word-wrap: break-word;
    line-height: 35px;
    letter-spacing: 0.20px;
    
  }
  
  .bottext {
     margin: auto;
    color: #1e1e1e;
    font-size: 11px;
   
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 20px;
  
    width: 100%;
  }
  #butbox{
     width: 25%;
     height: 35px;
    
     margin: auto;
     margin-top: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
    
    
  }
  .buton{
    
     
   
     border: 2px #1C2955 solid;
     width: 100%;
    height: 100%;
  
    border-radius: 10px;
    color: #1E1E1E; 
    font-size: 12px;
     background-color: white;
     font-weight: 550; 
    
  } 
 }  */

/* Adjust styles for small desktop screens */
/* @media (min-width: 768px) and (max-width: 1039px) {
  #logobox {
  
    height: 6%; 
    
    align-items: center;
    justify-content: center;
  
    margin-bottom: 35px;
  
    margin-top: 32vh;
    
  }
  img {
   
    width: 18%;
    height: 100%;
  }
  #logobox > p {
    font-size: 22px;
    font-weight: 500;
    color: #1e1e1e;
    
    
  }
  .vertical-line {
    height: 100%; 
    width: 1px;
   
    margin: 0 10px; 
  }
  
  .nutritext {
   
    font-size: 20px;
    font-weight: 500;
    color: #222222;
    text-align: center;
    width: 100%;
    margin: auto;
   
    word-wrap: break-word;
    line-height: 35px;
    letter-spacing: 0.20px;
    
  }
  
  .bottext {
     margin: auto;
    color: #1e1e1e;
    font-size: 10px;
    
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    text-align: center;
    margin-top: 20px;
  
    width: 100%;
  }
  #butbox{
     width: 30%;
     height: 30px;
    
     margin: auto;
     margin-top: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
    
    
  }
  .buton{
    
     
   
     border: 2px #1C2955 solid;
     width: 100%;
    height: 100%;
  
    border-radius: 10px;
    color: #1E1E1E; 
    font-size: 15px;
     background-color: white;
     font-weight: 550; 
    
  } 
 } */
