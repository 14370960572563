* {
    padding: 0;
    margin: 0;
    /* box-sizing: border-box; */
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.Brandpage-container {
    width: 100%;
    height: auto;
    /* display: none; */

    overflow-x: hidden;
    /* background: #f6f4f2; */
    background: #f6f4f2;
}

.brndpmain {
    margin: auto;
    width: 100%;
    /* height: 600px; */
    /* margin-top: 90px; */
    /* border: 5px solid red; */
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
}

.bp-container-1 {
    width: 25%;
    height: 115px;

    margin: auto;
    margin-top: 42px;
    margin-bottom: 28px;
}

.bp-container-1 > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Brand-menu {
    border-top: 1px solid #1058a8;
    border-bottom: 1px solid #1058a8;
    width: 89%;
    height: 47px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    padding-left: 8.52%;
    padding-right: 8.52%;
}

.Brand-menu > p {
    color: #002e76;
    /* text-align: left; */
    font-family: 'Helveticabold', sans-serif;
    font-size: 13px;
    font-weight: 700;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.bp-container-2 {
    width: 89%;
    height: 572px;
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-bottom: 90px;
}

.bp-container-2-a {
    width: 100%;
    height: 449px;
    background-image: url(../Assests/bp-1.png);
    background-position: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.bp-container-2-b {
    width: 100%;
    height: 123px;
    background: #122257;
    display: flex;
    align-items: center;
    padding-left: 44px;
}

.bp-container-2-b > p {
    color: #ffffff;
    text-align: left;
    font-family: 'Noto-semibold', sans-serif;
    font-size: 32px;
    font-weight: 600;
    position: relative;
}

.bp-container-c-for-image {
    width: 36%;
    height: 242px;

    position: absolute;
    z-index: 1;
    top: auto;
    margin-top: 290px;
    left: 58.4%;
    background-image: url(../Assests/w-i-1.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.redefining-wound {
    color: #122257;
    text-align: center;
    font-family: 'Noto-medium', sans-serif;
    font-size: 36px;
    font-weight: 400;
    position: relative;
    width: 70%;
    margin: auto;
    margin-bottom: 36px;
}

.if-you-are-wune {
    color: #132257;
    text-align: center;
    font-family: 'Noto-regular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    width: 49%;
    margin: auto;
    margin-bottom: 52px;
}

.bp-container-3 {
    width: 73.75%;
    height: 163px;

    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 80px;
}

.bp-container-3 > div {
    width: 112px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bp-container-3 > div > .bp-circle {
    width: 100%;
    height: 112px;

    background: #d9d9d9;
    border-radius: 50%;
}

.bp-text-circle {
    width: 100%;
    height: 36px;
}

.bp-text-circle > p {
    color: #002e76;
    text-align: center;
    font-family: 'Noto-semibold', sans-serif;
    font-size: 13px;
    font-weight: 600;
    position: relative;
}

.bp-container-4 {
    width: 82.3%;
    height: 544px;

    display: flex;
    background-image: url(../Assests/ol-1.png);
    background-position: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 80px;
}

.bp-container-4 > p {
    color: #ffffff;
    text-align: left;
    font-family: 'Noto-bold', sans-serif;
    font-size: 40px;
    font-weight: 700;
    position: relative;
    width: 51.4%;
    margin-top: 52px;
    margin-left: 35px;

    /* height: 196px; */
}

.bp-line-1 {
    border-style: solid;
    border-color: #979797;
    border-width: 0.5px;
    width: 82.3%;
    height: 0px;
    position: relative;
    margin: auto;
    margin-bottom: 81px;
}

.bp-container-5 {
    width: 76.25%;
    height: auto;

    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 42px;
    margin-bottom: 80px;
}

.bp-container-5 > div {
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    height: 390px;
    flex-direction: column;
    padding-top: 27px;
    padding-bottom: 23px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.bp-brand-image {
    width: 184px;
    height: 155px;
    background-image: url(../Assests/wun-p.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;
    /* margin-top: 27px; */
}

.s-suuport {
    margin-top: 9px;
    color: #1058a8;
    text-align: center;
    font-family: 'giloryextrabold', sans-serif;
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    position: relative;
}

.wune-complete-1 {
    color: #181818;
    text-align: center;
    font-family: 'Noto-medium', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    position: relative;
    width: 80.3%;
    margin: auto;
    margin-top: 12px;
}

.view-product-information {
    color: #002e76;
    text-align: center;
    font-family: 'Noto-medium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    margin-top: 12px;
    /* width: 211.34px; */
}

.bp-line-2 {
    border-style: solid;
    border-color: #979797;
    border-width: 1px;
    width: 82.3%;
    height: 0px;
    position: relative;
    margin: auto;
    margin-bottom: 110px;
}

.bp-container-6 {
    width: 73.2%;
    height: 280px;

    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.bp-container-6 > div {
    width: 28.1%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.bp-c-6-a {
    width: 100%;
    height: 238.1px;
    background: grey;
    border-radius: 19px 19px 0px 0px;
}

.bp-c-6-b {
    width: 100%;
    height: 41.9px;
    background: #002e76;
    border-radius: 0px 0px 19px 19px;
    padding-right: 15px;
}

.bp-c-aero-div {
    display: flex;
    align-items: center;
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Newherobold', sans-serif;
    font-size: 15px;
    height: 100%;
    font-weight: 700;
    position: relative;
    justify-content: flex-end;
}

.bp-c-disclaimer {
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 10px;
    font-weight: 400;
    position: relative;
    width: 88%;
    margin: auto;
    margin-bottom: 75px;
}

.bp-container-6-for-mobile {
    display: none;
}

.Brand-menu-for-mobile {
    display: none;
}

@media (max-width: 767px) {
    .brndpmain {
        margin: auto;
        width: 100%;
        /* height: 600px; */
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
    }

    .animated-cursor {
        display: none;
    }

    .bp-container-1 {
        width: 45%;
        height: 63px;

        margin: auto;
        margin-top: 23px;
        margin-bottom: 15px;
    }

    .bp-container-1 > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .Brand-menu-for-mobile {
        display: block;
        height: 32px;
        width: 100%;

        display: flex;
        align-items: center;
        border: none;
        border-top: 1px solid #1058a8;
        border-bottom: 1px solid #1058a8;
        width: 88%;
        margin: auto;
        margin-bottom: 23px;
    }

    .menu-p {
        color: #1058a8;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        position: relative;

        /* width: 200px; */
    }

    /* #mmmp-1{
  width:200px;
} */

    .Brand-menu {
        display: none;

        /* border-top: 1px solid #1058a8;
    border-bottom: 1px solid #1058a8;
    width: 89%;
    height: 47px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    padding-left: 8.52%;
    padding-right: 8.52%; */
    }

    .bp-container-2 {
        width: 100%;
        height: 175px;
        display: flex;
        flex-direction: column;

        margin: auto;
        margin-bottom: 47px;
    }

    .bp-container-2-a {
        width: 100%;
        height: 137px;
        background-image: url(../Assests/bp-1.png);
        background-position: cover;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bp-container-2-b {
        width: 100%;
        height: 38px;
        background: #122257;
        display: flex;
        align-items: center;
        padding-left: 25px;
    }

    .bp-container-2-b > p {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-semibold', sans-serif;
        font-size: 11px;
        font-weight: 600;
        position: relative;
    }

    .bp-container-c-for-image {
        width: 108px;
        height: 56px;

        position: absolute;
        z-index: 1;
        top: auto;
        margin-top: 110px;
        left: 70%;
        background-image: url(../Assests/w-i-1.png);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .redefining-wound {
        color: #122257;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        width: 87.1%;
        margin: auto;
        margin-bottom: 34px;

        word-wrap: break-word;
    }

    .if-you-are-wune {
        color: #132257;
        text-align: center;
        font-family: 'Noto-regular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        width: 80%;
        margin: auto;
        margin-bottom: 50px;
        word-wrap: break-word;
    }

    .bp-container-3 {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: initial;
        margin: auto;
        margin-bottom: 52px;
        row-gap: 44px;
    }

    .bp-container-3 > div {
        width: 140px;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: space-between;
        margin: auto;
    }

    .bp-container-3 > div > .bp-circle {
        height: 90px;
        width: 90px;
        background: #d9d9d9;
        border-radius: 50%;

        margin: auto;
    }

    .bp-text-circle {
        width: 100%;
        height: 36px;
        margin-top: 18px;
    }

    .bp-text-circle > p {
        color: #002e76;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 13px;
        font-weight: 500;
        position: relative;
    }

    .bp-container-4 {
        width: 100%;
        height: 560px;

        display: flex;
        background-image: url(../Assests/ol-1.png);
        background-position: cover;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        margin-bottom: 50px;
        background: grey;
    }

    .bp-container-4 > p {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-semibold', sans-serif;
        font-size: 25px;
        font-weight: 600;
        position: relative;
        width: 80%;
        margin-left: initial;
        margin: auto;
        margin-top: 41px;

        height: 132px;
    }

    .bp-line-1 {
        border-style: solid;
        border-color: #979797;
        border-width: 0.5px;
        width: 88%;
        height: 0px;
        position: relative;
        margin: auto;
        margin-bottom: 30px;
    }

    .bp-container-5 {
        width: 100%;
        height: auto;

        margin: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0;
        row-gap: 25px;
        margin-bottom: 60px;
    }

    .bp-container-5 > div {
        width: 88%;
        background: #ffffff;
        border-radius: 20px;
        display: flex;
        height: 390px;
        flex-direction: column;
        padding-top: 27px;
        padding-bottom: 23px;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    }

    .bp-brand-image {
        width: 183px;
        height: 155px;
        background-image: url(../Assests/wun-p.png);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: auto;
        /* margin-top: 27px; */
    }

    .s-suuport {
        margin-top: 9px;
        color: #1058a8;
        text-align: center;
        font-family: 'giloryextrabold', sans-serif;
        font-size: 22px;
        line-height: 33px;
        font-weight: 400;
        position: relative;
    }

    .wune-complete-1 {
        color: #181818;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        position: relative;
        width: 70%;
        margin: auto;
        margin-top: 12px;
    }

    .view-product-information {
        color: #002e76;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        margin-top: 12px;
        /* width: 211.34px; */
    }

    .bp-line-2 {
        border-style: solid;
        border-color: #979797;
        border-width: 1px;
        width: 88%;
        height: 0px;
        position: relative;
        margin: auto;
        margin-bottom: 52px;
    }

    .bp-container-6 {
        display: none;
    }

    .bp-container-6-for-mobile {
        display: block;
        height: auto;

        width: 100%;
        margin-bottom: 50px;
    }

    .mob-l {
        width: 75.42%;
        height: 281px;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 40px;
    }

    .bp-c-6-a {
        width: 100%;
        height: 238.1px;
        background: grey;
        border-radius: 19px 19px 0px 0px;
    }

    .bp-c-6-b {
        width: 100%;
        height: 41.9px;
        background: #002e76;
        border-radius: 0px 0px 19px 19px;
    }

    .bp-c-disclaimer {
        display: none;
        /* color: #000000;
  text-align: left;
  font-family: "NewheroRegular", sans-serif;
  font-size: 10px;
  font-weight: 400;
  position: relative;
  width: 88%;
  margin: auto; */
    }

    .bp-contact {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 50px;
        justify-content: initial;
    }

    .bp-c-cp-1 {
        width: 88%;
        height: 243px;
        background: #ffffff;
        border-radius: 13px;
        box-shadow:
            0px 4px 6px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
        padding-left: 20px;
        margin: auto;
    }

    .bp-cp-cp-2 {
        color: #002e76;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 20px;
        line-height: initial;
        font-weight: 400;
        position: relative;
        width: 80%;
        height: 85px;

        /* margin: auto; */
        margin-top: 15px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .bp-c-cp-3 {
        color: #002e76;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-left: 0px;
        margin-top: 9px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .bp-input-container {
        display: flex;
        flex-direction: column;

        width: 88.4%;
        justify-content: initial;
        margin-left: 0px;
        margin-top: 9px;
        height: auto;
    }

    #bp-input-box {
        /* background: transparent; */
        /* background: none; */
        border: none;
        /* Hide all borders */
        background: rgba(217, 217, 217, 0);
        border-radius: 7px;
        border-style: solid;
        border-color: #979797;
        border-width: 0.7px;
        width: 100%;
        color: #727272;
        margin-bottom: 19px;
        height: 35px;
        font-family: 'Noto-medium', sans-serif;
        font-size: 12px;
        font-weight: 500;

        padding-left: 15px;
    }

    .bp-input-container > button {
        background: #002e76;
        border-radius: 7px;
        width: 83px;
        height: 28px;
        position: relative;
        color: #ffffff;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 12px;
        border: none;
    }

    .apexpand2-bp {
        width: 76.85%;
        height: 182px;
        background: #002e76;
        border-radius: 12px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 27px;
    }

    .apexpand2p1-bp {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: 65%;

        margin-left: 30px;
        margin-top: 29px;
        margin-bottom: 29px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .apexpand2p2-bp {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        margin-left: 30px;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        width: 78%;
    }

    .apexpand2p2-bp::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        /* Initial thickness of underline */
        background-color: #000000;
        /* Initial underline color */
        transition: height 0.3s ease;
        /* Transition for thickness change */
    }

    .apexpand2p2-bp:hover::after {
        height: 2px;
        /* Increase thickness on hover */
        background-color: #000000;
        /* Color of the thicker underline */
        bottom: -1px;
        width: 87%;
    }
}
