.d-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.tpox {
    color: #000000;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin-left: 13.4%;
}

.download-form-divx {
    width: 73.4%;
    height: 269px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.down-lx {
    width: 48.6%;
    height: 100%;
}

.ask-yourx {
    color: #000000;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    margin-top: 10px;
}

.answerx {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    margin-top: 8px;
}

.dont-know-divx {
    background: rgba(217, 217, 217, 0.5);
    border-radius: 50px 50px 50px 0px;
    width: 100%;
    height: 76px;
    position: relative;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dont-know-divx > p {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 13px;
    font-weight: 400;
    position: relative;
}

.d-product-listx {
    display: flex;
    width: 92%;

    height: 44px;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
}

.d-product-listx > button {
    background: #ff5914;
    border-radius: 22px;
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px;
    width: 47.9%;
    height: 44px;
    position: relative;
    color: #ffffff;
}

.d-product-listx > p {
    color: #ff5914;
    text-align: left;
    font-family: 'Newherosemibold', sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    position: relative;
}

.down-rx {
    width: 48.9%;
    height: 100%;
    border-radius: 50px 0px 50px 0px;
    background: #d9d9d9;
}

.div-x-1-for-mobilex {
    display: none;
}

.doplx {
    display: none;
}

.atex {
    display: none;
}

.aywqx {
    display: none;
}

.div-x-2x {
    display: none;
}

.ask-btnx {
    display: none;
}

@media (max-width: 767px) {
    .d-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .div-x-1-for-mobilex {
        display: block;
        background: #d9d9d9;
        border-radius: 50px 0px 50px 0px;
        width: 88%;
        height: 179px;
        position: relative;
        margin: auto;
        margin-bottom: 14px;
    }

    .doplx {
        display: block;
        color: #ff5914;
        text-align: left;
        font-family: 'Newherosemibold', sans-serif;
        font-size: 13px;
        font-weight: 600;
        text-decoration: underline;
        position: relative;
        margin-bottom: 14px;
        margin-left: 6%;
    }

    .atex {
        display: block;
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        /* width: 219px; */
        height: 18px;
        margin-bottom: 10px;
        margin-left: 6%;
    }

    .aywqx {
        display: block;
        color: #ff5914;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        margin-bottom: 15px;
        margin-left: 6%;
    }

    .div-x-2x {
        display: block;
        background: rgba(217, 217, 217, 0.5);
        border-radius: 50px 50px 50px 0px;
        width: 88%;
        height: 76px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 18px;
    }

    .div-x-2x > p {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        width: 70%;
    }

    .ask-btnx {
        display: block;
        background: #ff5914;
        border-radius: 22px;
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px;
        width: 57.3%;
        height: 44px;
        margin: auto;
        color: #ffffff;
        /* text-align: left; */
        font-family: 'Newherosemibold', sans-serif;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        margin-bottom: 50px;
    }

    .tpox {
        display: none;
    }

    .download-form-divx {
        display: none;
    }
}
