* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

#company-container-A {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    /* background: #f6f4f2; */
    background: #fffffe;
}

.Cmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #fffffe;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.menuComxt {
    display: none;
}

.About-kingenic {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 40px;
    font-weight: 400;
    position: relative;
    /* width: 298px;
  height: 52px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin-top: 74px;
    margin-bottom: 35px;
}

.kingenic-description {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    position: relative;
    width: 73.75%;
    margin: auto;
    word-wrap: break-word;
    margin-bottom: 80px;
    /* height: 103px; */
}

.CbgBox {
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    width: 88.9%;
    height: 625px;

    margin: auto;
    /* padding-top: 90px;  */
    /* margin-bottom: 20px; */
    /* margin-top: 40px; */
    /* background: black; */
    overflow: hidden;
    position: relative;
    background: #c4c4c4;
    padding-bottom: 33px;
    padding-left: 4.375%;
    /* margin-top: 20px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.trnsn {
    color: #000000;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 19px;
    font-weight: 400;
    position: relative;
    /* margin-top: 386px; */
    /* margin-left: 63px; */
    /* line-height: 23px; */
    margin-bottom: 3px;
    margin-top: auto;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.compgpt1 {
    /* margin-left: 63px; */
    color: #000000;
    font-size: 64px;
    font-family: 'Newheromedium', sans-serif;
    font-weight: 500;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.compgpt2 {
    /* margin-left: 63px; */
    color: #000000;
    font-size: 64px;
    font-family: 'NewheroRegular', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.bgbox2 {
    width: 88.9%;
    height: auto;
    margin: auto;
    margin-top: 71px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.bgleft {
    width: 73.203125%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.Revitalising-Health-p {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 35px;
    font-weight: 400;
    position: relative;
    width: 95%;
}

.company-line-1 {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 770px;
    height: 0px;
    position: relative;
    margin-top: 27px;
}

.crafted-service-p {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 10px;
    font-weight: 400;
    position: relative;
    margin-top: 35px;
}

.From-Concept-to-Product {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin-top: 7px;
}

.Kingenic-is-a-multifunctional {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 90.2%;
    margin-top: 8px;
}

.dev-man-repack-div {
    display: flex;

    margin-top: 12px;
}

.development-div {
    width: 29%;
    /* height: 200px; */
    display: flex;
    flex-direction: column;
}

.dev-circle {
    background: #d9d9d9;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    position: relative;
}

.dev-headings {
    color: #ff5914;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    /* width: 232px; */
    margin-top: 17px;
    margin-bottom: 6px;
}

.dev-description {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 100%;
}

.manufacturing-div {
    width: 31%;
    /* height: 200px; */

    margin-right: 21px;
}

.man-circle {
    background: #d9d9d9;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    position: relative;
}

.man-headings {
    color: #ff5914;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    word-wrap: break-word;
    /* width: 232px; */
    margin-top: 17px;
    margin-bottom: 6px;
}

.man-description {
    color: #151515;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 100%;
    word-wrap: break-word;
}

.repacking-div {
    width: 33%;
    /* height: 200px; */
}

.rep-circle {
    background: #d9d9d9;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    position: relative;
}

.rep-headings {
    color: #ff5914;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    word-wrap: break-word;
    /* width: 232px; */
    margin-top: 17px;
    margin-bottom: 6px;
}

.rep-description {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 100%;
    word-wrap: break-word;
}

.Company-Certifications {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin-top: 37px;
}

.Certifications-line {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 326px;
    /* height: 0px; */
    position: relative;
    margin-top: 16px;
}

.Certifications-cicle-container {
    display: flex;
    width: 36.3%;
    height: 81px;

    margin-top: 24px;
    justify-content: space-between;
}

.Certifications-cicle-container > div {
    width: 52px;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.Certifications-cicle-container > div > div {
    background: #d9d9d9;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    position: relative;
}

.Certifications-cicle-container > div > p {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-top: 8px;
}

.bgright {
    width: 26.796875%;
    height: 93%;

    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */

    background: rgba(198, 197, 197, 0.07);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    border-radius: 16px;
    padding-bottom: 33px;
}

.latestnews {
    color: #151515;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    height: 37px;
    margin-left: 20px;
    margin-top: 30px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.avishek1 {
    width: 87%;
    height: 76px;

    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.avishek2 {
    width: 87%;
    height: 76px;

    margin-left: 20px;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.avishek3 {
    width: 87%;
    height: 76px;

    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.avishek4 {
    width: 87%;
    height: 76px;

    margin-left: 20px;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.divimg1 {
    background: #d9d9d9;
    border-radius: 8px;
    width: 29.45%;
    height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.divtext {
    width: 185px;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    display: flex;
    flex-direction: column;
}

.dece1 {
    color: #979797;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 9px;
    font-weight: 400;
    position: relative;
    /* height: 13px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.dece2 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    margin-top: 8px;
    /* line-height: 18px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.line-30 {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 85%;
    height: 0px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.cardcom {
    background: #fbfbfb;
    border-radius: 13px;
    width: 89%;
    height: 561px;
    position: relative;
    /* box-shadow: 3px 3px 4px 4px rgba(114, 114, 114, 0.03),
    -3px -3px 4px 4px rgba(114, 114, 114, 0.03); */

    margin: auto;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* padding-left: 8.2%;
  padding-right: 8.2%; */
    padding-top: 70px;
    padding-bottom: 70px;
}

.menuCom {
    width: 82.7%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    /* margin-left: 83px; */
    /* margin-top: 62px; */
    position: relative;
    border-color: #cccccc;
    border-bottom-width: 0.5px 0 0 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.menuCom > p {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 17px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.menuCom > p::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.menuCom > p.underline::after {
    background-color: #ff5914;
    bottom: -16px;
}

.undl8 {
    border: none;
    border-style: solid;
    border-color: #cccccc;
    border-width: 0.5px 0 0 0;
    width: 82.7%;
    height: 0px;
    /* margin-left: 83px; */
    margin: auto;
    position: relative;
    margin-top: 16px;
}

.formulacard {
    width: 82.7%;
    height: 353px;
    margin: auto;

    margin-top: 31px;
    /* margin-left: 0px; */
    display: flex;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.f1 {
    width: 38.7%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.fdp1 {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin-bottom: 9px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.fdp2 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.f2 {
    height: 100%;
    width: 59.3%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    border-radius: 13px;
}

.f2 > img {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.formulacard2 {
    height: auto;
    width: 100%;

    margin: auto;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.ptc1 {
    color: #151515;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* margin-top: 88px; */

    width: 35.4%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 20px;
}

.ptc2 {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    width: 68.2%;
    margin: auto;
}

.business-containerxy {
    display: none;
}

.business-containerx {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 80px;
}

.nutri-sol-card {
    /* width: 88%;
    height: 414px; */

    /* display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 57px; */
    margin-bottom: 57px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 38px;
}

.video-div {
    /* width: 55.2%;
    height: 100%; */

    background: gray;
}

.text-div-nutri {
    /* width: 39.7%; */
    /* height: auto; */
}

.bus-div-text {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-top: 53px;
    margin-bottom: 14px;
}

.nutri-solutions-text {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    margin-bottom: 14px;
}

.Developing-and-reinvention {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    width: 78%;

    /* height: 201px; */
    line-height: 28px;
}

.business-menu {
    /* width: 88%; */
    /* height: 47px; */
    display: flex;
    justify-content: space-between;
    /* margin: auto; */
    max-width: 1090px;
    width: 100%;
    margin: 0 auto;
}

.business-menu > p {
    font-size: 22px;
    font-weight: 500;
    line-height: 31px;
    color: #0f172e;
    padding-bottom: 30px;
    position: relative;
}

.business-menu p::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #50504e;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.business-menu p.selected::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    background-color: #007ad9;

    bottom: 0;
    left: 0;
    animation: slide 5s ease-in-out forwards;
}

.business-menu p.selected {
    /* color: #ff5914; */
}

@keyframes slide {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.company-container-c {
    width: 89%;
    height: 601px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: rgba(251, 251, 251, 0.05);
    border-radius: 15px;
    padding: 39px 0px 28px 0px;
    margin-bottom: 80px;
}

.c-c-c-div {
    width: 62%;
    height: 190px;

    display: flex;
    justify-content: space-between;
    margin: auto;
}

.cc-1 {
    width: 28.3%;
    height: 172px;

    background: #d9d9d9;
    border-radius: 10px;
}

.cc-2 {
    width: 65.2%;
    height: 100%;
}

.cc-text {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    width: 100%;
}

.c-underline-4 {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 61.4%;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    margin: auto;
    margin-top: 12px;
}

.cc-text2 {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 737px;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 22px;
}

.c-underline-5 {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 61.4%;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    margin: auto;
    /* margin-top: 12px; */
    margin-bottom: 34px;
}

.company-container-e {
    width: 100%;
    height: 442px;

    display: flex;
    justify-content: space-between;
    padding-top: 42px;
    padding-bottom: 42px;
    margin-bottom: 80px;
    background: rgba(172, 163, 163, 0.05);
}

.c-c-e-div1 {
    width: 34%;
    height: auto;

    /* margin-right: 22px; */
    margin-left: 8.5%;
}

.c-c-e-div1 > p {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 36px;
    font-weight: 500;
    margin-top: 96px;
    margin-bottom: 27px;
}

.c-c-e-div1 > button {
    border: initial;
    background: #ff5914;
    border-radius: 19px;
    width: 239px;
    height: 38px;
    color: #fffdf5;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline;
}

.c-c-e-div2 {
    width: 47.1%;
    height: 357px;
    background: grey;
    margin-right: 8.5%;
}

@media (max-width: 767px) {
    #company-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        /* background: #f6f4f2; */
        background: #fffffe;
    }

    .animated-cursor {
        display: none;
    }

    .Cmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #fffffe;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .About-kingenic {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 25px;
        font-weight: 400;
        position: relative;
        /* width: 298px;
    height: 52px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        margin-top: 44px;
        margin-bottom: 19px;
    }

    .kingenic-description {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        position: relative;
        width: 82%;
        margin: auto;
        word-wrap: break-word;

        margin-bottom: 48px;
        /* height: 103px; */
    }

    .CbgBox {
        display: flex;
        flex-direction: column;
        border-radius: initial;
        width: 100%;
        height: 287px;

        margin: auto;
        /* padding-top: 90px;  */
        /* margin-bottom: 20px; */
        /* margin-top: 40px; */
        /* background: black; */
        overflow: hidden;
        position: relative;
        background: #c4c4c4;
        padding-bottom: 17px;
        padding-left: 24px;
        /* margin-top: 20px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .trnsn {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        /* margin-top: 386px; */
        /* margin-left: 63px; */
        /* line-height: 23px; */
        margin-bottom: initial;
        margin-top: auto;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .compgpt1 {
        /* margin-left: 63px; */
        color: #151515;
        font-size: 30px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
    }

    .compgpt2 {
        /* margin-left: 63px; */
        color: #151515;
        font-size: 25px;
        font-family: 'NewheroRegular', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
    }

    .bgbox2 {
        width: 88%;
        height: auto;
        margin: auto;
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        margin-bottom: 50px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .bgleft {
        width: 100%;
        height: auto;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .Revitalising-Health-p {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 25px;
        font-weight: 400;
        position: relative;
        width: 100%;
    }

    .company-line-1 {
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px 0 0 0;
        width: 100%;
        height: 0px;
        position: relative;
        margin-top: 21px;
    }

    .crafted-service-p {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        margin-top: 26px;
    }

    .From-Concept-to-Product {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin-top: 7px;
    }

    .Kingenic-is-a-multifunctional {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 90.2%;
        margin-top: 8px;
    }

    .dev-man-repack-div {
        display: flex;

        margin-top: 26px;
        flex-direction: column;
    }

    .development-div {
        width: 70%;
        /* height: 200px; */
        display: flex;
        flex-direction: column;
    }

    .dev-circle {
        background: #d9d9d9;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        position: relative;
    }

    .dev-headings {
        color: #ff5914;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        /* width: 232px; */
        margin-top: 17px;
        margin-bottom: 6px;
    }

    .dev-description {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 100%;
    }

    .manufacturing-div {
        width: 76%;
        display: flex;
        flex-direction: column;
        /* height: 200px; */

        margin-right: initial;
        margin-top: 26px;
    }

    .man-circle {
        background: #d9d9d9;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        position: relative;
    }

    .man-headings {
        color: #ff5914;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        word-wrap: break-word;
        /* width: 232px; */
        margin-top: 17px;
        margin-bottom: 6px;
    }

    .man-description {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 100%;
        word-wrap: break-word;
    }

    .repacking-div {
        width: 88%;
        /* height: 200px; */

        display: flex;
        flex-direction: column;
        margin-top: 26px;
    }

    .rep-circle {
        background: #d9d9d9;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        position: relative;
    }

    .rep-headings {
        color: #ff5914;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        word-wrap: break-word;
        /* width: 232px; */
        margin-top: 17px;
        margin-bottom: 6px;
    }

    .rep-description {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 100%;
        word-wrap: break-word;
    }

    .Company-Certifications {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 24px;
        font-weight: 400;
        position: relative;
        margin-top: 32px;
    }

    .Certifications-line {
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px 0 0 0;
        width: 100%;
        /* height: 0px; */
        position: relative;
        margin-top: 8px;
    }

    .Certifications-cicle-container {
        display: flex;
        width: 100%;
        height: 81px;

        margin-top: 49px;
        justify-content: space-between;
    }

    .Certifications-cicle-container > div {
        width: 52px;
        height: 100%;
        display: flex;
        flex-direction: column;

        justify-content: center;
    }

    .Certifications-cicle-container > div > div {
        background: #d9d9d9;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        position: relative;
    }

    .Certifications-cicle-container > div > p {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-top: 8px;
    }

    .bgright {
        width: 100%;
        height: auto;

        /* box-shadow: -3px 4px 4px 3px rgba(0, 0, 0, 0.05),
    4px 4px 4px 3px rgba(0, 0, 0, 0.05); */
        background: rgba(198, 197, 197, 0.07);

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        border-radius: 16px;
        padding-bottom: 33px;
        margin-top: 38px;
    }

    .latestnews {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        height: 37px;
        margin-left: 20px;
        margin-top: 35px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .avishek1 {
        width: 87%;
        height: 76px;

        margin-left: 20px;
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .avishek2 {
        width: 87%;
        height: 76px;

        margin-left: 20px;
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .avishek3 {
        width: 87%;
        height: 76px;

        margin-left: 20px;
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .avishek4 {
        width: 87%;
        height: 76px;

        margin-left: 20px;
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .divimg1 {
        background: #d9d9d9;
        border-radius: 8px;
        width: 29.45%;
        height: 100%;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .divtext {
        width: 185px;
        height: 100%;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        display: flex;
        flex-direction: column;
    }

    .dece1 {
        color: #979797;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 9px;
        font-weight: 400;
        position: relative;
        /* height: 13px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .dece2 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-top: 8px;
        /* line-height: 18px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .line-30 {
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px 0 0 0;
        width: 85%;
        height: 0px;
        position: relative;
        margin-top: 21px;
        margin-bottom: 21px;
        margin-left: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .cardcom {
        background: rgba(172, 163, 163, 0.05);
        border-radius: 13px;
        width: 88%;
        height: auto;
        position: relative;
        box-shadow:
            4px 4px 0px 0px rgba(0, 0, 0, 0.03),
            0px -4px 4px 0px rgba(0, 0, 0, 0.03);

        margin: auto;
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        /* padding-left: 8.2%;
    padding-right: 8.2%; */
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .menuCom {
        display: none;
        /* width: 82.7%;
    display: flex;
    margin: auto;
    justify-content: space-between;
   
    position: relative;
    border-color: #cccccc;
    border-bottom-width: 0.5px 0 0 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; */
    }

    .menuComxt {
        display: block;

        display: flex;
        padding-left: 10px;
        /* padding: 0px 20px 0px 20px; */
        /* justify-content: space-around; */
    }

    /* .menuComxt > p {
    color: #26394a;
    text-align: left;
    font-family: "Newheromedium", sans-serif;
    font-size: 16px;
    font-weight: 600;
    position: relative;
  
    cursor: pointer;
   
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  } */
    #mt-p {
        color: #26394a;
        text-align: left;
        font-family: 'Newherosemibold', sans-serif;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        text-align: center;
    }

    .menuComxt > p::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -16px;
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .menuComxt > p:hover::after {
        background-color: #83694f;
        bottom: -16px;
    }

    .undl8 {
        border: none;
        border-style: solid;
        border-color: #13c1dd;
        border-width: 0.5px 0 0 0;
        width: 90%;
        height: 0px;

        margin: initial;
        margin-left: 20px;
        position: relative;
        margin-top: 16px;
    }

    .formulacard {
        width: 100%;
        height: auto;
        margin: auto;

        margin-top: 20px;
        /* margin-left: 0px; */
        display: flex;
        flex-direction: column;
        justify-content: initial;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .f1 {
        width: 90%;
        height: auto;
        margin: auto;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        /* padding-left: 20px; */
        margin-bottom: 20px;
    }

    .fdp1 {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-bottom: 9px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .fdp2 {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        word-wrap: break-word;
    }

    .f2 {
        height: 100%;
        width: 88%;
        margin: auto;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        border-radius: 13px;
    }

    .f2 > img {
        width: 100%;
        height: 100%;
        border-radius: 13px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .formulacard2 {
        height: auto;
        width: 100%;

        margin: auto;
        margin-bottom: 43px;
        display: flex;
        flex-direction: column;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .ptc1 {
        color: #151515;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        /* margin-top: 88px; */

        width: 89%;
        margin: auto;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .ptc2 {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        width: 80%;
        margin: auto;
    }

    .business-containerx {
        display: none;
        /* width: 100%;
    height:auto;
    
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 80px; */
    }

    .business-containerxy {
        display: block;
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 50px;
    }

    .b-divsion {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin-left: 6.4%;
        margin-bottom: 12px;
    }

    .bsc-div {
        width: 100%;
        background: #d9d9d9;

        height: 230px;
        overflow: hidden;
    }

    .nutri-sol-cardxy {
        width: 87.1%;
        /* height: 210px; */

        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .nutri-solutions-textxy {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
    }

    .Developing-and-reinventionxy {
        color: #1e1e1e;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;

        width: 95%;
        height: auto;
        margin-top: 25px;
    }

    .business-menuxy {
        width: 87.1%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin: auto;
        margin-top: -30px;
        margin-bottom: 20px;
    }

    .business-menuxy > p {
        color: transparent;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;

        width: 16.2%;
    }

    .business-menuxy p::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1.5px;
        background-color: #979797;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .business-menuxy p.selected::before {
        content: '';
        position: absolute;
        width: 0;
        height: 3px;
        background-color: #007ad9;
        z-index: 111;
        bottom: 0;
        left: 0;
        animation: slide 5s ease-in-out forwards;
    }

    .business-menuxy p.selected {
        color: transparent;
    }

    @keyframes slide {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }

    .company-container-c {
        width: 89%;
        height: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        background: rgba(172, 163, 163, 0.05);
        border-radius: 15px;
        padding: 40px 0px 40px 0px;
        margin-bottom: 50px;
    }

    .c-c-c-div {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
    }

    .cc-1 {
        width: 70%;
        height: 186px;

        background: #d9d9d9;
        border-radius: 10px;
        margin: auto;
    }

    .cc-2 {
        width: 88%;
        height: 100%;
        margin: auto;
        margin-top: 17px;
    }

    .cc-text {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        width: 100%;
    }

    .c-underline-4 {
        border-style: solid;
        border-color: #83694f;
        border-width: 1px 0 0 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
        margin: auto;
        margin-top: 25px;
    }

    .cc-text2 {
        color: #26394a;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 88%;
        margin: auto;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .c-underline-5 {
        border-style: solid;
        border-color: #83694f;
        border-width: 1px 0 0 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
        margin: auto;
        /* margin-top: 12px; */
        margin-bottom: 25px;
    }

    .waysto {
        color: #26394a;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-bottom: 11px;
    }

    .Company-container-d {
        width: 100%;
        height: auto;

        margin-bottom: 56px;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: initial;
        gap: 37px;
        padding-left: initial;
        padding-right: initial;
    }

    .Cbrnd-link {
        width: 88%;
        height: 331px;
        text-decoration: none;
        margin: auto;
    }

    .Ccardbr1 {
        width: 100%;
        height: 100%;
        border-radius: 21px;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #ffffff;
        color: #ffffff;
        /* padding-top: 17px;
    padding-bottom: 17px; */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        margin-bottom: 106px;
    }

    .Ccardbr1 > div {
        height: 180px;
        width: 100%;
        background: grey;
        border-radius: 21px 21px 0px 0px;
    }

    .Cbr1 {
        color: #26394a;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        margin-top: 17px;
    }

    .Cbr2 {
        color: #26394a;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        margin: auto;
        margin-top: 11px;
        margin-bottom: 11px;
        width: 80%;
    }

    .Cbr30 {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
        width: auto;
    }

    .Cbr30::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 10%;
    }

    .Cbr30:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -1px;
        width: 80%;
    }

    .Cbr31 {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .Cbr31::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 12%;
    }

    .Cbr31:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -1px;
        width: 75%;
    }

    .Cbr32 {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .Cbr32::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 8%;
    }

    .Cbr32:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -2px;
        width: 84%;
    }

    .Cbr33 {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .Cbr33::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 25%;
    }

    .Cbr33:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -2px;
        width: 50%;
    }

    .company-container-e {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column-reverse;
        justify-content: initial;
        padding-top: initial;
        padding-bottom: initial;
        margin-bottom: 56px;
        background: rgba(172, 163, 163, 0.05);
    }

    .c-c-e-div1 {
        display: flex;
        flex-direction: column;
        width: 88%;
        height: auto;

        /* margin-right: 22px; */
        margin-left: initial;
        margin: auto;
    }

    .c-c-e-div1 > p {
        color: #26394a;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 24px;
        font-weight: 500;
        margin-top: 62px;
        margin-bottom: 57px;
    }

    .c-c-e-div1 > button {
        border: initial;
        background: #83694f;
        border-radius: 19px;
        width: 228px;
        height: 38px;
        color: #fffdf5;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        margin: auto;
    }

    .c-c-e-div2 {
        width: 88%;
        height: 187px;
        background: grey;
        margin-right: initial;
        margin: auto;
    }
}
