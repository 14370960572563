*body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #fffdf8;
}

#privatecontainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* background-color: #FFFDF8; */
    background: #fffdf8;
}

#privatenavbar {
    width: 100%;
    height: 70px;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: fixed; */
    margin-bottom: 25px;

    position: sticky;
    top: 0;
    z-index: 1;
}

.prinavTextbox {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.privector {
    width: 32px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px;
}

.pritextHello1 {
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.prinavtext1 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
}

.topBox {
    display: flex;
    /* margin-left: 60px; */
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;

    margin-bottom: 30px;
}

.pribox1 {
    width: 64%;
    height: 135px;
    font-size: 55px;
    font-weight: 550;
    font-family: 'NewHero', sans-serif;
    color: #222222;
}

.formla {
    color: #4da0d6;
}

.pribox2 {
    /* width: 26%; */
    height: 52px;

    /* display: flex; */
}

.boxcalender {
    width: 26%;
    margin-top: 10px;
}

#pributtonBox {
    /* border: 1px solid green; */
    width: 40%;
    height: 40px;
    background-color: black;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.priiconx {
    width: 8%;
    height: 28%;
    /* border: 1px solid green; */
}

#pributtonBox > p {
    color: white;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    /* border: 1px solid green; */
    font-family: 'NewHero', sans-serif;
}

.bannerBox1 {
    width: 90%;
    height: 340px;
    /* border: 1px solid green; */
    margin-left: 60px;
    border-radius: 15px;
    background: gray;
}

.category {
    font-size: 20px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    color: black;
    margin-top: 35px;
    margin-left: 100px;
}

.line1 {
    border: 1px black solid;
    width: 85%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

/* .categoryBox{
 
  width: 85%;
  height: auto;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 10px;
 
  margin-bottom: 25px;
}
.catbox{
  
 height: 48px;
 display: flex;

 
 justify-content: space-evenly;
 
}
.icondiv{
  
  width: 48px;
  height: 100%;
  display: flex;
}
.catLo{
  height: 100%;
  width: 100%;
}
.pBox{
 
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
}
.pBox>p{
  font-size: 12px;
  font-weight: 500;
  font-family: 'NewHero', sans-serif;
  color: black;
 
 
 
} */
