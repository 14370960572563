* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

#brand-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f4f4f4;
}

.Bmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.brand-container-A {
    width: auto;
    height: 225px;
    display: flex;
    margin: auto;
    /* justify-content: space-between; */

    margin-top: 154px;
    gap: 184px;
}

.BCCat {
    color: #26394a;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* height: 44px; */
}

.BClngText {
    display: flex;
    flex-direction: column;
    width: 641px;
}

.BC1t {
    color: #26394a;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* width: 641px; */
    /* height: 126px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    line-height: 21px;
}

.BC2t {
    color: #83694f;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    width: 629px;
    /* height: 126px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin-top: 18px;
    line-height: 17px;
    letter-spacing: normal;
    width: 590px;
}

.BxbgBox {
    border-radius: 11px;
    border: none;
    width: 76%;
    height: 683px;

    margin: auto;
    background-image: url(../Assests/inf1.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    /* background: #c4c4c4; */
    margin-top: 94px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    display: flex;
    flex-direction: column;
    margin-bottom: 95px;
}

.brand-container-b {
    width: 81.2%;
    height: 495px;
    display: flex;

    justify-content: space-between;
    margin: auto;
    margin-bottom: 113px;
}

.bc-div1 {
    width: 42.4%;
    height: 100%;

    background-image: url(../Assests/wn1.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 15px;

    background: grey;
}

.bc-div2 {
    width: 55%;
    height: 100%;

    background: rgba(255, 255, 255, 0.9);

    border-radius: 15px;
    box-shadow:
        4px 4px 3px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
    padding: 20px 0px 18px 0px;
}

.brand-wune-logo {
    width: 137px;
    height: 73px;
    background-image: url(../Assests/wn.jpg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;

    margin-bottom: 9px;
}

.intro-of-wune {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    position: relative;
    width: 89.9%;
    margin: auto;
    margin-bottom: 19px;
}

.wune-target {
    color: #1058a8;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    line-height: 11px;
    font-weight: 500;
    position: relative;
    margin-left: 5.05%;
    margin-bottom: 10px;
}

.custom-list {
    list-style-type: none;
    /* Removes default bullet points */
    padding-left: 5.05%;
    /* Resets default padding */
    margin-bottom: 18px;
}

.custom-list li {
    margin-bottom: 1px;
    /* Adds space between list items */
    color: #26394a;
    text-align: left;
    font-family: 'Newheromediumitalic', sans-serif;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;

    position: relative;
}

.custom-list li:hover {
    text-decoration: underline;
}

.custom-list li::before {
    content: '\2022';
    /* Unicode for bullet point character */
    color: #26394a;
    /* Change bullet point color */
    margin-right: 10px;
    /* Adds space between bullet and text */
}

.bc-div2 > button {
    background: rgba(217, 217, 217, 0);
    border-radius: 50px;
    border-style: solid;
    border-color: #1058a8;
    border-width: 0.7px;
    width: 100px;
    height: 29px;
    margin-left: 5.05%;
    color: #1058a8;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 13px;
    /* line-height: 11px; */
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.applies-to-all {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 8px;
    line-height: 11px;
    font-weight: 400;
    position: relative;
    width: 89.1%;
    margin-left: 5.05%;
}

.brand-container-c {
    width: 81.2%;
    height: 495px;
    display: flex;

    justify-content: space-between;
    margin: auto;
    margin-bottom: 113px;
}

.bc-div1-c {
    width: 42.4%;
    height: 100%;
    background-image: url(../Assests/cl1.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    border-radius: 15px;
    opacity: 0.949999988079071;
    /* background: grey; */
}

.bc-div2-c {
    width: 55%;
    height: 100%;

    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow:
        4px 4px 3px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-bottom: 18px;
}

.brand-wune-logo-c {
    width: 137px;
    height: 73px;
    background-image: url(../Assests/col1.jpg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;

    margin-bottom: 9px;
}

.intro-of-wune-c {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    position: relative;
    width: 89.9%;
    margin: auto;
    margin-bottom: 19px;
}

.wune-target-c {
    color: #ce0f37;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    line-height: 11px;
    font-weight: 500;
    position: relative;
    margin-left: 5.05%;
    margin-bottom: 10px;
}

.custom-list-c {
    list-style-type: none;
    /* Removes default bullet points */
    padding-left: 5.05%;
    /* Resets default padding */
    margin-bottom: 17px;
}

.custom-list-c li {
    margin-bottom: 1px;
    /* Adds space between list items */
    color: #26394a;
    text-align: left;
    font-family: 'Newheromediumitalic', sans-serif;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;

    position: relative;
}

.custom-list-c li:hover {
    text-decoration: underline;
}

.custom-list-c li::before {
    content: '\2022';
    /* Unicode for bullet point character */
    color: #26394a;
    /* Change bullet point color */
    margin-right: 10px;
    /* Adds space between bullet and text */
}

.bc-div2-c > button {
    background: rgba(217, 217, 217, 0);
    border-radius: 50px;
    border-style: solid;
    border-color: #df0000;
    border-width: 0.7px;
    width: 181px;
    height: 29px;
    margin-left: 5.05%;
    color: #df0000;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 13px;
    /* line-height: 11px; */
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
}

.bc-link-p2 {
    color: #df0000;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 13px;
    /* line-height: 11px; */
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.applies-to-all-c {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 8px;
    line-height: 11px;
    font-weight: 400;
    position: relative;
    width: 89.1%;
    margin-left: 5.05%;
}

.brand-container-d {
    width: 81.2%;
    height: 495px;
    display: flex;

    justify-content: space-between;
    margin: auto;
    margin-bottom: 95px;
}

.bc-div1-d {
    width: 42.4%;
    height: 100%;
    background-image: url(../Assests/de1.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    border-radius: 15px;
    opacity: 0.949999988079071;
    /* background: grey; */
}

.bc-div2-d {
    width: 55%;
    height: 100%;

    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow:
        4px 4px 3px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-bottom: 18px;
}

.brand-wune-logo-d {
    width: 137px;
    height: 73px;
    background-image: url(../Assests/def.jpg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;

    margin-bottom: 9px;
}

.intro-of-wune-d {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    position: relative;
    width: 89.9%;
    margin: auto;
    margin-bottom: 19px;
}

.wune-target-d {
    color: #262e6a;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    line-height: 11px;
    font-weight: 500;
    position: relative;
    margin-left: 5.05%;
    margin-bottom: 10px;
}

.custom-list-d {
    list-style-type: none;
    /* Removes default bullet points */
    padding-left: 5.05%;
    /* Resets default padding */
    margin-bottom: 17px;
}

.custom-list-d li {
    margin-bottom: 1px;
    /* Adds space between list items */
    color: #26394a;
    text-align: left;
    font-family: 'Newheromediumitalic', sans-serif;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;

    position: relative;
}

.custom-list-d li:hover {
    text-decoration: underline;
}

.custom-list-d li::before {
    content: '\2022';
    /* Unicode for bullet point character */
    color: #26394a;
    /* Change bullet point color */
    margin-right: 10px;
    /* Adds space between bullet and text */
}

.bc-div2-d > button {
    border: none;
    background: rgba(217, 217, 217, 0);
    border-radius: 50px;
    border-style: solid;
    border-color: #1058a8;
    border-width: 0.7px;
    width: 100px;
    height: 29px;
    margin-left: 5.05%;

    margin-bottom: 15px;
}

.bc-link-p1 {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-color: #1058a8;
    color: #1058a8;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 13px;
    /* line-height: 11px; */
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.applies-to-all-d {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 8px;
    line-height: 11px;
    font-weight: 400;
    position: relative;
    width: 89.1%;
    margin-left: 5.05%;
}

.speciality-nutrition {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 30px;
    font-weight: 400;
    position: relative;
    margin-left: 15.9%;
    margin-bottom: 38px;
}

.brand-container-e {
    width: 68.3%;
    height: 175px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
}

.brand-container-e > div {
    width: 48.73%;
    height: 100%;

    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow:
        0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        -4px -4px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
}

.brand-img-div1 {
    width: 30.5%;
    height: 100%;

    border-radius: 15px 0px 0px 15px;
    background-image: url(../Assests/os1.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.brand-img-div2 {
    width: 69.5%;
    height: 100%;

    padding-left: 21px;
}

.pediatric-specialty {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-top: 28px;
}

.Abott-offers {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    width: 95%;

    margin-top: 7px;
}

.explore-osemil {
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    position: relative;
    margin-top: 17px;
}

.design-to-help {
    color: #26394a;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-left: 15.9%;
    margin-bottom: 95px;
}

.brand-container-f {
    background: #ffffff;
    border-radius: 13px;
    width: 77.1%;
    height: 236px;
    position: relative;
    box-shadow:
        0px 4px 6px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
    margin: auto;
    display: flex;
    flex-direction: column;

    padding-left: 64px;
    margin-bottom: 97px;
}

.expand-Your-product {
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    width: 75%;

    margin-top: 50px;
}

.Offering-Market-ready-brands {
    color: #000000;
    text-align: left;
    font-family: 'NewHero-Regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 53%;

    margin-top: 20px;
}

.brand-container-f > button {
    border: none;
    background: #83694f;
    border-radius: 16px;
    width: 83px;
    height: 32px;

    margin-top: 20px;
    color: #ffffff;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.waysto-brand {
    color: #26394a;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 28px;
    font-weight: 500;
    position: relative;
    margin-bottom: 58px;
}

.brand-container-g {
    width: 100%;
    height: 365px;

    margin-bottom: 106px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 15px;
    padding-left: 6%;
    padding-right: 6%;
}

.brnd1-link {
    width: 302px;
    height: 331px;
    text-decoration: none;
}

.card-br1 {
    width: 100%;
    height: 100%;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #ffffff;
    color: #ffffff;
    /* padding-top: 17px;
  padding-bottom: 17px; */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 106px;
}

.card-br1 > div {
    height: 180px;
    width: 100%;
    background: grey;
    border-radius: 21px 21px 0px 0px;
}

.br-1 {
    color: #26394a;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    margin-top: 13px;
}

.br-2 {
    color: #26394a;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin-top: 11px;
    margin-bottom: 11px;
}

.br-30 {
    color: #83694f;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 3px; */
    /* margin-bottom: 11px; */
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
    display: inline-block;
    text-decoration: none;
    width: auto;
}

.br-30::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #83694f;
    transition: height 0.3s ease;
    margin-left: 10%;
}

.br-30:hover::after {
    height: 2px;
    background-color: #83694f;
    bottom: -1px;
    width: 80%;
}

.br-31 {
    color: #83694f;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 3px; */
    /* margin-bottom: 11px; */
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.br-31::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #83694f;
    transition: height 0.3s ease;
    margin-left: 12%;
}

.br-31:hover::after {
    height: 2px;
    background-color: #83694f;
    bottom: -1px;
    width: 75%;
}

.br-32 {
    color: #83694f;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 3px; */
    /* margin-bottom: 11px; */
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.br-32::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #83694f;
    transition: height 0.3s ease;
    margin-left: 8%;
}

.br-32:hover::after {
    height: 2px;
    background-color: #83694f;
    bottom: -2px;
    width: 84%;
}

.br-33 {
    color: #83694f;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 3px; */
    /* margin-bottom: 11px; */
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.br-33::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #83694f;
    transition: height 0.3s ease;
    margin-left: 25%;
}

.br-33:hover::after {
    height: 2px;
    background-color: #83694f;
    bottom: -2px;
    width: 50%;
}

.brand-container-h {
    display: none;
}

@media (max-width: 767px) {
    #brand-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f4f4f4;
    }

    .animated-cursor {
        display: none;
    }

    .Bmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #f4f4f4;
        margin-top: 60px;
    }

    .brand-container-A {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        /* justify-content: space-between; */

        margin-top: 84px;
        gap: 0px;
    }

    .BCCat {
        color: #26394a;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 25px;
        font-weight: 400;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        margin-left: 5.5%;
        margin-bottom: 19px;
        /* height: 44px; */
    }

    .BClngText {
        display: flex;
        flex-direction: column;
        width: 88%;

        margin: auto;
    }

    .BC1t {
        color: #26394a;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        position: relative;
        /* width: 641px; */
        /* height: 126px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        margin-bottom: 6px;
    }

    .BC2t {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        position: relative;
        width: 344px;
        /* height: 126px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        margin-top: initial;

        letter-spacing: normal;
    }

    .BxbgBox {
        border-radius: 11px;
        border: none;
        width: 88%;
        height: 217px;

        margin: auto;
        background-image: url(../Assests/inf1.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        /* background: #c4c4c4; */
        margin-top: 46px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        display: flex;
        flex-direction: column;
        margin-bottom: 53px;
    }

    .brand-container-b {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        justify-content: initial;
        margin: auto;
        margin-bottom: 53px;
    }

    .bc-div1 {
        width: 88%;
        height: 343px;

        background-image: url(../Assests/wn1.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 15px;
        opacity: 0.949999988079071;
        margin: auto;
        /* background: grey; */
    }

    .bc-div2 {
        width: 88%;
        height: 599px;

        background: rgba(255, 255, 255, 0.9);

        border-radius: 15px;
        box-shadow:
            4px 4px 3px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
        margin: auto;
        margin-top: 26px;
    }

    .brand-wune-logo {
        width: 137px;
        height: 73px;
        background-image: url(../Assests/wn.jpg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 9px;
    }

    .intro-of-wune {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        position: relative;
        width: 89.8%;
        margin: auto;
        margin-bottom: 15px;
    }

    .wune-target {
        color: #1058a8;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 13px;
        line-height: 11px;
        font-weight: 500;
        position: relative;
        margin-left: 5.05%;
        margin-bottom: 10px;
    }

    .custom-list {
        list-style-type: none;
        /* Removes default bullet points */
        padding-left: 5.05%;
        /* Resets default padding */
        margin-bottom: 23px;
    }

    .custom-list li {
        margin-bottom: 2px;
        /* Adds space between list items */
        color: #26394a;
        text-align: left;
        font-family: 'Newheromediumitalic', sans-serif;
        font-size: 13px;
        line-height: 21px;
        font-weight: 500;

        position: relative;
    }

    .custom-list li:hover {
        text-decoration: underline;
    }

    .custom-list li::before {
        content: '\2022';
        /* Unicode for bullet point character */
        color: #26394a;
        /* Change bullet point color */
        margin-right: 10px;
        /* Adds space between bullet and text */
    }

    .bc-div2 > button {
        background: rgba(217, 217, 217, 0);
        border-radius: 50px;
        border-style: solid;
        border-color: #1058a8;
        border-width: 0.7px;
        width: 110px;
        height: 29px;
        margin-left: 5.05%;
        color: #1058a8;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 13px;
        /* line-height: 11px; */
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 33px;
    }

    .applies-to-all {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 8px;
        line-height: 11px;
        font-weight: 400;
        position: relative;
        width: 89.1%;
        margin-left: 5.05%;
        margin-bottom: 28px;
    }

    .brand-container-c {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        justify-content: initial;
        margin: auto;
        margin-bottom: 56px;
    }

    .bc-div1-c {
        width: 88%;
        height: 343px;
        background-image: url(../Assests/cl1.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        border-radius: 15px;
        opacity: 0.949999988079071;
        /* background: grey; */
        margin: auto;
    }

    .bc-div2-c {
        width: 88%;
        height: 599px;

        background: rgba(255, 255, 255, 0.9);
        border-radius: 15px;
        box-shadow:
            4px 4px 3px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
        margin: auto;
        margin-top: 28px;
    }

    .brand-wune-logo-c {
        width: 137px;
        height: 73px;
        background-image: url(../Assests/col1.jpg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 13px;
    }

    .intro-of-wune-c {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        position: relative;
        width: 89.8%;
        margin: auto;
        margin-bottom: 15px;
    }

    .wune-target-c {
        color: #ce0f37;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        line-height: 11px;
        font-weight: 500;
        position: relative;
        margin-left: 5.05%;
        margin-bottom: 15px;
    }

    .custom-list-c {
        list-style-type: none;
        /* Removes default bullet points */
        padding-left: 5.05%;
        /* Resets default padding */
        margin-bottom: 24px;
    }

    .custom-list-c li {
        margin-bottom: 2px;
        /* Adds space between list items */
        color: #26394a;
        text-align: left;
        font-family: 'Newheromediumitalic', sans-serif;
        font-size: 13px;
        line-height: 21px;
        font-weight: 500;

        position: relative;
    }

    .custom-list-c li:hover {
        text-decoration: underline;
    }

    .custom-list-c li::before {
        content: '\2022';
        /* Unicode for bullet point character */
        color: #26394a;
        /* Change bullet point color */
        margin-right: 10px;
        /* Adds space between bullet and text */
    }

    .bc-div2-c > button {
        background: rgba(217, 217, 217, 0);
        border-radius: 50px;
        border-style: solid;
        border-color: #df0000;
        border-width: 0.7px;
        width: 181px;
        height: 29px;
        margin-left: 5.05%;
        color: #df0000;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 13px;
        /* line-height: 11px; */
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 24px;
    }

    .applies-to-all-c {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 8px;
        line-height: 11px;
        font-weight: 400;
        position: relative;
        width: 89.1%;
        margin-left: 5.05%;
        margin-bottom: 88px;
    }

    .brand-container-d {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        justify-content: initial;
        margin: auto;
        margin-bottom: 32px;
    }

    .bc-div1-d {
        width: 88%;
        height: 343px;
        background-image: url(../Assests/de1.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        border-radius: 15px;
        opacity: 0.949999988079071;
        /* background: grey; */
        margin: auto;
    }

    .bc-div2-d {
        width: 88%;
        height: 599px;

        background: rgba(255, 255, 255, 0.9);
        border-radius: 15px;
        box-shadow:
            4px 4px 3px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 3px 1px rgba(0, 0, 0, 0.05);
        margin: auto;
        margin-top: 26px;
    }

    .brand-wune-logo-d {
        width: 137px;
        height: 73px;
        background-image: url(../Assests/def.jpg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 13px;
    }

    .intro-of-wune-d {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        position: relative;
        width: 89.9%;
        margin: auto;
        margin-bottom: 15px;
    }

    .wune-target-d {
        color: #262e6a;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        line-height: 11px;
        font-weight: 500;
        position: relative;
        margin-left: 5.05%;
        margin-bottom: 15px;
    }

    .custom-list-d {
        list-style-type: none;
        /* Removes default bullet points */
        padding-left: 5.05%;
        /* Resets default padding */
        margin-bottom: 23px;
    }

    .custom-list-d li {
        margin-bottom: 2px;
        /* Adds space between list items */
        color: #26394a;
        text-align: left;
        font-family: 'Newheromediumitalic', sans-serif;
        font-size: 13px;
        line-height: 21px;
        font-weight: 500;

        position: relative;
    }

    .custom-list-d li:hover {
        text-decoration: underline;
    }

    .custom-list-d li::before {
        content: '\2022';
        /* Unicode for bullet point character */
        color: #26394a;
        /* Change bullet point color */
        margin-right: 10px;
        /* Adds space between bullet and text */
    }

    .bc-div2-d > button {
        background: rgba(217, 217, 217, 0);
        border-radius: 50px;
        border-style: solid;
        border-color: #262e6a;
        border-width: 0.7px;
        width: 100px;
        height: 29px;
        margin-left: 5.05%;
        color: #1058a8;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 13px;
        /* line-height: 11px; */
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 24px;
    }

    .applies-to-all-d {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 8px;
        line-height: 11px;
        font-weight: 400;
        position: relative;
        width: 89.1%;
        margin-left: 5.05%;
        margin-bottom: 28px;
    }

    .speciality-nutrition {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 30px;
        font-weight: 400;
        position: relative;
        margin-left: 15.9%;
        margin-bottom: 32px;
    }

    .brand-container-e {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        margin: auto;
        margin-bottom: 50px;
    }

    .brand-container-e > div {
        width: 88%;
        height: 297px;

        background: rgba(255, 255, 255, 0.9);
        border-radius: 15px;
        box-shadow:
            0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            -4px -4px 4px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 32px;
    }

    .brand-img-div1 {
        width: 100%;
        height: 178px;

        border-radius: initial;
        border-radius: 15px 15px 0px 0px;
        background-image: url(../Assests/os1.jpg);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background: grey;
    }

    .brand-img-div2 {
        width: 100%;
        height: 119px;

        padding-left: 27px;
    }

    .pediatric-specialty {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-top: 16px;
    }

    .Abott-offers {
        color: #26394a;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        width: 95%;

        margin-top: 7px;
    }

    .explore-osemil {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-decoration: underline;
        position: relative;
        margin-top: 7px;
        width: 95%;
    }

    .design-to-help {
        display: none;
    }

    .brand-container-f {
        display: none;
    }

    .waysto-brand {
        color: #26394a;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-bottom: 11px;
    }

    .brand-container-g {
        display: none;
    }

    .brand-container-h {
        display: block;

        width: 100%;
        margin-bottom: 52px;
    }

    .brnd1-link {
        display: none;
    }

    .brnd1-link-h {
        /* width: 77%;
    height: 331px; */
        text-decoration: none;
    }

    .card-br1-h {
        width: 77%;
        height: 331px;
        border-radius: 21px;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #fffefb;
        color: #ffffff;
        /* padding-top: 17px;
    padding-bottom: 17px; */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        margin: auto;
        margin-bottom: 40px;
    }

    .card-br1-h > div {
        height: 180px;
        width: 100%;
        background: grey;
        border-radius: 21px 21px 0px 0px;
    }

    .br-1-h {
        color: #26394a;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        margin-top: 15px;
    }

    .br-2-h {
        color: #26394a;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        margin: auto;
        margin-top: 9px;
        margin-bottom: 11px;
        width: 85%;
    }

    .br-30-h {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
        width: auto;
    }

    .br-30-h::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 10%;
    }

    .br-30-h:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -1px;
        width: 80%;
    }

    .br-31-h {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .br-31-h::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 12%;
    }

    .br-31-h:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -1px;
        width: 75%;
    }

    .br-32-h {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .br-32-h::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 8%;
    }

    .br-32-h:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -2px;
        width: 84%;
    }

    .br-33-h {
        color: #83694f;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        /* margin-top: 3px; */
        /* margin-bottom: 11px; */
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        position: relative;
        display: inline-block;
        text-decoration: none;
    }

    .br-33-h::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #83694f;
        transition: height 0.3s ease;
        margin-left: 25%;
    }

    .br-33-h:hover::after {
        height: 2px;
        background-color: #83694f;
        bottom: -2px;
        width: 50%;
    }
}
