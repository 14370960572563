* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.Contact-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f6f4f2;
}

.Contactmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.cntusp1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 42px;
    font-weight: 400;
    position: relative;
    margin: auto;
    margin-top: 111px;
    margin-bottom: 30px;
}

.cntusbtn {
    display: flex;

    height: 53px;
    width: 186px;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 138px;
    color: #ffffff;

    /* text-align: center; */
}

.cntusbtn > .btn1 {
    background: #151515;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cntusbtn > .btn1 > p {
    color: #ffffff;
    /* text-align: left; */
    font-family: 'HelveticaRegular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.und4 {
    border-style: solid;
    border-color: #ff5914;
    border-width: 1px 0 0 0;
    width: 89%;
    height: 0px;
    position: relative;
    margin: auto;
    margin-bottom: 45px;
}

.hnab {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    margin-left: 5.5%;
    margin-bottom: 49px;
}

.cntdivbox {
    width: 89%;
    height: auto;

    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 66px;
}

.cndus1 {
    width: 48.4%;
    height: 100%;
}

.us1 {
    color: #151515;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    font-weight: 400;
    position: relative;
    /* margin-top: 10px; */
}

.us2 {
    color: #151515;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    position: relative;
    width: 100%;
    height: 77px;
}

.us3 {
    width: 100%;
    height: 166px;

    display: flex;
    justify-content: space-between;
    margin-top: 7px;
}

.us3 > div {
    width: 47%;
    height: 100%;

    background: #ffffff;
    border-radius: 15px;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 24px;
}

.us3a {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    text-decoration: underline;
    position: relative;
}

.us3b {
    color: #151515;

    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.us3c {
    color: #151515;

    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.us3d {
    color: #151515;

    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.us4 {
    color: #151515;
    text-align: left;
    font-family: 'HelveticaRegular', sans-serif;
    font-size: 10px;
    font-weight: 400;
    position: relative;
    width: 90.3%;
    margin-top: 14px;
}

/* .us5 {
  width: 76.3%;
  height: 216px;
 
  margin-top: 21px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 11px;
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.05),
    -4px -4px 4px 1px rgba(0, 0, 0, 0.05);
}
.accordion {
 
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}
.accordion-item {
 
}
.accordion-header {
 
  display: flex;
  align-items: center;
  
  gap: 10px;
  color: #000000;
 
  font-family: "HelveticaRegular", sans-serif;
  font-size: 15px;
  font-weight: 400;
  position: relative;
}
.accordion-content {
 
  display: flex;
  margin-left: 7%;
  
  font-family: "HelveticaRegular", sans-serif;
  font-size: 8px;
  font-weight: 400;
  position: relative;
} */
.cndus2 {
    width: 49%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.dus2 {
    color: #151515;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    font-weight: 400;
    position: relative;
    margin-bottom: 8px;
}

.dus3 {
    width: 100%;
    height: 30px;
    border: none;
    /* background: rgba(217, 217, 217, 0); */
    border-radius: 4px;
    border-style: solid;
    /* border-color: #000000; */
    border-width: 0.5px;
    padding-left: 10px;
    color: #979797;

    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
}

.dus4 {
    width: 100%;
    height: 30px;

    margin-top: 13px;
    display: flex;
    justify-content: space-between;
}

.dus4 > input {
    width: 47.9%;
    height: 100%;
    border: none;
    border-radius: 4px;
    border-style: solid;

    border-width: 0.5px;
    padding-left: 10px;
    color: #979797;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
}

.dus4x {
    width: 100%;
    height: 30px;

    margin-top: 13px;
    display: flex;
    justify-content: space-between;
}

.dus4x > input {
    width: 47.9%;
    height: 100%;
    border: none;
    border-radius: 4px;
    border-style: solid;

    border-width: 0.5px;
    padding-left: 10px;
    color: #979797;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.dus5 {
    width: 100%;
    height: 99px;
    margin-top: 13px;
    border: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 0.5px;
    padding: 10px;
    color: #979797;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    resize: none;
    /* Prevent resizing of textarea */
}

/* .dus5>p{
    color: #979797;
 
  font-family: "HelveticaMedium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  margin-left: 11px;

  } */
.dus6 {
    width: 100%;
    height: 15px;
    display: flex;
    margin-top: 13px;
}

.dus6 > input {
    border: 0.5px solid;
    margin-right: 10px;
}

.dus6 > p {
    color: #151515;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
}

.dus7 {
    width: 12.5%;
    height: 27px;
    color: white;
    background: #151515;
    border-radius: 4px;
    margin-top: 13px;
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 10px;
    font-weight: 500;
    position: relative;
}

.dus1g {
    display: none;
}

.accordion-container {
    width: 61%;
    height: 834px;
    margin: auto;
    margin-bottom: 114px;
    background: var(--neutral-100, #ffffff);
    border-radius: 18px;
}

.f-ask {
    margin-left: 11.5%;
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    position: relative;
    margin-top: 57px;
    margin-bottom: 47px;
}

/* Accordion styles */
.accordion {
    background: none;
    margin-bottom: 10px;

    width: 79.4%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ff5914;
}

/* Accordion header styles */
.accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    display: flex;

    justify-content: space-between;

    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    position: relative;
    height: 104px;
}

.accordion-title {
    /* margin-left: 30px; */
}

/* Accordion content styles */
.accordion-content {
    padding: 10px;
    background-color: #fff;

    margin-top: -40px;
}

@media (max-width: 767px) {
    .Contact-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f6f4f2;
    }

    .animated-cursor {
        display: none;
    }

    .Contactmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .cntusp1 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 30px;
        font-weight: 400;
        position: relative;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .cntusbtn {
        display: flex;

        height: 53px;
        width: 186px;
        justify-content: space-between;
        margin: auto;
        margin-bottom: 50px;
        color: #ffffff;

        /* text-align: center; */
    }

    .cntusbtn > .btn1 {
        background: #151515;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cntusbtn > .btn1 > p {
        color: #ffffff;
        /* text-align: left; */
        font-family: 'HelveticaRegular', sans-serif;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .und4 {
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px 0 0 0;
        width: 89%;
        height: 0px;
        position: relative;
        margin: auto;
        margin-bottom: 21px;
    }

    .hnab {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-left: 5.5%;
        margin-bottom: 27px;
    }

    .cntdivbox {
        width: 100%;
        height: auto;

        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: initial;
        margin-bottom: 30px;
    }

    .cndus1 {
        width: 88%;
        height: 100%;
        /* border: 1px solid green; */
        margin: auto;
    }

    .us1 {
        color: #151515;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 24px;
        font-weight: 400;
        position: relative;
        /* margin-top: 10px; */
    }

    .us2 {
        color: #151515;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        position: relative;
        width: 100%;
        height: auto;
    }

    .us3 {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 7px;
        row-gap: 20px;
    }

    .us3 > div {
        width: 88%;
        height: 166px;

        background: #ffffff;
        border-radius: 15px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0px;
        /* margin: auto; */
    }

    .us3a {
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        text-decoration: underline;
        position: relative;
    }

    .us3b {
        color: #151515;

        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 400;
    }

    .us3c {
        color: #151515;

        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 400;
    }

    .us3d {
        color: #151515;

        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 400;
    }

    .us4 {
        color: #151515;
        text-align: left;
        font-family: 'HelveticaRegular', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        width: 90.3%;
        margin-top: 14px;
    }

    .cndus2 {
        width: 88%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 20px;
    }

    .dus2 {
        color: #151515;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 24px;
        font-weight: 500;
        position: relative;
        margin-bottom: 8px;
    }

    .dus3 {
        width: 100%;
        height: 30px;
        border: none;
        /* background: rgba(217, 217, 217, 0); */
        border-radius: 4px;
        border-style: solid;
        /* border-color: #000000; */
        border-width: 0.5px;
        padding-left: 10px;
        color: #979797;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
    }

    .dus4 {
        width: 100%;
        height: 30px;

        margin-top: 13px;
        display: flex;
        justify-content: space-between;
    }

    .dus4 > input {
        width: 47.9%;
        height: 100%;
        border: none;
        border-radius: 4px;
        border-style: solid;

        border-width: 0.5px;
        padding-left: 10px;
        color: #979797;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
    }

    .dus4x {
        width: 100%;
        height: 30px;

        margin-top: 13px;
        display: flex;
        justify-content: space-between;
    }

    .dus4x > input {
        width: 47.9%;
        height: 100%;
        border: none;
        border-radius: 4px;
        border-style: solid;

        border-width: 0.5px;
        padding-left: 10px;
        color: #979797;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }

    .dus5 {
        width: 100%;
        height: 99px;
        margin-top: 13px;
        border: none;
        border-radius: 4px;
        border-style: solid;
        border-width: 0.5px;
        padding: 10px;
        color: #979797;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        resize: none;
        /* Prevent resizing of textarea */
    }

    /* .dus5>p{
      color: #979797;
   
    font-family: "HelveticaMedium", sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    margin-left: 11px;
  
    } */
    .dus6 {
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 13px;
    }

    .dus6 > input {
        border: 0.5px solid;
        margin-right: 10px;
    }

    .dus6 > p {
        color: #151515;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }

    .dus7 {
        width: 12.5%;
        height: 27px;
        color: white;
        background: #151515;
        border-radius: 4px;
        margin-top: 13px;
        color: #ffffff;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        margin-top: 10px;
    }

    .dus1g {
        display: none;
    }

    .accordion-container {
        width: 88%;
        height: auto;
        margin: auto;
        margin-bottom: 50px;
        background: var(--neutral-100, #ffffff);
        border-radius: 18px;
        padding-bottom: 30px;
    }

    .f-ask {
        margin-left: 10px;
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 22px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    /* Accordion styles */
    .accordion {
        background: none;
        margin-bottom: 10px;

        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d83760;
    }

    /* Accordion header styles */
    .accordion-header {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        display: flex;

        justify-content: space-between;

        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        line-height: 28px;
        font-weight: 400;
        position: relative;
        height: 104px;
    }

    .accordion-title {
        /* margin-left: 30px; */
    }

    /* Accordion content styles */
    .accordion-content {
        padding: 10px;
        background-color: #fff;

        margin-top: -20px;
    }
}
