* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#productContainer {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    background: #fffdf8;
}

#productNavbar {
    width: 100%;
    height: 70px;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: fixed; */
    margin-bottom: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.pnavTextbox {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.pvector {
    width: 32px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px;
}

.ptextHello1 {
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.pnavtext1 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
}

.imageBoxp {
    /* border: 1px solid green; */
    height: 700px;
    width: 100%;
    display: flex;
    /* padding: 60px; */
    padding-left: 60px;
    padding-right: 60px;
    /* justify-content:center ;
    align-items: center; */
}

.div1 {
    width: 100%;
    height: 100%;
    display: flex;

    background: #fffdf8;
}

.img1box {
    /* width: 200%; */
    height: 100%;
    width: 100%;
    /* background-position: 100% 100%; */
    /* background-size: cover; */

    border-radius: 60px;
}

.proText {
    margin-bottom: 40px;
    margin-top: 40px;

    /* width: 70%; */
    /* margin-left: 60px; */
    padding-left: 60px;
    font-size: 55px;
    font-family: 'NewHero', sans-serif;
    color: #222222;
    font-weight: 500;
}

.prdText {
    color: #a083bc;
}

.cardContainer {
    width: 100%;
    display: flex;
    overflow-x: auto;
    /* Hide the scroll bar */
    -webkit-overflow-scrolling: touch;
    /* Enable smooth scrolling on iOS devices */
    white-space: nowrap;
    padding-left: 60px;
    padding-right: 60px;
    gap: 50px;
    background: #fffdf8;
    margin: auto;
    margin-bottom: 130px;
    touch-action: pan-x;
    /* Enable touch scrolling */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Hide scrollbar in IE/Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.CardBox {
    /* border: 1px solid green; */
    background: #fffdf8;
}

.ImageCard:hover {
    transform: scale(1.05);
}

.ImageCard {
    height: 250px;
    border-radius: 25px;
    background: #d9d9d9;
}

.Cp1 {
    font-size: 15px;
    font-weight: 600;
    color: #222222;
    font-family: 'NewHero', sans-serif;

    /* margin-top: 25px;
    margin-bottom: 15px; */
    padding-top: 20px;
}

.Cp2 {
    font-size: 11px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
    /* margin-bottom: 20px; */
    padding-top: 10px;
}

.Cp3 {
    font-size: 10px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
    /* margin-bottom: 20px; */
    padding-top: 10px;
    padding-bottom: 40px;
}

.buttondiv:hover {
    transform: scale(1.05);
}

.buttonlink {
    /* width: 45%; */
    text-decoration: none;
}

.buttondiv {
    width: 45%;
    border: 1px #a0a0a0 solid;
    background: rgba(217, 217, 217, 0);
    height: 45px;
    border-radius: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
}

.Cp4 {
    font-size: 12px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
}

.Tnavbar4 {
    display: none;
}

@media (max-width: 767px) {
    .Tnavbar4 {
        display: initial;
        width: 100%;
        height: 60px;

        display: flex;
        background: #fffdf8;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .vectorN4 {
        width: 40px;
        height: 30px;
        /* margin-left: 4%; */
        margin-right: 5px;
    }

    .pBox4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 5px;
        margin: auto;
    }

    .huby3 {
        font-size: 12px;
        font-weight: 600;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    .huby4 {
        font-size: 15px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    #productNavbar {
        width: 100%;
        height: 70px;
        background-color: black;
        color: white;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* position: fixed; */
        margin-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .pnavTextbox {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding-left: 20px;
    }

    .pvector {
        display: none;

        width: 32px;
        height: 25px;
        margin-left: 4%;
        margin-right: 5px;
    }

    .imageBoxp {
        /* border: 1px solid green; */
        height: 150px;
        width: 100%;
        display: flex;
        /* padding: 60px; */
        padding-left: 20px;
        padding-right: 20px;
        /* justify-content:center ;
      align-items: center; */
    }

    .div1 {
        width: 100%;
        height: 100%;
        display: flex;

        background: #fffdf8;
    }

    .img1box {
        /* width: 200%; */
        height: 100%;
        width: 100%;
        /* background-position: 100% 100%; */
        /* background-size: cover; */

        border-radius: 14px;
    }

    .proText {
        margin-bottom: 30px;
        margin-top: 20px;

        width: 100%;
        /* margin-left: 20px; */
        padding-left: 20px;
        font-size: 20px;
        font-family: 'NewHero', sans-serif;
        color: #222222;
        font-weight: 500;
    }

    .prdText {
        color: #a083bc;
    }

    .cardContainer::-webkit-scrollbar {
        width: 0;
    }

    .cardContainer {
        width: 100%;
        display: flex;
        overflow-x: auto;
        white-space: nowrap;

        padding-left: 30px;
        padding-right: 30px;
        padding: 20px;
        gap: 50px;
        background: #fffdf8;
        /* height: 200px; */
        /* padding-bottom: 150px; */
        /* margin: auto; */
        margin-bottom: 150px;
    }

    .CardBox {
        /* width: 70%; */
        /* border: 1px solid green; */
        background: #fffdf8;
    }

    .ImageCard:hover {
        transform: scale(1.05);
    }

    .ImageCard {
        height: 110px;
        border-radius: 16px;
        background: #d9d9d9;
    }

    .Cp1 {
        font-size: 10px;
        font-weight: 600;
        color: #222222;
        font-family: 'NewHero', sans-serif;

        /* margin-top: 25px;
      margin-bottom: 15px; */
        padding-top: 20px;
        word-wrap: break-word;
    }

    .Cp2 {
        font-size: 8px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
        /* margin-bottom: 20px; */
        padding-top: 10px;
        word-wrap: break-word;
    }

    .Cp3 {
        font-size: 7px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
        /* margin-bottom: 20px; */
        padding-top: 10px;
        padding-bottom: 10px;
        /* white-space: nowrap; */
    }

    .buttondiv:hover {
        transform: scale(1.05);
    }

    .buttonlink {
        /* width: 45%; */
        text-decoration: none;
    }

    .buttondiv {
        width: 70%;
        border: 1px #a0a0a0 solid;
        background: rgba(217, 217, 217, 0);
        height: 35px;
        border-radius: 22.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-bottom: 20px; */
    }

    .Cp4 {
        font-size: 10px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }
}
