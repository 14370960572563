*,
*::after,
*::before {
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: rgb(204, 204, 204);
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* position: fixed;
    z-index: 1;
    top: 0px;
    left: 0px; */
    background-color: #ffffff;
    padding-left: 62px;
    padding-right: 90px;
}

.nav__brand {
    text-transform: uppercase;
}

.nav__menu {
    display: flex;
    align-items: left;
    /* justify-content: space-around; */
    gap: 3rem;
}

/* .nav__link{
    color: #181818;
    font-size: 13px;
    font-weight: 400;
    font-family: "HelveticaRegular",sans-serif;
    text-align: center;
    line-height: 20px;
    letter-spacing: normal;
    text-decoration: none;
} */
.nav__link p {
    color: #05050a;
    text-decoration: none;
    display: block;
    padding: 5px 0;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 1;
    font-weight: 400;
    position: relative;
    z-index: 1;
    text-align: center;
}

.nav__link p:before {
    display: block;
    content: '';
    width: 0;
    height: 3px;
    bottom: 5px;
    left: 0;
    bottom: -3px;
    z-index: 0;
    position: absolute;
    background: #23abd4;
    transition: all 1s ease-in-out;
}

.nav__link p:hover {
    background-position: 0%;

    &:before {
        width: 100%;
    }
}

.nav__toggler {
    display: none;
}

.nav__toggler div {
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: black;
    transition: 0.3s ease-in;
}

.jhatku {
    display: none;
}

.cdrdp {
    display: none;
}

@media screen and (max-width: 768px) {
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        position: fixed;
        z-index: 1;
        top: 0px;
        left: 0px;
        background-color: #fffffe;
        padding-left: 0px;
        padding-right: 30px;
    }

    .nav__toggler {
        display: block;
        cursor: pointer;
    }

    .nav__menu {
        padding-top: 40px;
        /* padding-left: 40px; */
        position: fixed;
        top: 8vh;
        right: 0;
        height: 100vh;
        width: 100%;
        background-color: #fffffe;
        flex-direction: column;
        transform: translateX(100%);
        transition: 0.5s ease-in;

        gap: 0px;
    }

    .nav__link > p {
        color: black;
        font-size: 20px;
        font-weight: 500;
        font-family: 'InterRegular', sans-serif;
        text-align: left;
        line-height: 50px;
        letter-spacing: normal;
        text-decoration: none;

        /* padding-top: 20px;
    padding-bottom: 20px; */
        /* border-top: 1px solid red; */
        border-color: #979797;
        border-width: 1px 0 0 0;
        width: 88%;
        margin: auto;
        border-style: solid;
    }

    .cdrdp {
        width: 88%;

        height: 220px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-right: 26px;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
        margin: auto;
        margin-top: 10px;
    }

    .kinlot1dp {
        display: flex;
        align-items: center;
        margin-top: 45px;
        margin-left: 20px;
        color: #000000;
    }

    .logo11dp {
        width: 45px;
        height: 22px;
        /* margin-left: 20px; */
        /* align-items: center; */
        color: #000000;
    }

    .vline1dp {
        border: 1px solid #000000;
        height: 22px;
        margin-right: 5px;
        color: #000000;
    }

    .hubp1dp {
        font-size: 20px;
        font-family: 'HelveticaMedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #000000;
        height: 22px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .acprdtdp {
        font-size: 15px;
        font-family: 'NewHero', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
        color: #000000;
        margin-left: 20px;
        margin-top: 18px;
        margin-bottom: 10px;

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .crdunderdp {
        display: flex;

        align-items: center;
    }

    .cdrt21dp {
        font-size: 15px;
        font-family: 'HelveticaMedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #df0000;
        /* line-height: 52px; */
        /* margin-left: 20px; */
        /* margin-top: px; */

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
        position: relative;
        display: inline-block;
        line-height: 20px;
        margin-left: 20px;
    }

    .cdrt21dp::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 0%;
        height: 1px;
        /* Initial thickness of underline */
        background-color: #df0000;
        /* Initial underline color */
        transition: height 0.3s ease;
        /* Transition for thickness change */
    }

    .cdrt21dp:hover::after {
        height: 2px;
        /* Increase thickness on hover */
        background-color: #df0000;
        /* Color of the thicker underline */
        bottom: -1px;
        width: 100%;
    }
}

/* Active Class */
.nav__active {
    transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 5px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-4px, -5px);
}

.nav__item {
    margin: 0 20px;
    position: relative;
}
