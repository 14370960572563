.hero__slider {
    width: 100%;
    height: 90vh;
    background: #000;
    margin-top: 40px;

    @media (max-width: 575px) {
        height: 70vh;
    }

    .swiper-button-prev {
        visibility: hidden;
        top: calc(100% - 50px) !important;
        left: 24px !important;

        @media (max-width: 575px) {
            visibility: visible;
        }
    }

    .swiper-button-prev::after {
        font-size: 20px !important;
        font-weight: 700;
    }

    .swiper-button-next {
        visibility: hidden;
        top: calc(100% - 50px) !important;
        right: 24px !important;
        @media (max-width: 575px) {
            visibility: visible;
        }
    }

    .swiper-button-next::after {
        font-size: 20px !important;
        font-weight: 700;
    }

    .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
    }

    .swiper-pagination {
        @media (max-width: 575px) {
            bottom: 38px;
        }
    }
}
