.NavBoxmaintm {
    width: 100%;
    height: auto;
    background: white;

    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;

    display: flex;
    flex-direction: column;
}

.NavBox1main {
    width: 100%;
    height: 38px;

    background: #05050a;
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding-left: 90px;
    padding-right: 90px;
}

.msearch {
    display: flex;

    justify-content: space-between;
    width: 30%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.cntus {
    display: flex;

    align-items: center;
    gap: 5px;
}

.srchicon {
    display: flex;

    align-items: center;
    gap: 5px;
}

.NText1m {
    color: #c6c5c5;

    font-size: 12px;
    font-weight: 300;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.NText2m {
    color: #c6c5c5;
    font-size: 12px;
    font-weight: 300;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.NText3m {
    color: #c6c5c5;
    font-size: 12px;
    font-weight: 300;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.NText4m {
    color: #c6c5c5;
    font-size: 12px;
    font-weight: 300;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.NavBox2m {
    width: 100%;
    height: 52px;

    justify-content: space-between;
    display: flex;
    align-items: center;

    padding-left: 62px;
    padding-right: 90px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.iml {
    width: 149px;
    height: 52px;
}

.mainMenu {
    display: flex;

    gap: 40px;
}

.mainMenu > p {
    color: #181818;
    font-size: 13px;
    font-weight: 400;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    letter-spacing: normal;
    text-decoration: none;
}

.linkmenu {
    color: #181818;
    font-size: 13px;
    font-weight: 400;
    font-family: 'HelveticaRegular', sans-serif;
    text-align: center;
    line-height: 20px;
    letter-spacing: normal;
    text-decoration: none;
}

.linkmenu:hover {
    text-decoration: underline;
    color: #181818;
}

.burgline {
    display: none;
}

@media (max-width: 767px) {
    .NavBoxmaintm {
        width: 100%;
        height: 59px;
        background: white;

        position: fixed;
        z-index: 1000;
        top: 0px;
        left: 0px;

        display: flex;
        flex-direction: column;
    }

    .NavBox1main {
        display: none;
    }

    .NavBox2m {
        width: 100%;
        height: 59px;

        justify-content: space-between;
        display: flex;
        align-items: center;

        padding-left: initial;
        padding-right: initial;

        padding-right: 24px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .mainMenu {
        display: none;
    }

    .burgline {
        display: block;

        font-size: 28px;
    }

    .iml {
        width: 149px;
        height: 100%;
    }
}
