* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

#Private-containerx {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f6f4f2;
}

.Bmainx {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.tbdiv1 {
    border-radius: 26px;
    width: 89%;
    height: 356px;
    position: relative;
    /* background: grey; */
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}

.tbd-pr1 {
    width: 36.25%;
    height: 100%;
    background: grey;
    border-radius: 26px;
}

.tbd-pr2 {
    width: 61.41%;
    height: 100%;
    background: linear-gradient(
        -89.92deg,
        rgba(32, 190, 215, 0.9) 0%,
        rgba(80, 129, 173, 0.9) 100%
    );
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tbd-pr2 > p {
    color: #ffffff;
    font-family: 'InterRegular', sans-serif;
    font-size: 40px;
    font-weight: 400;
    width: 36.52%;
    text-align: center;
}

.sports-brand {
    color: #ffffff;
    font-family: 'Interbold', sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.priv-sp-nutri {
    margin-top: 80px;
    color: #181818;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 36px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 5.5%;
    position: relative;
}

.kriyexp {
    display: none;
}

.circletext {
    height: 18px;
    width: 50%;
    background: grey;
    margin-top: 38px;
    margin-left: 80px;
}

.tablx {
    width: 89%;
    margin: auto;
    margin-top: 42px;
    background: rgba(217, 217, 217, 0);
    border-radius: 11px;
    border-style: solid;
    border-color: #000000;
    border-width: 1px;
}

table {
    width: 100%;

    /* margin-bottom: 20px; */

    margin: auto;

    border-collapse: collapse;
    border-radius: 11px;
    overflow: hidden;
}

th,
td {
    border: 1px solid #ddd;
    /* padding: 8px; */
    text-align: left;
    height: 46px;

    padding-left: 15px;
}

th {
    /* background-color: #ffffff; */

    color: #000000;

    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
}

td {
    border: 1px solid #ffffff;
    /* background: rgba(217, 217, 217, 0.8); */
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
}

.xycategoryx {
    font-size: 20px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    color: black;
    margin-top: 97px;
    margin-left: 11.5%;
}

.xyline1x {
    border: 0.5px solid;
    width: 77.1%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.zcategoryBoxx {
    width: 77.1%;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    margin-bottom: 88px;
}

.zcatboxx {
    /* border: 1px solid green; */
    height: 48px;
    display: flex;

    /* align-items: flex-start; */
    justify-content: space-evenly;
}

.zicondivx {
    /* border: 1px solid green; */
    width: 48px;
    height: 100%;
    display: flex;
}

.zcatLox {
    height: 100%;
    width: 100%;
}

.zpBoxx {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
}

.zpBoxx > p {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Newheromedium', sans-serif;
    color: black;
    /* border: 1px solid green; */
}

.pc-form-div {
    width: 73.75%;
    height: 416px;
    margin: auto;
    display: flex;

    margin-bottom: 75px;
    background: #ffffff;
    box-shadow:
        0px 4px 6px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
}

.priv-div-l {
    width: 56.41%;

    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 42px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.priv-exp-p {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    position: relative;
}

.priv-collab {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-top: 13px;
    margin-bottom: 19px;
}

.priv-div-l > input {
    border: none;
    background: none;
    width: 67.5%;
    height: 25px;
    border-bottom: 1px solid #ff5914;
    margin-bottom: 25px;
    color: #727272;

    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.priv-div-l > textarea {
    border: none;
    background: none;
    width: 67.5%;
    height: 61px;
    border-bottom: 1px solid #ff5914;
    margin-bottom: 20px;
    color: #727272;

    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.priv-div-l > button {
    border: none;
    background: #ff5914;
    border-radius: 16px;
    width: 112px;
    height: 32px;
    position: relative;
    color: #ffffff;

    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.priv-div-r {
    width: 43.59%;

    height: 100%;
    background: grey;
    border-radius: 0px 15px 15px 0px;
}

.hgemplovdon1x {
    display: none;
}

.kriyediv {
    display: none;
}

.hgemplovdon1x {
    display: none;
}

.tnndiv1lovdon1x {
    display: none;
}

.priv-sp-nutri {
}

@media (max-width: 767px) {
    #Private-containerx {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f6f4f2;
    }

    .animated-cursor {
        display: none;
    }

    .Bmainx {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .tbdiv1 {
        border-radius: 26px;
        width: 90%;
        height: 224px;
        position: relative;
        background: grey;
        margin: auto;

        margin-top: 50px;
    }

    .cprvtext {
        display: none;
    }

    .priv-sp-nutri {
        display: block;
        color: #181818;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        margin-left: 25px;
        margin-top: 41px;
    }

    .tbd-pr1 {
        display: none;
    }

    .tbd-pr2 {
        display: none;
    }

    .circletext {
        height: 18px;
        width: 88%;
        background: grey;
        margin-top: 10px;
        margin-left: 25px;
    }

    .kriyediv {
        display: block;
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 119px;
        background: #ffffff;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);

        margin: auto;
        margin-top: 53px;
        border-radius: 11px;
        margin-bottom: 25px;
        justify-content: space-around;
        padding-left: 20px;
    }

    .kriyediv > div {
        display: flex;
    }

    .kriyexp1 {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        margin-right: 20px;

        width: 90px;
    }

    .kriyexp2 {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
    }

    .tablx {
        display: none;
    }

    table {
        display: none;
    }

    .xycategoryx {
        font-size: 20px;
        font-weight: 500;
        font-family: 'NewHero', sans-serif;
        color: #000000;
        margin-top: 20px;
        width: 100%;
        margin-left: 6%;
    }

    .xyline1x {
        border: 0.5px solid;
        width: 90%;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .zcategoryBoxx {
        width: 90%;
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 47px;
    }

    .zcatboxx {
        /* border: 1px solid green; */
        height: 48px;
        display: flex;

        /* align-items: flex-start; */
        justify-content: space-evenly;
    }

    .zicondivx {
        /* border: 1px solid green; */
        width: 48px;
        height: 100%;
        display: flex;
    }

    .zcatLox {
        height: 100%;
        width: 100%;
    }

    .zpBoxx {
        height: 100%;
        width: 60%;
        display: flex;
        align-items: center;
    }

    .zpBoxx > p {
        font-size: 12px;
        font-weight: 500;
        font-family: 'Newheromedium', sans-serif;
        color: black;
        /* border: 1px solid green; */
    }

    .kriyexp {
        display: block;
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-left: 25px;
        margin-top: 20px;
    }

    .pc-form-div {
        width: 88%;
        height: auto;
        margin: auto;
        display: flex;
        flex-direction: column;

        margin-bottom: 50px;
        background: #ffffff;
        box-shadow:
            0px 4px 6px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
        border-radius: 13px;
    }

    .priv-div-l {
        width: 100%;

        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: auto;
    }

    .priv-exp-p {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: initial;
        font-weight: 400;
        position: relative;
        width: 86.59%;
    }

    .priv-collab {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-top: 13px;
        margin-bottom: 19px;
        width: 60%;
    }

    .priv-div-l > input {
        border: none;
        background: none;
        width: 86.59%;
        height: 25px;
        border-bottom: 1px solid #83694f;
        margin-bottom: 25px;
        color: #727272;

        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
    }

    .priv-div-l > textarea {
        border: none;
        background: none;
        width: 86.59%;
        height: 61px;
        border-bottom: 1px solid #ff5914;
        margin-bottom: 20px;
        color: #727272;

        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: relative;
    }

    .priv-div-l > button {
        border: none;
        background: #ff5914;
        border-radius: 16px;
        width: 112px;
        height: 32px;
        position: relative;
        color: #ffffff;

        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
    }

    .priv-div-r {
        display: none;
    }
}
