* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.Ingredients-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f6f4f2;
}

.Imain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #f6f4f2;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.ingbox {
    width: 89%;
    height: 625px;

    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    background: #000;
    margin-bottom: 30px;
    border-radius: 11px;
    padding-left: 64px;
}

.idnts1 {
    color: #ffffff;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 19px;
    font-weight: 400;
    position: relative;
    margin-top: auto;
}

.idnts2 {
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 55px;
    font-weight: 500;
    position: relative;
}

.idnts3 {
    color: #ffffff;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 25px;
    font-weight: 400;
    position: relative;
    margin-bottom: 57px;
}

.idnavigate {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-left: 5.5%;
}

.idselect {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 35px;
    font-weight: 500;
    position: relative;
    margin: auto;
    margin-bottom: 17px;
}

.jump {
    color: #151515;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    width: 536px;
    height: 43px;
    margin: auto;
    margin-bottom: 29px;
}

.lettera {
    display: flex;
    width: 66%;

    margin: auto;
    justify-content: space-between;
    /* margin-bottom: 30px; */
}

.lettera > p {
    color: #151515;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    position: relative;
}

.lettera > p::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    /* Adjust padding from text bottom */
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.lettera > p:hover::after {
    background-color: #13c1dd;
    bottom: -4px;
}

.und2 {
    border-style: solid;
    border-color: #727272;
    border-width: 1px 0 0 0;
    width: 89%;
    height: 0px;
    position: relative;
    margin: auto;
    margin-top: 4px;
    margin-bottom: 30px;
}

.ingrcards {
    width: 89%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: auto;
    gap: 15px;
    margin-bottom: 66px;
}

.ingrcards > div {
    height: 225px;
    width: 100%;
}

.ingrcards > div > div {
    height: 190px;
    width: 100%;
    background: #d9d9d9;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

    position: relative;
}

.ingrcards > div > p {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    margin-top: 13px;
}

.diclm {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    margin: auto;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    width: 89%;
    height: 71px;

    margin-bottom: 72px;
}

.ipublishcard {
    width: 89%;
    height: 306px;
    margin: auto;
    /* margin-top: 52px; */
    margin-bottom: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(242, 242, 242, 0.5);
    border-radius: 20px;
}

.ipublp1div {
    width: 26%;
    height: auto;

    display: flex;
    flex-direction: column;
}

.ipu1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* display: flex;
  align-items: center;
  justify-content: flex-start; */
}

.ipu2 {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    /* width: 220px; */
    margin-top: 10px;
    margin-bottom: 16px;
    /* display: flex;
  align-items: center;
  justify-content: flex-start; */
}

.ipu3 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 278px;
    line-height: 18px;
}

.ipublp2div {
    width: 70.9%;
    height: 84.6%;

    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 21px;
}

.ipublp2div > div {
    width: 31.6%;
    height: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 21px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.ipublp2div > div > div {
    height: 59.5%;
    width: 100%;
    background: #000000;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
}

.ipubt1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    position: relative;
    /* width: 262px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    margin-top: 15px;

    font-size: 20px;
    font-weight: 400;
}

.ipubt2 {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    font-weight: 500;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    margin-top: 15px;
}

.ipubt2:hover {
    color: #ff5914;
    text-decoration: underline;
}

#topupasita {
    display: none;
}

.ingrcardslove {
    display: none;
}

.letteralove {
    display: none;
}

.publishcardsita {
    display: none;
}

.ING-CHILD {
    display: none;
}

.product-form-ing {
    display: none;
}

@media (max-width: 767px) {
    .animated-cursor {
        display: none;
    }

    .Ingredients-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f6f4f2;
    }

    .Imain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .ingbox {
        width: 100%;
        height: 659px;

        margin: auto;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        background: #000;
        margin-bottom: 45px;
        border-radius: 0px;
        padding-left: 24px;
        /* text-align: center; */
    }

    .idnts1 {
        display: none;
    }

    .idnts2 {
        color: #ffffff;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 24px;
        font-weight: 500;
        position: relative;

        margin-top: initial;
        margin-top: auto;
        margin-bottom: 7px;
    }

    .idnts3 {
        color: #ffffff;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-bottom: 42px;
    }

    .idnavigate {
        display: none;
    }

    .idselect {
        color: #151515;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin: auto;
        margin-bottom: 8px;
    }

    .jump {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        position: relative;
        width: 85%;
        height: auto;
        margin: auto;
        margin-bottom: 24px;
    }

    .lettera {
        display: none;
    }

    .letteralove {
        display: flex;
        width: 85%;

        margin: auto;
        justify-content: space-between;
        /* margin-bottom: 30px; */
    }

    .letteralove > p {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        position: relative;
    }

    .letteralove > p::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        /* Adjust padding from text bottom */
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .letteralove > p:hover::after {
        background-color: #1e1e1e;
        bottom: -4px;
    }

    .und2 {
        border-style: solid;
        border-color: #727272;
        border-width: 1px 0 0 0;
        width: 85%;
        height: 0px;
        position: relative;
        margin: auto;
        margin-top: 4px;
        margin-bottom: 30px;
    }

    .ingrcards {
        display: none;
    }

    .ingrcardslove {
        width: 89%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: auto;
        gap: 15px;
        margin-bottom: 40px;
    }

    .ingrcardslove > div {
        height: 225px;
        width: 100%;
    }

    .ingrcardslove > div > div {
        height: 190px;
        width: 100%;
        background: #d9d9d9;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

        position: relative;
    }

    .ingrcardslove > div > p {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        position: relative;
        margin-top: 13px;
    }

    .diclm {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 10px;
        line-height: 15px;
        font-weight: 400;
        position: relative;
        width: 88%;
        margin: auto;

        height: auto;
        margin-bottom: 43px;
    }

    .ipublishcard {
        display: none;
    }

    #topupasita {
        display: block;
    }

    .ingredient-swiper-1 {
        height: auto;
        margin-bottom: 50px;
    }

    .ING-CHILD {
        display: block;
        width: 73.1%;
        height: 259px;

        margin: auto;
        display: flex;
        flex-direction: column;
        border-radius: 21px;
        margin-bottom: 35px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        background: #ffffff;
    }

    .ING-CHILD > div {
        width: 100%;
        height: 154px;
        background: gray;
        border-radius: 21px 21px 0px 0px;
    }

    .ipubt1 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        position: relative;
        /* width: 262px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: 15px;

        font-size: 20px;
        font-weight: 400;
    }

    .ipubt2 {
        color: #13c1dd;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        font-weight: 500;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: 13px;
    }

    .ipubt2:hover {
        color: #83694f;
        text-decoration: underline;
    }

    .product-form-ing {
        width: 88%;
        height: 464px;
        background: #ffffff;
        border-radius: 13px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin: auto;
        display: flex;
        flex-direction: column;

        padding-bottom: 27px;
        margin-bottom: 53px;
    }

    .form-p1-ing {
        width: 86.6%;
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin: auto;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .form-p2-ing {
        color: #151515;
        text-align: left;
        font-family: 'NewHero-Regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 86.6%;
        margin: auto;

        margin-bottom: 15px;
    }

    .product-form-ing > input {
        height: 30px;
        width: 86.6%;
        margin: auto;
        margin-bottom: 19px;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form-ing > textarea {
        width: 86.6%;
        height: 68px;
        margin: auto;
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form-ing > button {
        border: none;
        background: #13c1dd;
        border-radius: 16px;
        width: 83px;
        height: 32px;
        color: #ffffff;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 6.7%;
    }
}
