@-webkit-keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
    }
}
@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
    }
}

.slider__strem {
    margin: auto;
    position: relative;
    width: 100%;
    padding: 30px 0;

    &::after,
    &::before {
        background: linear-gradient(
            to right,
            white 0%,
            rgba(255, 255, 255, 0) 100%
        );
        content: '';
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        -webkit-animation: scroll 100s linear infinite;
        animation: scroll 100s linear infinite;
        display: flex;
        width: calc(250px * 14);
        gap: 10px;
    }

    .slide {
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        // padding: 10px;
        // padding-left: 30px;
        // padding-right: 30px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: grayscale(1);
        }
    }

    &.slider__strem__2 {
        flex-direction: row-reverse;
        justify-content: flex-end;
        transform: rotate(180deg);
        margin-top: 0px;

        .slide-track {
            -webkit-animation: scroll 100s linear infinite;
            animation: scroll 100s linear infinite;
            display: flex;
            width: calc(250px * 14);

            .slide {
                transform: rotate(-180deg);
            }
        }
    }
}
