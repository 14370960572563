.footer {
    width: 100%;
    height: auto;

    margin-top: 0px;
    background: #000;
    display: flex;
    padding: 2rem 1.5rem;
}

.foot {
    width: 94%;

    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.ft1 {
    font-size: 16px;
    /* line-height: 1.3; */

    color: #eae8e1;
    font-family: 'HelveticaRegular', sans-serif;
    /* letter-spacing: -.2px; */
    /* padding-left: 24px; */
    font-weight: 400;
}

.footdivl {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.leftfoot {
    /* border: 1px solid yellowgreen; */
    width: auto;
    height: auto;
}

.inputbox {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.checkbox-container {
    display: flex;
    align-items: center;

    font-size: 0.75rem;
    color: #eae8e1;
    font-family: 'HelveticaRegular', sans-serif;
    margin-top: 20px;
    align-items: center;
}

.checkbox-custom {
    margin-right: 10px;
}

input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    /* Adjust the checkbox width */
    height: 16px;
    /* Adjust the checkbox height */
    border: 1px solid white;
    /* Border for the checkbox */
    outline: none;
    /* Remove the default focus outline */
    position: relative;
    /* color: white; */
}

/* Style the checkmark inside the checkbox */
input[type='checkbox']::after {
    content: '\2713';
    /* Unicode checkmark character */
    font-size: 16px;
    /* Adjust the checkmark size */
    color: transparent;
    /* Make the checkmark transparent initially */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Style the checked state */
input[type='checkbox']:checked::after {
    color: #000;
    /* Change the checkmark color when checked */
}

.icondiv {
    display: flex;
    width: 60%;
    height: auto;

    justify-content: space-between;
    margin-top: 40px;
}

.rightfoot {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.cafetext {
    display: grid;
    height: 70px;
    width: 60%;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    color: #eae8e1;
}

.cafetext > p {
    font-size: 1.5rem;
    font-family: 'HelveticaRegular', sans-serif;
    font-weight: 400;
}

.custom-select {
    width: 250px;
    height: 60px;
    font-size: 16px;
    /* Adjust the font size as needed */
    padding: 10px;
    /* Add padding for better spacing */
    border-radius: 5px;
    /* Rounded corners for a better look */
    font-family: 'HelveticaRegular', sans-serif;
    color: #eae8e1;
    background-color: transparent;
    /* Set background color to transparent */
    border: 1px solid #eae8e1;
    /* Border for visualization */
    /* Additional styling if needed */
    font-weight: 400;
}

.line1x {
    width: 90%;
    border: 0.5px solid #eae8e1;
    margin-top: 10px;
}

.line2x {
    width: 100%;
    border: 0.5px solid #eae8e1;
    margin-top: 20px;
}

.fottext {
    font-size: 10px;
    color: #eae8e1;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.fottext > p {
    border-right: 1px solid #eae8e1;
    padding-right: 10px;
}

@media (max-width: 767px) {
    footer {
        width: 100%;
        height: auto;

        margin-top: 0px;
        background: #26394a;
        display: flex;
        padding: 0rem 0rem;
    }

    .foot {
        width: 100%;

        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ft1 {
        font-size: 16px;
        /* line-height: 1.3; */

        color: #eae8e1;
        font-family: 'HelveticaRegular', sans-serif;
        /* letter-spacing: -.2px; */
        /* padding-left: 24px; */
        font-weight: 400;
    }

    .footdivl {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        justify-content: space-between;
    }

    .leftfoot {
        /* border: 1px solid yellowgreen; */
        width: auto;
        height: auto;
        margin-bottom: 20px;
    }

    .inputbox {
        display: flex;
        align-items: center;
        width: 98%;
        justify-content: space-between;
    }

    .checkbox-container {
        display: flex;
        align-items: center;

        font-size: 0.75rem;
        color: #eae8e1;
        font-family: 'HelveticaRegular', sans-serif;
        margin-top: 20px;
        align-items: center;
    }

    .checkbox-custom {
        margin-right: 10px;
    }

    input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 16px;
        /* Adjust the checkbox width */
        height: 16px;
        /* Adjust the checkbox height */
        border: 1px solid white;
        /* Border for the checkbox */
        outline: none;
        /* Remove the default focus outline */
        position: relative;
        /* color: white; */
    }

    /* Style the checkmark inside the checkbox */
    input[type='checkbox']::after {
        content: '\2713';
        /* Unicode checkmark character */
        font-size: 16px;
        /* Adjust the checkmark size */
        color: transparent;
        /* Make the checkmark transparent initially */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Style the checked state */
    input[type='checkbox']:checked::after {
        color: #000;
        /* Change the checkmark color when checked */
    }

    .icondiv {
        display: flex;
        width: 60%;
        height: auto;

        justify-content: space-between;
        margin-top: 40px;
    }

    .rightfoot {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cafetext {
        display: grid;
        height: 70px;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        color: #eae8e1;
    }

    .cafetext > p {
        font-size: 1.4rem;
        font-family: 'HelveticaRegular', sans-serif;
        font-weight: 400;
    }

    .custom-select {
        width: 250px;
        height: 40px;
        font-size: 16px;
        /* Adjust the font size as needed */
        padding: 0px;
        /* Add padding for better spacing */
        border-radius: 5px;
        /* Rounded corners for a better look */
        font-family: 'HelveticaRegular', sans-serif;
        color: #eae8e1;
        background-color: transparent;
        /* Set background color to transparent */
        border: 1px solid #eae8e1;
        /* Border for visualization */
        /* Additional styling if needed */
        font-weight: 400;
        margin-top: 20px;
        /* padding-left: 10px; */
        padding-right: 10px;
    }

    .line1x {
        width: 90%;
        border: 0.5px solid #eae8e1;
        margin-top: 10px;
    }

    .line2x {
        width: 90%;
        border: 0.5px solid #eae8e1;
        margin-top: 20px;
    }

    .fottext {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        gap: 10px;
    }

    .fottext > p {
        border-right: none;
        padding-right: 0px;
        color: #eae8e1;
        font-size: 12px;
    }
}
