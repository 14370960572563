@font-face {
    font-family: 'NewHero';
    src:
        local('New Hero'),
        url(../fonts/New-Hero-Regular.otf) format('opentype');

    /* You can include different font weights and styles here if needed */
}
@font-face {
    font-family: 'NewHero1';
    src:
        local('New Hero'),
        url(../fonts/New\ Hero\ ExtraBold.otf) format('opentype');

    /* You can include different font weights and styles here if needed */
}
@font-face {
    font-family: 'NewHero2';
    src:
        local('New Hero'),
        url(../fonts/New\ Hero\ SemiBold.otf) format('opentype');

    /* You can include different font weights and styles here if needed */
}

@font-face {
    font-family: 'NewheroRegular';
    src: url(../fonts/New-Hero-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Newheromedium';
    src: url(../fonts/New\ Hero\ Medium.otf) format('opentype');
}
@font-face {
    font-family: 'Newherobold';
    src: url(../fonts/New\ Hero\ Bold.otf) format('opentype');
}
@font-face {
    font-family: 'Newherosemibold';
    src: url(../fonts/New\ Hero\ SemiBold.otf) format('opentype');
}
@font-face {
    font-family: 'Newheroextrabold';
    src: url(../fonts/New\ Hero\ ExtraBold.otf) format('opentype');
}
@font-face {
    font-family: 'Newheromediumitalic';
    src: url(../fonts/New\ Hero\ Medium\ Italic.otf) format('opentype');
}
@font-face {
    font-family: 'Noto-regular';
    src: url(../fonts/NotoSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Noto-medium';
    src: url(../fonts/NotoSans-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Noto-bold';
    src: url(../fonts/NotoSans-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Noto-semibold';
    src: url(../fonts/NotoSans-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'giloryextrabold';
    src: url(../fonts/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
    font-family: 'source-sans-pro-regular';
    src: url(../fonts/SourceSansPro-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'source-sans-pro-semibold';
    src: url(../fonts/SourceSansPro-Semibold.ttf) format('truetype');
}
@font-face {
    font-family: 'Manrope';
    src:
        local('Manrope'),
        url(../fonts/Manrope-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Manrope1';

    src:
        local('Manrope'),
        url(../fonts/Manrope-ExtraBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Manrope2';
    src:
        local('Manrope'),
        url(../fonts/Manrope-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Manrope3';
    src:
        local('Manrope'),
        url(../fonts/Manrope-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'InterRegular';
    src:
        local('Inter'),
        url(../fonts/Inter-Regular.ttf) format('truetype');
    /* You can include different font weights and styles here if needed */
}
@font-face {
    font-family: 'InterMedium';
    src:
        local('Inter'),
        url(../fonts/Inter-Medium.ttf) format('truetype');
    /* You can include different font weights and styles here if needed */
}
@font-face {
    font-family: 'Interbold';
    src:
        local('Inter'),
        url(../fonts/Inter-Bold.ttf) format('truetype');
    /* You can include different font weights and styles here if needed */
}
@font-face {
    font-family: 'HelveticaRegular';
    src:
        url(../fonts/velveticaregular.ttf) format('truetype'),
        url(../fonts/velveticaregular.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaMedium';
    src: url(../fonts/velveticamedium.ttf) format('truetype');
}
@font-face {
    font-family: 'Helveticabold';
    src:
        url(../fonts/velveticabold.ttf) format('truetype'),
        url(../fonts/velveticabold.otf) format('opentype');
}
