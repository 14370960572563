* {
    padding: 0;
    margin: 0;
    /* box-sizing: border-box; */
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.Product-container {
    width: 100%;
    height: auto;
    /* display: none; */

    overflow-x: hidden;
    background: #f6f4f2;
}

.Product-main {
    margin: auto;
    width: 100%;
    /* height: 600px; */
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
}

.product-container-1 {
    width: 25%;
    height: 120px;
    background-image: url(../Assests/cha.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 31px;
}

.Product-menu {
    border-top: 1px solid #1058a8;
    border-bottom: 1px solid #1058a8;
    width: 89%;
    height: 47px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    padding-left: 8.52%;
    padding-right: 8.52%;
}

.Product-menu > p {
    color: #002e76;
    /* text-align: left; */
    font-family: 'Helveticabold', sans-serif;
    font-size: 13px;
    font-weight: 700;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.product-container-A {
    width: 80.5%;
    height: 419px;

    margin: auto;
    margin-bottom: 88px;
    display: flex;
    justify-content: space-between;
}

.pc-a-1 {
    width: 42.71%;
    height: 100%;

    background: grey;
}

.pc-a-2 {
    width: 47%;
    height: 100%;
}

.pro-surgery-support {
    color: #0f4b91;
    text-align: left;
    font-family: 'Noto-medium', sans-serif;
    font-size: 32px;
    font-weight: 500;
    position: relative;
    margin-bottom: 19px;
}

.pro-centrum-advance {
    color: #181818;
    text-align: left;
    font-family: 'Noto-medium', sans-serif;
    font-size: 15px;

    font-weight: 500;
    position: relative;
    width: 100%;
    margin-bottom: 28px;
    /* height: 357px; */
}

.product-list {
    /* list-style-type: none; */
    /* padding-left: 20; */
}

.product-list li {
    margin-bottom: 15px;
    color: #181818;
    font-family: 'Noto-medium', sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    word-wrap: break-word;
    position: relative;
    padding-left: 20px;
}

.product-list li::before {
    content: '\2022';
    color: black;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 5px;
}

.Product-Category-container {
    width: 77.1%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 91px;
}

.Product-Category-menu {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Product-Category-menu > p {
    width: 224px;
    height: 100%;
    background: #efefef;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #50504e;

    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
}

.Categorycard1 {
    width: 98%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 37px;
    row-gap: 29px;
    margin-top: 54px;
}

.Categorycard1 > div {
    height: 100px;
    display: flex;
    justify-content: space-between;
}

.cat-circle {
    width: 100px;
    height: 100%;
    border-radius: 50%;

    background: grey;
}

.cat-text-box {
    width: 77.5%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.energy-release {
    color: #0f4b91;
    text-align: left;
    font-family: 'Noto-regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
}

.vitamin-b12 {
    color: #000000;
    text-align: left;
    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    word-wrap: break-word;
}

.hari {
    display: flex;
    flex-direction: column;
}

.Categorycard2 {
    width: 98%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 37px;
    row-gap: 29px;
    margin-top: 54px;
}

.Categorycard2 > div {
    height: 100px;
    display: flex;
    justify-content: space-between;
}

.Categorycard2 > div > .cat-text-box {
    width: 77.5%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.Categorycard2 > div > .cat-text-box > .energy-release {
    color: #0f4b91;
    text-align: left;
    font-family: 'Noto-regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    margin-bottom: 10px;
}

.vsf {
    background: #002e76;
    border-radius: 21px;
    width: 233px;
    height: 42px;
    margin: auto;
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
}

/* .Categorycard2{
  width: 86.8%;
  height: auto;
 
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 60px;
  row-gap: 80px;
  margin-top: 54px;
} */

.Categorycard3 {
    width: 98%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 37px;
    row-gap: 29px;
    margin-top: 54px;
}

.Categorycard3 > div {
    height: 135px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.recommended-dosage {
    color: #0059af;
    text-align: left;
    font-family: 'Noto-regular', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    margin-bottom: 10px;
}

.box-of-text-main {
    display: flex;
    justify-content: space-between;
}

.cat-circle-3 {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: grey;
}

.cat-text-box-3 {
    width: 75%;
    height: 100px;

    display: flex;
    flex-direction: column;
}

.energy-release-3 {
    color: #50504e;
    text-align: left;
    font-family: 'Noto-regular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
}

.Categorycard4 {
    width: 94%;
    height: auto;

    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 54px;
}

.Categorycard4 > a {
    color: #0059af;
    text-align: left;
    font-family: 'Noto-medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.Product-Category-menu p.selected {
    background-color: #002e76;
    color: #ffffff;
    /* Add any other styles for the selected state */
}

.product-container-B {
    width: 78.4%;
    height: 203px;
    margin: auto;
    /* border:1px solid red; */
    background: grey;
    border-radius: 20px;
    margin-bottom: 77px;
}

.product-explore-text {
    color: #0f4b91;
    text-align: center;
    font-family: 'Noto-bold', sans-serif;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    margin-bottom: 43px;
}

.product-container-C {
    width: 85.1%;
    height: 272px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 62px;
}

.product-container-C > div {
    width: 17.72%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 21px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.pc-b-1 {
    width: 100%;
    height: 180px;
    background: grey;
    border-radius: 21px 21px 0px 0px;
}

.pc-b-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92px;

    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    border-radius: 0px 0px 21px 21px;
}

.pc-b-opth {
    color: #0f4b91;
    text-align: center;
    font-family: 'Noto-bold', sans-serif;
    font-size: 15px;
    font-weight: 700;
    position: relative;
}

.pc-b-tnp {
    color: #181818;
    text-align: center;
    font-family: 'Noto-regularr', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
}

.pc-b-vm {
    color: #0f4b91;
    text-align: center;
    font-family: 'Noto-bold', sans-serif;
    font-size: 12px;
    font-weight: 700;
    position: relative;
}

.product-line-1 {
    border-style: solid;
    border-color: #979797;
    border-width: 1px 0 0 0;
    width: 85.1%;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    margin: auto;
    margin-bottom: 60px;
}

.product-container-D {
    width: 73.2%;
    height: 280px;

    margin: auto;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.product-container-D > div {
    width: 28.1%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.pc-c-1 {
    width: 100%;
    height: 238.1px;
    background: grey;
    border-radius: 19px 19px 0px 0px;
}

.PC-C-2 {
    width: 100%;
    height: 41.9px;
    background: #002e76;
    border-radius: 0px 0px 19px 19px;
    padding-right: 15px;
}

.PC-c-aero-div {
    display: flex;
    align-items: center;
    color: #ffffff;
    /* text-align: left; */
    font-family: 'Newherobold', sans-serif;
    font-size: 15px;
    height: 100%;
    font-weight: 700;
    position: relative;
    justify-content: flex-end;
}

.PC-c-disclaimer {
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 10px;
    font-weight: 400;
    position: relative;
    width: 88%;
    margin: auto;
    margin-bottom: 58px;
}

.product-menu-for-mobilex {
    display: none;
}

.product-container-C-for-mobile {
    display: none;
}

.product-container-for-mobile {
    display: none;
}

.menu-px {
    display: none;
}

#pro-p {
    display: none;
}

@media (max-width: 767px) {
    .Product-main {
        margin: auto;
        width: 100%;
        /* height: 600px; */
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
    }

    .animated-cursor {
        display: none;
    }

    .product-container-1 {
        width: 300px;
        height: 120px;
        background-image: url(../Assests/cha.png);
        background-position: cover;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;
        margin-top: 39px;
        margin-bottom: 36px;
    }

    .Product-menu {
        display: none;
    }

    .product-menu-for-mobilex {
        display: block;

        height: 32px;
        width: 100%;

        display: flex;
        align-items: center;
        border: none;
        border-top: 1px solid #1058a8;
        border-bottom: 1px solid #1058a8;
        width: 88%;
        margin: auto;
        margin-bottom: 28px;
    }

    .menu-px {
        display: block;
        color: #1058a8;
        text-align: center;
        font-family: 'Noto-semibold', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        position: relative;

        width: 200px;
    }

    #mmmp-1 {
        width: 200px;
    }

    .product-container-A {
        width: 100%;
        height: auto;

        margin: auto;
        margin-bottom: 28px;
        display: flex;
        flex-direction: column;
        justify-content: initial;
    }

    .pc-a-1 {
        width: 79.4%;
        height: 264px;

        background: grey;
        margin: auto;
        margin-bottom: 15px;
    }

    .pc-a-2 {
        width: 88%;
        height: auto;

        margin: auto;
    }

    .pro-surgery-support {
        color: #0f4b91;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        margin-bottom: 19px;
    }

    .pro-centrum-advance {
        color: #181818;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 15px;

        font-weight: 500;
        position: relative;
        width: 93%;
        margin-bottom: 28px;
        /* height: 357px; */
    }

    .product-list {
        /* list-style-type: none; */
        /* padding-left: 20; */
    }

    .product-list li {
        margin-bottom: 15px;
        color: #181818;
        font-family: 'Noto-medium', sans-serif;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        word-wrap: break-word;
        position: relative;
        padding-left: 20px;

        width: 93%;
    }

    .product-list li::before {
        content: '\2022';
        color: black;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 5px;
    }

    .Product-Category-container {
        width: 88%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 40px;
    }

    .Product-Category-menu-for-mobile {
        height: 63px;
        width: 100%;
    }

    .Product-Category-menu {
        display: none;
        /* height: 42px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
     */
    }

    #pro-p {
        display: block;
        width: 160px;
        height: 63px;
        background: #efefef;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #50504e;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
    }

    #pro-p.selected {
        background-color: #002e76;
        color: #ffffff;
        /* Add any other styles for the selected state */
    }

    .Categorycard1 {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0px;
        row-gap: 50px;
        margin-top: 30px;
    }

    .Categorycard1 > div {
        height: auto;
        display: flex;
        justify-content: space-between;
    }

    .cat-circle {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: grey;
    }

    .cat-text-box {
        width: 66.5%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    .energy-release {
        color: #0f4b91;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 18px;
        font-weight: 400;
        position: relative;
    }

    .vitamin-b12 {
        color: #000000;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        word-wrap: break-word;
    }

    .Categorycard2 {
        width: 98%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0px;
        row-gap: 50px;
        margin-top: 30px;
    }

    .Categorycard2 > div {
        height: 100px;
        display: flex;
        justify-content: space-between;
    }

    .Categorycard2 > div > .cat-text-box {
        width: 66.5%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    .Categorycard2 > div > .cat-text-box > .energy-release {
        color: #0f4b91;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        margin-bottom: 10px;
    }

    .vsf {
        background: #002e76;
        border-radius: 21px;
        width: 233px;
        height: 42px;
        margin: auto;
        color: #ffffff;
        /* text-align: left; */
        font-family: 'Noto-regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        margin-top: 20px;
    }

    .Categorycard3 {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0px;
        row-gap: 50px;
        margin-top: 30px;
    }

    .Categorycard3 > div {
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
    }

    .recommended-dosage {
        color: #0059af;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 18px;
        font-weight: 400;
        position: relative;
        margin-bottom: 10px;
    }

    .box-of-text-main {
        display: flex;
        justify-content: space-between;
    }

    .cat-circle-3 {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: grey;
    }

    .cat-text-box-3 {
        width: 65.5%;
        height: auto;

        display: flex;
        flex-direction: column;
    }

    .energy-release-3 {
        color: #50504e;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
    }

    .Categorycard4 {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-top: 30px;
    }

    .Categorycard4 > a {
        color: #0059af;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 18px;
        font-weight: 500;
        position: relative;
    }

    .product-container-B {
        width: 88%;
        height: 401px;
        margin: auto;
        /* border:1px solid red; */
        background: grey;
        border-radius: 20px;
        margin-bottom: 44px;
    }

    .product-explore-text {
        color: #0f4b91;
        text-align: center;
        font-family: 'Noto-bold', sans-serif;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        margin-bottom: 38px;
    }

    .product-container-C {
        display: none;
        /* 
    width: 85.1%;
    height: 272px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 59px; */
    }

    .product-container-C-for-mobile {
        display: block;

        width: 100%;
        height: auto;
        margin-bottom: 27px;
    }

    .product-container-C > div {
        display: none;
        /* width: 17.72%;
     height: 100%;
     display: flex;
     flex-direction: column;
     border-radius: 21px;
     background: #ffffff;
     box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1),
      -4px -4px 4px 0px rgba(0, 0, 0, 0.1); */
    }

    .pro-slide-div {
        /* width: 55.2%; */
        height: 272px;
        display: flex;
        flex-direction: column;
        border-radius: 21px;
        background: #ffffff;
        box-shadow:
            4px 4px 4px 0px rgba(0, 0, 0, 0.1),
            -4px -4px 4px 0px rgba(0, 0, 0, 0.1);

        /* margin: auto; */
        margin-left: 25px;
        margin-bottom: 40px;
    }

    .pc-b-1 {
        width: 100%;
        height: 180px;
        background: grey;
        border-radius: 21px 21px 0px 0px;
    }

    .pc-b-2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 92px;

        padding-top: 16px;
        padding-bottom: 16px;
        justify-content: space-between;
        border-radius: 0px 0px 21px 21px;
    }

    .pc-b-opth {
        color: #0f4b91;
        text-align: center;
        font-family: 'Noto-bold', sans-serif;
        font-size: 15px;
        font-weight: 700;
        position: relative;
    }

    .pc-b-tnp {
        color: #181818;
        text-align: center;
        font-family: 'Noto-regularr', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }

    .pc-b-vm {
        color: #0f4b91;
        text-align: center;
        font-family: 'Noto-bold', sans-serif;
        font-size: 12px;
        font-weight: 700;
        position: relative;
    }

    .product-line-1 {
        border-style: solid;
        border-color: #979797;
        border-width: 1px 0 0 0;
        width: 85.1%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
        margin: auto;
        margin-bottom: 53px;
    }

    .product-container-D {
        display: none;
        /* width: 73.2%;
    height: 280px;
   
    margin: auto;
    margin-bottom: 40px;
    display: flex;
   justify-content: space-between; */
    }

    .product-container-for-mobile {
        display: block;

        margin-bottom: 53px;
    }

    .pro-d-slide {
        width: 75.32%;
        height: 281px;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 40px;
    }

    .product-container-D > div {
        display: none;
    }

    .pc-c-1 {
        display: none;
    }

    .PC-C-2 {
        display: none;
    }

    .PC-c-aero-div {
        display: none;
    }

    .pc-c-1x {
        width: 100%;
        height: 238.1px;
        background: grey;
        border-radius: 19px 19px 0px 0px;
    }

    .PC-C-2x {
        width: 100%;
        height: 41.9px;
        background: #002e76;
        border-radius: 0px 0px 19px 19px;
        padding-right: 15px;
    }

    .PC-c-aero-divx {
        display: flex;
        align-items: center;
        color: #ffffff;
        /* text-align: left; */
        font-family: 'Newherobold', sans-serif;
        font-size: 15px;
        height: 100%;
        font-weight: 700;
        position: relative;
        justify-content: flex-end;
    }

    .PC-c-disclaimer {
        display: none;
    }

    .PC-contact {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 53px;
        justify-content: initial;
    }

    .PC-c-cp-1 {
        width: 88%;
        height: 243px;
        background: #ffffff;
        border-radius: 13px;
        box-shadow:
            0px 4px 6px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
        padding-left: 20px;
        margin: auto;
    }

    .PC-cp-cp-2 {
        color: #002e76;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        position: relative;
        width: 70%;
        height: 85px;

        /* margin: auto; */
        margin-top: 9px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .PC-c-cp-3 {
        color: #002e76;
        text-align: left;
        font-family: 'Noto-regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-left: 0px;
        margin-top: 9px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .PC-input-container {
        display: flex;
        flex-direction: column;

        width: 95%;
        justify-content: space-between;
        margin-left: 0px;
        margin-top: 9px;
        height: auto;
    }

    #PC-input-box {
        /* background: transparent; */
        /* background: none; */
        border: none;
        /* Hide all borders */
        background: rgba(217, 217, 217, 0);
        border-radius: 7px;
        border-style: solid;
        border-color: #979797;
        border-width: 1px;
        width: 100%;
        height: 35px;
        color: #727272;

        font-family: 'Noto-medium', sans-serif;
        font-size: 12px;
        font-weight: 500;

        padding-left: 15px;
    }

    .PC-input-container > button {
        background: #002e76;
        border-radius: 7px;
        width: 83px;
        height: 28px;
        /* height: 32px; */
        position: relative;
        color: #ffffff;
        text-align: center;
        font-family: 'Noto-medium', sans-serif;
        font-size: 12px;
        border: none;
        margin-top: 19px;
    }

    .apexpand2-PC {
        width: 76.85%;
        height: 182px;
        background: #002e76;
        border-radius: 12px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 27px;
    }

    .apexpand2p1-PC {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: 55%;

        margin-left: 30px;
        margin-top: 29px;
        margin-bottom: 29px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .apexpand2p2-PC {
        color: #ffffff;
        text-align: left;
        font-family: 'Noto-medium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        margin-left: 30px;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        width: 78%;
    }

    .apexpand2p2-PC::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        /* Initial thickness of underline */
        background-color: #000000;
        /* Initial underline color */
        transition: height 0.3s ease;
        /* Transition for thickness change */
    }

    .apexpand2p2-PC:hover::after {
        height: 2px;
        /* Increase thickness on hover */
        background-color: #000000;
        /* Color of the thicker underline */
        bottom: -1px;
        width: 87%;
    }
}
