*body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background: #FFFDF8; */
}

#conatiner {
    width: 100%;
    /* height: 600px; */
    display: flex;
    /* background-color: #FFFDF8; */
    background: #fffdf8;
}

#navbar1 {
    width: 100%;
    height: 70px;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: fixed; */
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.navTextbox {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.vector {
    width: 32px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px;
}

.textHello1 {
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.navtext1 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
}

.topBox1x {
    display: flex;
    /* margin-left: 60px; */
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;

    /* margin-bottom: 15px; */
    margin-top: 20px;
    background: #fffdf8;
}

.para1x {
    font-size: 45px;
    font-weight: 550;
    font-family: 'NewHero', sans-serif;
}

.nutTxt1x {
    font-size: 45px;
    font-weight: 600;
    font-family: 'NewHero', sans-serif;
    color: #20bed7;
}

.pribox11x {
    /* border: 1px solid green; */
    width: 64%;
    height: 135px;
}

.pribox21x {
    /* width: 26%; */
    height: 52px;
    /* border: 1px solid green; */
    /* display: flex; */
}

.boxcalender1x {
    width: 26%;
    margin-top: 10px;
}

#pributtonBox10x {
    /* border: 1px solid green; */
    width: 40%;
    height: 40px;
    background-color: black;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.priiconx10x {
    width: 8%;
    height: 28%;
    /* border: 1px solid green; */
}

#pributtonBox10x > p {
    color: white;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    /* border: 1px solid green; */
    font-family: 'NewHero', sans-serif;
}

.card-container2 {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;

    /* padding-left: 60px; */
    justify-content: space-evenly;
    margin-bottom: 20px;
    background: #fffdf8;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

.card-container2::-webkit-scrollbar {
    display: none;
    /* Hide the scrollbar in webkit browsers */
}

.card2:hover {
    transform: scale(1.05);
    /* Scale up the card on hover */

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.card2 {
    flex: 0 0 auto;
    /* margin-right: 10px; */
    width: 30%;
    /* Adjust as needed */
    /* background-color: #fff;
  border: 1px solid #ddd; */
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

    border-radius: 32px;
    padding: 30px;
    box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.1);
    background: #fffdf8;

    transition:
        transform 0.3s,
        box-shadow 0.3s;
}

.card2 img {
    width: 100%;
    max-width: 100%;
    height: 20vh;

    border-radius: 20px;
}

.card-content2 {
    /* padding: 20px; */
    /* border: 1px solid green; */
    margin-top: 20px;
    line-height: 20px;
}

.card2 .title2 {
    font-size: 18px;
    font-family: 'NewHero', sans-serif;
    font-weight: 600;
    color: #282828;
}

.card2 .descrip2 {
    font-size: 10px;
    font-weight: 400;
    color: #282828;
    font-family: 'NewHero', sans-serif;
    /* word-wrap:nowrap; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card2 .explr2 {
    font-size: 11px;
    font-weight: 600;
    color: black;
    font-family: 'NewHero', sans-serif;
}

.gradientBox {
    background: #fffdf8;

    margin-left: 60px;
    width: 80%;
    font-size: 45px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    color: #222222;
}

.ingText {
    color: #4da0d6;
}

.fruitsbox {
    width: 90%;

    margin-top: 20px;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* padding: 20px; */
    gap: 10px;
    margin: auto;
    background: #fffdf8;
    margin-bottom: 120px;
}

.iconbox {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fruitsimage {
    width: 200px;
    height: 200px;
    /* border: 1px solid  green; */
    background-position: 100% 100%;
    border-radius: 50%;

    /* box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.10); */
    background: #fffdf8;

    transition:
        transform 0.3s,
        opacity 0.3s,
        box-shadow 0.3s;
    /* Add smooth transitions */
    transform-origin: center center;
    cursor: pointer;
}

.fruitsimage:hover {
    transform: scale(1.05);

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.iconbox > p {
    font-size: 18px;
    font-family: 'NewHero', sans-serif;
    font-family: 500;
    color: #222222;
    margin-top: 10px;
}

.Tnavbar2 {
    display: none;
}

@media (max-width: 767px) {
    /* #container {
   
    width: 100%;
    height: auto;
   
   display: flex;
   flex-direction: column;
   background-color: #FFFDF8;
   
  } */
    .Tnavbar2 {
        display: initial;
        width: 100%;
        height: 60px;

        display: flex;
        background: #fffdf8;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .vectorN2 {
        width: 40px;
        height: 30px;
        /* margin-left: 4%; */
        /* margin-right: 5px; */
        /* justify-content: space-evenly; */
        /* margin: auto; */
    }

    .pBox1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        /* margin: auto; */
    }

    .hub02 {
        font-size: 10px;
        font-weight: 600;
        color: black;
        font-family: 'Inter', sans-serif;
    }

    .hub03 {
        font-size: 13px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    #navbar1 {
        width: 100%;
        height: 70px;
        background-color: black;
        color: white;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* position: fixed; */
        margin-bottom: initial;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .vector {
        display: none;
    }

    .navTextbox {
        padding-left: 20px;
    }

    .textHello1 {
        font-size: 15px;
        font-weight: 500;
        color: #fffdf8;
        font-family: 'NewHero', sans-serif;
    }

    .navtext1 {
        font-size: 8px;
        font-weight: 400;
        font-family: 'NewHero', sans-serif;
    }

    .topBox1x {
        /* Adjust styles for .topBox1 at this screen size */

        display: flex;
        flex-direction: column;
        /* padding-right: 20px; */
        padding-left: 20px;

        margin-bottom: initial;
        margin-top: 15px;
    }

    .para1x {
        /* Adjust styles for .para1 at this screen size */

        font-size: 25px;
        width: 100%;
        font-weight: 550;
        font-family: 'NewHero', sans-serif;
    }

    .nutTxt1x {
        font-size: 25px;
        font-weight: 600;
        font-family: 'NewHero', sans-serif;
        color: #a083bc;
    }

    .boxcalender1x {
        /* Adjust styles for .boxcalender1 at this screen size */
        /* border: 1px solid teal; */
        width: 100%;
    }

    .pribox11x {
        width: 100%;
        /* border: 2px solid red; */
        height: 70px;
        /* Adjust styles for .pribox11 at this screen size */
    }

    .pribox21x {
        /* width: 26%; */
        height: 40px;

        margin-bottom: 10px;
        /* display: flex; */
    }

    #pributtonBox10x {
        display: none;
        margin-top: 0px;

        /* Adjust styles for #pributtonBox10 at this screen size */
    }

    .card2 {
        width: 90%;
        /* Full-width on small screens */

        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .card-container2 {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 20px;

        /* padding-left: 60px; */
        justify-content: initial;
        gap: 30px;
    }

    .gradientBox {
        background: #fffdf8;

        margin-left: 20px;
        width: 80%;
        font-size: 25px;
        font-weight: 500;
        font-family: 'NewHero', sans-serif;
        color: #222222;
    }

    .ingText {
        color: #4da0d6;
    }

    .fruitsbox {
        width: 90%;

        margin-top: 20px;
        height: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        /* padding: 20px; */
        /* gap: 5px; */
        margin: auto;
        background: #fffdf8;
        margin-bottom: 120px;
    }

    .iconbox {
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .fruitsimage {
        width: 70px;
        height: 70px;
        /* border: 1px solid  green; */
        background-position: 100% 100%;
        border-radius: 50%;

        /* box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.10); */
        background: #fffdf8;

        transition:
            transform 0.3s,
            opacity 0.3s,
            box-shadow 0.3s;
        /* Add smooth transitions */
        transform-origin: center center;
        cursor: pointer;
    }

    .fruitsimage:hover {
        transform: scale(1.05);

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    .iconbox > p {
        font-size: 8px;
        font-family: 'NewHero', sans-serif;
        font-family: 500;
        color: #222222;
        margin-top: 10px;
    }
}
