/* html {
  scroll-behavior: smooth;
} */
* {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

.business-containerxyx {
    display: none;
}

#homeContainer {
    width: 100%;
    height: auto;
    position: relative;
    overflow-x: hidden;
    background: #ffffff;
}

.mainc {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 40px;
}

.bgBox {
    /* border-radius: 11px; */
    width: 100%;
    height: 90vh;
    background-color: #000000;
    margin: auto;

    overflow: hidden;
    position: relative;
    /* margin-top: 23px; */
    /* padding-left: 82px; */
    /* background: #26394a; */
    display: flex;
    flex-direction: column;
}

.bgBox > img {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    /* border-radius: 11px; */
    /* position: absolute; */
}

/* .bgVideo1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

 
} */

.Top-text-box {
    display: none;
}

.Top-text-boxm {
    width: 100%;

    display: flex;
    height: auto;
}

.chtextm {
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 55px;
    line-height: 77px;
    font-weight: 500;
    position: relative;

    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: auto;
    width: 58%;
    margin: auto;
    margin-top: 82px;
    margin-bottom: 116px;
    color: #1d3446;
}

.texcm {
    animation: blinker 4s linear infinite;
}

@keyframes blinker {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}

.horizontal-text-box {
    display: none;
}

.horizontal-text-boxm {
    display: flex;
    justify-content: space-between;
}

.h-tboxm {
    width: auto;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
}

.hbp1m {
    font-size: 45px;
    font-weight: 500;
    font-family: 'Newheromedium', sans-serif;
    color: #1d3446;
    word-wrap: break-word;
    line-height: 76px;

    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.hbp1m::after {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;
    width: 20%;
    height: 7px;
    /* Height of the underline */
    background-color: #007ad9;
    /* Change the color as needed */
    transition: width 2s ease;
    /* Transition effect duration */
    margin-top: 40px;
}

.hbp1m:hover::after {
    background-color: #007ad9;
    /* Change the color of the underline on hover */
    width: 100%;
    /* Slide in from left to right */
}

.hbp2m {
    display: flex;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 400;
    font-family: 'NewheroRegular', sans-serif;
    color: #727272;
    margin-top: 10px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    height: 16px;
    /* line-height: 76px; */
}

/* .darkbox {
  width: 100%;
  height: 460px;
  
  
 
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
}
.darkbox > img {
  position: absolute;
}
.ball1 {
 
  width: 25px;
  height: 25px;
  left: 55%;
  top: 12%;
 
}

.ball3 {
  
  width: 30px;
  height: 30px;
  left: 92%;
  top: 3%;
 
}

.ball4 {
  
  width: 52px;
  height: 52px;
  left: 70%;
  top: 18%;
 
}

.ball5 {
  
  width: 32px;
  height: 32px;
  left: 85%;
  top: 43%;
  
}
.ball6 {

  width: 45px;
  height: 45px;
  left: 35%;
  top: 52%;
 
}
.ball7 {
 
  width: 28px;
  height: 28px;
  left: 55%;
  top: 80%;
 
  
}
.ball2 {
 
  width: 30px;
  height: 30px;
  left: 20%;
  top: 92%;
 
}

.anika {
  font-size: 1rem;
  font-weight: 300;
 
  color: black;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "HelveticaRegular", sans-serif;
 
  margin-left: 10%;
  margin-top: 59px;
  margin-bottom: 26px;
 
}
.unline1 {
  width: 80%;
  
  margin-left: 10%;
  margin-bottom: 40px;
  border: 1px solid black;
}
.unline2 {
  width: 80%;
 
  margin-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid black;
}
.unline3 {
  width: 80%;
 
  margin-left: 10%;
  margin-top: 40px;
  border: 1px solid black;
}
.textw {
  
  display: flex;
  justify-content: flex-start;
  background: transparent;
  overflow: hidden;
  height: 70px;
  align-items: center;
  
}
.textw > h1 {
  font-weight: 300;
  font-size: 4.36364rem;

  color: black;
 
  white-space: nowrap;
  padding: 0 1rem;
  letter-spacing: 1px;
  animation: move-rtl 100000ms linear infinite;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "HelveticaRegular", sans-serif;
  
}
@keyframes move-rtl {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.textwl {
 
  display: flex;
  justify-content: flex-start;
  background: transparent;
  overflow: hidden;
  height: 70px;
  align-items: center;
 
}

.textwl > h1 {
  font-size: 4.36364rem;
  font-weight: 300;
  
  color: black;
  white-space: nowrap;
  padding: 0 1rem;
  letter-spacing: 1px;
  animation: move-ltr 100000ms linear infinite;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "HelveticaRegular", sans-serif;
}

@keyframes move-ltr {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */
/* .explre1 {
  width: auto;
 
 
  word-wrap: break-word;
  font-size: 24px;
  font-weight: 400;
  font-family: "HelveticaRegular", sans-serif;
  color: #000000;
  
  letter-spacing: normal;
  margin-left: 90px;
  
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 15px;
} */
.business-containerx-about {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 100px;
}

.nutri-sol-card-about {
    width: 77%;
    /* height: 300px; */

    /* display: flex;
    justify-content: space-between;
    margin: auto; */

    margin-bottom: 42px;
}

.video-div-about {
    width: 47.5%;
    height: 100%;

    /* background: gray; */
    border-radius: 11px;
    display: flex;
    overflow: hidden;
    position: relative;
}

.bgVideo1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-div-nutri-about {
    width: 50%;
    height: auto;

    padding-top: 0px;
    /* padding-bottom: 53px; */
}

.bus-div-text-about {
    color: #1d3446;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* margin-top: 53px; */
    margin-bottom: 13px;
}

.nutri-solutions-text-about {
    color: #007ad9;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 40px;
    font-weight: 500;
    position: relative;
    /* margin-bottom: 14px; */
}

.Developing-and-reinvention-about {
    color: #50504e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 100%;

    /* height: 201px; */
    line-height: 21px;
    margin-bottom: 14px;
}

.exp-more1 {
    display: none;
}

.exp-more {
    display: flex;
    align-items: center;
    color: #007ad9;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 16px;
    /* line-height: 47px; */
    font-weight: 400;
    text-decoration: underline;

    /* margin-top: 14px; */
}

.exp-more > button {
    border: none;
    background: none;
    color: #007ad9;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 16px;
    /* line-height: 47px; */
    font-weight: 400;
    text-decoration: underline;
}

.business-menu-about {
    /* max-width: 1280px;
    padding: 0 20px; */
    height: 64px;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.business-menu-about > p {
    color: #1d3446;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 22px;
    font-weight: 500;
    position: relative;

    width: 18%;
}

.business-menu-about p::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #727272;
    bottom: 0;
    left: 0;
}

/* .business-menu-about p.selected::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #727272;
  bottom: 0;
  left: 0;
  z-index: 1;
  animation: slideOut 5s ease-in-out backwards;
} */

.business-menu-about p.selected::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    background-color: #007ad9;
    bottom: -1px;
    left: 0;
    z-index: 1;
    animation: slide 5s ease-in-out forwards;
}

.business-menu-about p.selected {
    color: #007ad9;
}

@keyframes slideOut {
    0% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

@keyframes slide {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.card-container-B {
    display: flex;
    width: 100%;
    /* margin: 0 auto; */
    /* padding: 30px 29px; */
    flex-direction: column;
    background: rgba(151, 151, 151, 0.05);
}

.business-segment-logo-div {
    width: auto;
    height: 35px;

    margin: auto;
    margin-top: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.image-logo {
    width: 58px;
    height: 100%;
    object-fit: cover;

    background: url(../Assests/ldg1.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.horizon-line {
    height: 22px;
    border: 1px solid #1d3446;
    margin-left: 8px;
    margin-right: 8px;
}

.business-seg-text {
    color: #1d3446;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;

    font-weight: 500;
    position: relative;

    /* width: 90%; */
    height: 100%;
    display: flex;
    align-items: center;

    padding: none;
}

.hc-text {
    color: #007ad9;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    margin: auto;
}

.card-container-c {
    background: #ffffff;
    border-radius: 11px;
    width: 100%;
    height: auto;
    position: relative;
    /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px; */

    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 116px;
}

.card-container-c > .extrp2 {
    color: #007ad9;
    text-align: center;
    font-family: 'Newherobold', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    text-transform: uppercase;

    letter-spacing: 2px;
    margin-bottom: 15px;
}

.card-container-c > .extrp1 {
    color: #1d3446;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 35px;
    line-height: 52px;
    font-weight: 500;
    position: relative;
    width: 65%;
    margin: auto;
    /* margin-top: 44px; */

    /* margin-bottom: 31px; */
}

.horizontal-container-1 {
    width: 70.2%;
    height: 60px;
    margin: auto;
    /* margin-bottom: 51px; */

    position: relative;
    display: flex;
    align-items: center;

    justify-content: space-between;

    margin-top: 48px;
}

.slider-box-div {
    height: 60px;
    width: 194px;
    display: flex;
    background: gray;
    /* position: relative; */
    /* z-index: 1; */
}

.slider-box-div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-container-d {
    width: 100%;

    height: auto;

    margin-top: 110px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.exp-via-hubs {
    color: #26394a;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 35px;
    line-height: 52px;
    font-weight: 500;
    position: relative;
}

.we-have-created {
    color: #50504e;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    position: relative;
    width: 54%;
    margin: auto;
    margin-top: 13px;
}

.mob-box {
    width: 47%;

    margin: auto;
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
}

.box-left {
    width: 40.1%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.box-left > .bl-div-1 {
    width: 100%;
    height: 132px;

    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.product-hub-logo-div {
    height: 22px;
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.log-1 {
    width: 50px;
    height: 100%;
    display: flex;
}

.log-1 > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.line-x {
    border-style: solid;
    border-color: #26394a;
    border-width: 1px;
    margin-left: 4px;
    margin-right: 6px;

    height: 19px;
    position: relative;
}

.product-hub-logo-div > p {
    color: #26394a;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    position: relative;
    width: 155px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.startup-p {
    color: #50504e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    position: relative;
    margin-top: 15px;
}

.explr-1 {
    display: flex;
    align-items: center;
    color: #007ad9;
    margin-top: 7px;
}

.explr-1 > p {
    color: #007ad9;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    position: relative;
    margin-right: 5px;
}

.box-left > .bl-div-2 {
    width: 100%;
    height: auto;

    margin-top: 53px;
    display: flex;
    flex-direction: column;
}

.box-left > .bl-div-3 {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    margin-top: 56px;
}

.box-right {
    border-radius: 50px;
}

.card-container-s {
    background: rgba(151, 151, 151, 0.05);
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.crd-s-div {
    width: 80%;
    margin: 60px auto;
    display: flex;
    flex-direction: row;

    gap: 25px;
    justify-content: space-between;
}

.crd-s-left {
    width: 54.3%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.want-to-develop {
    color: #007ad9;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}

.we-are-just {
    color: #26394a;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 35px;
    font-weight: 500;
    position: relative;
    margin-top: 6px;
}

.we-do-things {
    color: #50504e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    position: relative;
    margin-top: 16px;
}

.elipse-div {
    /* align-items: center; */
    margin-top: 16px;
}

.elipse-div > div {
    border: 4px solid #007ad9;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background: #ececec;
    margin-right: 7px;
}

.elipse-div > p {
    color: #26394a;
    font-family: 'Newheromedium', sans-serif;
    font-weight: 500;
    position: relative;
}

.target-consumer {
    color: #525c60;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 165.4%;
    font-weight: 400;
    position: relative;
    width: 85%;
    margin-top: 16px;
}

.send-dev {
    color: #007ad9;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    position: relative;
}

.elipse-div-2 {
    /* align-items: center; */
    margin-top: 19px;
}

.elipse-div-2 > div {
    border: 4px solid #007ad9;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background: #ececec;
    margin-right: 7px;
}

.elipse-div-2 > p {
    color: #26394a;
    font-family: 'Newheromedium', sans-serif;
    font-weight: 500;
    position: relative;
}

.weoffer-moat {
    color: #525c60;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 165.4%;
    font-weight: 400;
    position: relative;
    width: 90%;
    margin-top: 19px;
}

.crd-s-right {
    height: 100%;
    width: 50%;
}

.card-container-e {
    width: 89%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 61px;
    background: rgba(176, 181, 190, 0.05);
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.horiline {
    width: 96%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 29px;
    /* margin-bottom: 15px; */
    border-style: solid;
    border-color: #007ad9;
    border-width: 1px 0 0 0;
    height: 0px;
}

.mntrends {
    width: 100%;
    height: 22px;

    /* margin-top: 24px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.kinlots {
    display: flex;
    align-items: center;
    width: 30%;
}

.logo1s {
    width: 46px;
    height: 22px;
    background: url(../Assests/ldg1.svg);
    background-size: 100% 100%;
    background-position: 100%;
    background-repeat: no-repeat;
}

.vlines {
    border: 1px solid black;
    height: 22px;
    margin-right: 10px;
}

.hubps {
    color: #1e1e1e;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.ptrnds {
    color: #007ad9;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.imgtboxt1 {
    width: 100%;
    height: auto;
    margin-top: 37px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

.imgtboxt2 {
    width: 100%;
    height: auto;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

/* .rbt1 {
  font-size: 15px;
  font-family: "Newheromedium", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  color: #df0000;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
} */
.rbt2 {
    color: #1e1e1e;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    line-height: 29px;
    /* line-height: 32px; */
    width: 761px;
    margin-top: 0px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.ravit {
    display: flex;
    width: auto;
    align-items: center;
}

.ravit > p {
    color: #727272;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-right: 20px;
}

.piyug1 {
    width: auto;
    height: 21px;
    background: #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    padding: 10px;
    margin-right: 20px;
}

.piyug1 > p {
    color: #727272;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 12px;
    font-weight: 500;
    width: auto;
}

.rbt3 {
    display: flex;
    gap: 5px;
}

/* .rbt3 {
  font-size: 12px;
  font-family: "HelveticaRegular", sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  color: #727272;
 
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  
} */
.rtimgbox {
    width: 232px;
    height: 179px;
    border-radius: 10px;
}

.story {
    color: #1e1e1e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    position: relative;
    width: 828px;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.card-container-x {
    width: 89%;
    height: 306px;

    margin: auto;
    margin-bottom: 60px;
    margin-top: 60px;
    border-radius: 20px;
    background: #fffffe;
    display: flex;
    /* flex-direction: column; */
}

.mo1-x {
    width: 24.53125%;
    height: 100%;
}

.mop1-x {
    width: 251px;

    color: #1e1e1e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 40px;
    font-weight: 400;
    /* border: 1px solid green; */
    margin-top: 85px;

    margin-left: 24px;
}

.tub1-x {
    display: flex;
    background: #ff5914;
    border-radius: 19px;
    width: 272px;
    height: 38px;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
}

.mop2-x {
    color: #fffdf5;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    /* text-decoration: underline; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    text-decoration: none;
    position: relative;
    display: inline-block;
}

.mop2-x::after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 0%;
    height: 1px;
    /* Initial thickness of underline */
    background-color: #ff5914;
    /* Initial underline color */
    transition: height 0.3s ease;
    /* Transition for thickness change */
}

.mop2-x:hover::after {
    height: 2px;
    /* Increase thickness on hover */
    background-color: #ff5914;
    /* Color of the thicker underline */
    bottom: -1px;
    width: 100%;
}

.mo2-x {
    width: 75.46875%;
    height: 100%;

    display: flex;
    padding-top: 23px;
    padding-bottom: 23px;
    justify-content: space-evenly;
}

.mo2-x > div {
    width: 29.6066252588%;
    height: 259px;
    background: #fffffe;
    border-color: #c6c5c5;
    border-radius: 21px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.mo2p1-x {
    color: #1e1e1e;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 160px;
    margin-left: 21px;
}

.mo2p2-x {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    font-weight: 500;
    /* text-decoration: underline; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 21px;
    margin-top: 17px;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.mo2p2-x::after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;

    height: 1px;
    /* Initial thickness of underline */
    background-color: #ff5914;
    /* Initial underline color */
    transition: height 0.3s ease;
    /* Transition for thickness change */
}

.mo2p2-x:hover::after {
    height: 2px;
    /* Increase thickness on hover */
    background-color: #ff5914;
    /* Color of the thicker underline */
    bottom: -1px;
    width: 100%;
}

@media (max-width: 767px) {
    .crd-s-div {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        background: #fff;
        padding-bottom: 60px;
    }

    .crd-s-left {
        width: 80%;
        margin: auto;
        height: 100%;
    }

    .crd-s-right {
        width: 100%;
        height: 70vh;
    }

    .card-container-x {
        display: none;
    }

    .card-container-f-mobile {
        display: block;
    }

    #homeContainer {
        width: 100%;
        height: auto;

        overflow-x: hidden;
        background: #f6f4f2;
    }

    .animated-cursor {
        display: none;
    }

    .mainc {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */
        /* border: 5px solid red; */
        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 0px;
    }

    /* .longTextm {
    padding-left: initial;
    width: auto;
    margin-top: auto;

    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    position: relative;
    z-index: 1;
    height: auto;

    margin-left: 6.1%;
  } */

    /* .gayatext {
    color: #fffefb;
    font-family: "Newheromedium", sans-serif;
    font-size: 25px;
    line-height: initial;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;
  } */

    /* .gayatext1 {
    color: #fffefb;
    font-family: "Newherosemibold", sans-serif;
    font-size: 30px;
    line-height: initial;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    position: relative;

  } */

    .Top-text-boxm {
        width: 100%;

        display: flex;
        height: auto;
    }

    .chtextm {
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 39px;
        line-height: 50px;
        font-weight: 500;
        position: relative;

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        height: 200px;
        width: 100%;
        margin: auto;
        margin-top: 63px;
        margin-bottom: 66px;
        color: 'black';
    }

    .business-seg-text {
        font-size: 18px;
    }

    .hc-text {
        text-align: center;
        width: 70%;
        margin: auto;
    }

    .horizontal-text-box {
        display: none;
    }

    .horizontal-text-boxm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        /* padding-left: 15%; */
        gap: 20px;

        justify-content: center;
    }

    .h-tboxm {
        width: auto;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .mob-box {
        width: 100%;
        margin: auto;
        margin-top: 27px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .hbp1m {
        font-size: 38px;
        font-weight: 600;
        font-family: 'Newheromedium', sans-serif;
        color: #000000;
        word-wrap: break-word;
        line-height: 76px;

        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .hbp1m::after {
        content: '';
        position: absolute;

        bottom: 0;
        left: 0;
        width: 80%;
        height: 7px;
        /* Height of the underline */
        background-color: #007ad9;
        /* Change the color as needed */
        transition: width 0.5s ease;
        /* Transition effect duration */
        margin-top: 40px;
    }

    .hbp2m {
        display: flex;
        word-wrap: break-word;
        font-size: 10px;
        font-weight: 400;
        font-family: 'NewheroRegular', sans-serif;
        color: #000000;
        margin-top: 20px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        height: 16px;
        /* line-height: 76px; */
    }

    .great-word {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 50px;
        font-weight: 400;
        position: relative;
        width: auto;

        /* word-wrap: break-word; */
        margin: auto;
        margin-bottom: 36px;
    }

    .our-website {
        color: #ff5914;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin: auto;
        margin-bottom: 50px;

        width: 95%;
    }

    .rbt2 {
        font-size: 15px;
        width: 90%;
        line-height: 20px;
        margin: auto;
    }

    .email-subscribe-button {
        height: auto;
        width: 100%;

        display: flex;
        flex-direction: column;
        /* padding-left: 10.2%;
    padding-right: 10.2%; */
        justify-content: initial;
        margin: auto;
        margin-bottom: 42px;
        padding-left: 0px;
        padding-right: 0px;
    }

    /* .email-subscribe-button>button{
    height: 100%;
    width: 13.32%;
    border-radius: 28px;
  } */
    .email-subscribe-input {
        width: 60.3%;
        height: 21px;
        border: 1px solid black;
        border-style: solid;
        border-color: #ff5914;
        border-top: none;
        border-left: none;
        border-right: none;
        padding-left: 30px;
        color: #979797;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        margin-bottom: 15px;
    }

    .subscribe-button {
        height: 21px;
        width: auto;
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 16px;
        font-weight: 400;
        position: relative;
        border: initial;
        background: initial;
        background: rgba(217, 217, 217, 0);

        margin-bottom: 26px;
    }

    .signup-button {
        height: 30px;
        width: 93px;
        border-radius: 14px;
        color: #000000;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 10px;
        font-weight: 400;
        position: relative;
        border: initial;
        background: initial;
        background: rgba(217, 217, 217, 0);
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px;
    }

    .touch-with {
        color: #000000;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 8px;
        font-weight: 400;
        margin: auto;
        position: relative;
        margin-bottom: 103px;

        width: 95%;
    }

    .footer-for-home {
        width: 100%;
        background: #26394a;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 142px;
    }

    .footer-for-home > p {
        color: #ffffff;

        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }

    .card-contener-A {
        max-width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: initial;
        column-gap: 0px;
        gap: 40px;
        padding: 0px 0px 0px 0px;
        margin-bottom: 54px;
    }

    .card-1x {
        background-image: url(../Assests/cute.png);
        background-position: cover;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        width: 89%;
        height: 332px;
        border-radius: 14px;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    }

    .blur-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        padding-left: 20px;

        height: 160px;
        width: 100%;
        margin-top: 172px;
        background: rgba(255, 255, 255, 0.58);
        border-radius: 0px 0px 14px 14px;
        backdrop-filter: blur(8px);
    }

    .we-have-created {
        width: 90%;
    }

    .box-left {
        width: 70%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .text-1 {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular';
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        width: 98%;

        margin-top: 12px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .gets-div {
        display: flex;
        align-items: center;
        margin-top: auto;
    }

    .getnows {
        color: #ff5914;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14.5px;
        font-weight: 500;
        text-decoration: underline;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .aero1 {
        color: #df0000;
        margin-left: 10px;
    }

    .card-container-B {
        padding: 30px;
    }

    .kinlogodiv {
        width: 89%;
        margin: auto;
        height: auto;

        /* margin-top: 50px; */
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        background: rgba(242, 242, 242, 0.5);
        border-radius: 20px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .leftlogodiv {
        height: 213px;
        width: 100%;
        margin-top: 18px;
        margin-right: 0px;
        margin-bottom: 23px;
    }

    .kinlot {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lagaudiv {
        width: 98px;
        height: 44px;

        background-image: url(../Assests/lo2.jpg);
        background-size: cover;
        background-position: center;
    }

    .vline {
        border: 0.5px solid black;
        height: 44px;
        margin-right: 14px;
        margin-left: 8px;
    }

    .hubp {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        /* width: 164px;
    height: 23px; */
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .pct {
        color: #000000;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 31px;
        line-height: 35px;
        font-weight: 400;
        margin-top: 28px;

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        width: 85%;
        margin-left: 19px;
    }

    .expdm {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-left: 19px;
    }

    .explrm {
        color: #ff5914;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 16px;
        line-height: 47px;
        font-weight: 400;

        letter-spacing: normal;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        /* display: flex;
    align-items: center; */
        text-decoration: none;
        position: relative;
        /* display: inline-block; */
    }

    .explrm::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 0%;
        height: 1px;
        /* Initial thickness of underline */
        background-color: #ff5914;
        /* Initial underline color */
        transition: height 0.3s ease;
        /* Transition for thickness change */
    }

    .explrm:hover::after {
        height: 2px;
        /* Increase thickness on hover */
        background-color: #ff5914;
        /* Color of the thicker underline */
        bottom: -1px;
        width: 100%;
    }

    #topu {
        display: block;
    }

    .tipu {
        width: 100%;
        height: 350px;

        display: flex;
        /* padding-top: 10px;
    padding-bottom: 10px; */
    }

    .babup {
        width: 88%;
        height: 260px;

        margin: auto;
        position: relative;

        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* backdrop-filter: blur(2px); */
        margin-top: 0px;
        margin-bottom: 50px;
    }

    .babdiv1 {
        width: 100%;
        height: 55%;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: grey;
    }

    .babdiv2 {
        width: 100%;
        height: 45%;

        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .babp1 {
        color: #ff5914;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        /* line-height: 47px; */
        font-weight: 400;
        position: relative;
        width: 280px;
        /* height: 20px; */

        /* margin: auto; */
        margin-top: 0px;
    }

    .babp2 {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        width: 260px;
        margin-top: 0px;

        /* margin: auto; */
    }

    .want-to-develop {
        text-align: center;
    }

    .we-are-just {
        text-align: center;
        font-size: 20px;
        margin-top: 6px;
    }

    .we-do-things {
        text-align: center;
    }

    .kincardbox {
        display: none;
    }

    .card-container-c-for-mobile {
        display: block;
    }

    .card-container-c-for-mobile {
        background: #ffffff;
        border-radius: 11px;
        width: 89%;
        height: 230px;
        position: relative;
        /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px; */

        margin: auto;
        display: flex;
        flex-direction: column;
        margin-top: 46px;
        padding-bottom: 20px;
    }

    .card-container-c > .extrp1 {
        position: relative;
        color: #000000;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 25px;
        line-height: 40px;
        font-weight: 500;
        width: 350px;
        margin: auto;
        margin-top: 33px;

        /* margin-bottom: 31px; */
    }

    .card-container-c > .extrp2 {
        color: #007ad9;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin: auto;
        width: 70%;

        margin-top: 7px;
    }

    .horizontal-container-1-mob {
        width: 100%;
        height: 78px;
        margin: auto;
        margin-bottom: 0px;

        position: relative;
        display: flex;
        align-items: initial;

        justify-content: initial;

        margin-top: 8px;
    }

    .slider-box-div-mob {
        height: 78px;
        width: 152px;
        display: flex;
        background: grey;
        /* position: relative; */
        /* z-index: 1; */
    }

    .slider-box-div-mob > img {
        display: none;
        /* width: 100%;
    height: 100%;
    object-fit: cover; */
    }

    .crdunder {
        width: 100%;
        display: flex;
        align-items: center;

        padding-left: 0px;
        margin-top: 0px;
        color: #ff5914;
    }

    .cdrt1 {
        font-size: 15px;
        font-family: 'NewheroRegular', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
        color: #1e1e1e;
        line-height: initial;
        margin-left: 0px;
        margin-top: 0px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        width: 82%;
        height: auto;
    }

    .cdrt2 {
        font-size: 15px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #ff5914;
        /* line-height: 68px; */
        /* margin-left: 18px; */
        /* margin-top: px; */
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
        position: relative;
        display: inline-block;
    }

    .elipse-div > div {
        display: none;
    }

    .elipse-div-2 > div {
        display: none;
    }

    .target-consumer {
        width: 75%;
    }

    .weoffer-moat {
        width: 80%;
    }

    .kinlotfx {
        display: flex;
        align-items: center;
    }

    .logo1fx {
        width: 63px;
        height: 31px;
        margin-left: 0px;
        object-fit: cover;
    }

    .vlinefx {
        border: 1px solid black;
        height: 25px;
        margin-right: 10px;
    }

    .hubpfx {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
    }

    .acprdt {
        font-size: 15px;
        font-family: 'NewheroRegular', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
        color: #000000;
        margin-left: 0px;
        margin-top: 18px;
        margin-bottom: 5px;

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    .cdrt21 {
        font-size: 15px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #ff5914;
        /* line-height: 52px; */
        /* margin-left: 20px; */
        /* margin-top: px; */

        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
        position: relative;
        display: inline-block;
    }

    .piyug1 {
        width: auto;
        height: 11px;
        background: #ededed;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        padding: 10px;
        margin-right: 20px;
    }

    .piyug1 > p {
        color: #1058a8;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-size: 9px;
        font-weight: 500;
        width: auto;
    }

    .catlg {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        margin-left: 0px;
        margin-top: 0px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        /*   */
    }

    .bdtext {
        font-size: 15px;
        font-family: 'NewHero', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
        color: #000000;
        line-height: 20px;
        margin-left: 0px;
        margin-top: 10px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        /*   */
        width: 304px;
    }

    .see {
        font-size: 15px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #df0000;
        /* line-height: 18px; */
        /* margin-left: 20px; */
        margin-top: 0px;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        /*   */
        text-decoration: none;
        position: relative;
        display: inline-block;
    }

    .tiputr {
        display: block;
        width: 100%;
        height: auto;

        margin-top: 50px;
        display: flex;
        align-items: center;
    }

    .babuptrw {
        width: 88%;
        height: auto;

        margin: auto;
        background: rgba(176, 181, 190, 0.05);
        border-radius: 20px;
        display: flex;
        flex-direction: column;

        padding-top: 28px;
        margin-bottom: 40px;
    }

    .kinlotfxtr {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .logo1fxtr {
        width: 46px;
        height: 18px;
        margin-left: 0px;

        object-fit: cover;
    }

    .vlinefxtr {
        border: 1px solid black;
        height: 18px;
        margin-right: 10px;
    }

    .hubpfxtr {
        font-size: 24px;
        font-family: 'Newheromedium', sans-serif;
        font-weight: 500;
        word-wrap: break-word;
        color: #000000;
        height: 33px;
    }

    .Cxtrends {
        color: #000000;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        /*   */
        text-align: center;
        margin-top: 5px;
    }

    .bruline {
        border-style: solid;
        border-color: #ff5914;
        border-width: 1px 0 0 0;
        width: 95%;
        height: 0px;
        position: relative;
        /* margin: auto; */
        margin-top: 8px;
        margin-left: 10px;
    }

    .brudiv1 {
        width: 77%;

        background-color: #000000;
        height: 213px;
        margin: auto;
        margin-top: 13px;
        border-radius: 10px;
        margin-bottom: 9px;
    }

    .brudte1 {
        color: #000000;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        position: relative;
        width: 92%;
        margin-left: 0px;
        margin: auto;
    }

    .brudiv2-x-x {
        display: flex;
        width: 100%;

        padding-left: 4.5%;
        margin-top: 11px;
        color: #727272;
        gap: 10px;
        /* text-align: left; */
    }

    .brd-1-0 {
        display: flex;
        width: auto;

        /* justify-content: space-between; */
        align-items: center;
        font-family: 'InterRegular', sans-serif;
        font-size: 10px;
        /* line-height: 32px; */
        font-weight: 400;
        position: relative;
        gap: 10px;
    }

    .brudiv2-x-x > button {
        border: none;
        background: #ededed;
        border-radius: 10px;
        width: 73px;
        height: 17px;
        position: relative;
        color: #000000;

        font-family: 'InterMedium', sans-serif;
        font-size: 9px;
        font-weight: 500;
        position: relative;
    }

    .brudte3 {
        color: #000000;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        width: 90%;
        margin-left: intial;
        margin: auto;

        margin-top: 12px;
        margin-bottom: 15px;
    }

    .business-containerx-about {
        display: none;
    }

    .business-containerxyx {
        display: block;
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 48px;
    }

    .b-divsionx {
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin-left: 6.4%;
        margin-bottom: 12px;
    }

    .bsc-divx {
        width: 100%;
        background: #d9d9d9;
        display: flex;
        overflow: hidden;
        position: relative;
        height: 229px;
    }

    .bgVideo1x {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .nutri-sol-cardxyx {
        width: 87.1%;
        height: 210px;

        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .nutri-solutions-textxyx {
        color: #000000;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
    }

    .Developing-and-reinventionxyx {
        color: #1e1e1e;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;

        width: 95%;
        height: auto;
        margin-top: 25px;
    }

    .business-menuxyx {
        width: 87.1%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin: auto;
        margin-top: -30px;
        margin-bottom: 20px;
    }

    .business-menuxyx > p {
        color: transparent;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;

        width: 16.2%;
    }

    .business-menuxyx p::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #727272;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .business-menuxyx p.selected::before {
        content: '';
        position: absolute;
        width: 0;
        height: 3px;
        background-color: #007ad9;

        bottom: 0;
        left: 0;
        animation: slide 5s ease-in-out forwards;
    }

    .business-menuxyx p.selected {
        color: transparent;
    }

    @keyframes slide {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }

    .exp-more1 {
        display: block;
        display: flex;
        align-items: center;
        color: #007ad9;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 16px;
        /* line-height: 47px; */
        font-weight: 400;
        text-decoration: underline;

        margin-top: 10px;
    }

    .exp-more1 > button {
        border: none;
        background: none;
        color: #007ad9;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 16px;
        /* line-height: 47px; */
        font-weight: 400;
        text-decoration: underline;
    }

    .story {
        color: #525c60;
        text-align: left;
        font-family: 'New Hero', sans-serif;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        position: relative;
        width: 90%;
        margin: auto;
    }
}
