* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

#Private-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f6f4f2;
}

.Bmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.PRtbdiv1 {
    border-radius: 26px;
    width: 89%;
    height: 366px;
    position: relative;
    background: grey;
    margin: auto;
    margin-top: 21px;
}

/* .PBbgBox{
    border-radius: 11px;
    width: 88.9%;
    height: 625px;
    
    margin: auto;
    
    overflow: hidden;
    position: relative;
    background: #c4c4c4;
  margin-top: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
   
  } */

.hwywait1 {
    display: none;
    /* color: #151515;
    font-size: 64px;
    font-family: "HelveticaMedium" , sans-serif;
    font-weight: 500;
    word-wrap: break-word;
    margin-left: 153px; */
}

.hwywait2 {
    display: none;
    /* color: #151515;
    font-size: 64px;
    font-family: "HelveticaRegular", sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    margin-left: 153px; */
}

.PBtrnsn {
    display: none;
    /* color: #151515;
  
  font-family: "HelveticaRegular", sans-serif;
  font-size: 19px;
  font-weight: 400;
  position: relative;
  margin-top: 56px;
  margin-left: 153px;
  line-height: 23px;
  margin-bottom: 24px;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; */
}

.prvtext {
    color: #151515;
    /* text-align: left; */
    font-family: 'HelveticaRegular', sans-serif;
    font-size: 24px;
    line-height: 76px;
    font-weight: 400;
    position: relative;
    width: 545px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin-top: 83px;
    margin-left: 80px;
    margin-bottom: 13px;
}

.xycategory {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    /* line-height: 76px; */
    font-weight: 400;
    position: relative;
    margin-top: 68px;
    margin-left: 8.82%;
}

.xyline1 {
    border: 0.5px solid;
    width: 82.4%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.zcategoryBox {
    width: 85%;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    margin-bottom: 43px;
}

.zcatbox {
    /* border: 1px solid green; */
    height: 48px;
    display: flex;

    /* align-items: flex-start; */
    justify-content: space-evenly;
}

.zicondiv {
    /* border: 1px solid green; */
    width: 48px;
    height: 100%;
    display: flex;
}

.zcatLo {
    height: 100%;
    width: 100%;
}

.zpBox {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
}

.zpBox > p {
    font-size: 12px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    color: black;
    /* border: 1px solid green; */
}

/* .tpo{
  color: #151515;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin-left: 13.4%;
  margin-top: 43px;
}
.download-form-div{
 
  width: 73.4%;
  height: 269px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
.down-l{
 
  width: 48.6%;
  height: 100%;
}
.ask-your{
  color: #000000;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-top: 10px;
}
.answer{
  color: #ff5914;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-top: 8px;
}
.dont-know-div{
  background: rgba(217, 217, 217, 0.5);
  border-radius: 50px 50px 50px 0px;
  width: 100%;
  height: 76px;
  position: relative;
  margin-top: 24px;

}
.d-product-list{
  display: flex;
  width: 92%;

  height: 44px;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}
.d-product-list>button{
  background: #ff5914;
  border-radius: 22px;
  border-style: solid;
  border-color: #ff5914;
  border-width: 1px;
  width: 47.9%;
  height: 44px;
  position: relative;
  color: #ffffff;
}
.d-product-list>p{
  color: #ff5914;
  text-align: left;
  font-family: "Newherosemibold", sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
}
.down-r{
 
  width: 48.9%;
  height: 100%;
  border-radius: 50px 0px 50px 0px;
  background: #d9d9d9;
} */
.hgemplovdon1 {
    display: none;
}

.tnndiv1lovdon1 {
    display: none;
}

.product-form-l-p {
    display: none;
}

.div-x-1-for-mobile {
    display: none;
}

.dopl {
    display: none;
}

.ate {
    display: none;
}

.aywq {
    display: none;
}

.div-x-2 {
    display: none;
}

.ask-btn {
    display: none;
}

@media (max-width: 767px) {
    #Private-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f6f4f2;
    }

    .animated-cursor {
        display: none;
    }

    .Bmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .PRtbdiv1 {
        border-radius: 26px;
        width: 89%;
        height: 224px;
        position: relative;
        background: grey;
        margin: auto;
        margin-top: 21px;
    }

    .we-off {
        display: none;
    }

    .xycategory {
        font-size: 15px;
        font-weight: 500;
        font-family: 'Newheromedium', sans-serif;
        color: black;
        margin-top: 46px;
        margin-left: 25px;
    }

    .xyline1 {
        border: 0.5px solid;
        width: 90%;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .zcategoryBox {
        display: none;

        width: 90%;
        height: auto;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 36px;
    }

    .zcatbox {
        /* border: 1px solid green; */
        height: 48px;
        display: flex;

        /* align-items: flex-start; */
        justify-content: space-evenly;
    }

    .zicondiv {
        /* border: 1px solid green; */
        width: 48px;
        height: 100%;
        display: flex;
    }

    .zcatLo {
        height: 100%;
        width: 100%;
    }

    .zpBox {
        height: 100%;
        width: 60%;
        display: flex;
        align-items: center;
    }

    .zpBox > p {
        font-size: 12px;
        font-weight: 500;
        font-family: 'Newheromedium', sans-serif;
        color: black;
        /* border: 1px solid green; */
    }

    /* .div-x-1-for-mobile{
  display: block;
  background: #d9d9d9;
  border-radius: 50px 0px 50px 0px;
  width: 88%;
  height: 179px;
  position: relative;
  margin: auto;
  margin-bottom: 14px;
}
.dopl{
  display: block;
  color: #ff5914;
  text-align: left;
  font-family: "Newherosemibold", sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
  margin-bottom: 10px;
  margin-left: 6%;
}
.ate{
  display: block;
  color: #000000;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  position: relative;
 
  height: 18px;
  margin-bottom: 10px;
  margin-left: 6%;
}
.aywq{
  display: block;
  color: #ff5914;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  margin-left: 6%;
}
.div-x-2{
  display: block;
  background: rgba(217, 217, 217, 0.5);
  border-radius: 50px 50px 50px 0px;
  width: 88%;
  height: 76px;
  position: relative;
  
  margin: auto;
  margin-bottom: 20px;
}
.ask-btn{
  display: block;
  background: #ff5914;
  border-radius: 22px;
  border-style: solid;
  border-color: #ff5914;
  border-width: 1px;
  width: 57.3%;
  height: 44px;
  margin: auto;
  margin-bottom: 58px;
} */
    /* .product-form-l-p {
  display: block;
  width: 88%;
  height: 464px;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.05),
    -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
    margin: auto;
    display: flex;
    flex-direction: column;
   
    padding-bottom: 27px;
    margin-bottom: 53px;
    margin-top: 0px;
}
.form-p1-l-p{
   width: 80%;
   color: #151515;
text-align: left;
font-family: "NewheroRegular", sans-serif;
font-size: 20px;
font-weight: 400;
position: relative;
margin: auto;
margin-top: 18px;
margin-bottom: 18px;

}
.form-p2-l-p{
  color: #151515;
  text-align: left;
  font-family: "NewHero-Regular", sans-serif;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  width: 80%;
  margin: auto;
  
  margin-bottom: 15px;
}
.product-form-l-p >input{
 
  height: 30px;
  width: 80%;
  margin: auto;
  margin-bottom: 19px;
  border: none;
  border-bottom: 1px solid #83694f;
}
.product-form-l-p>textarea{
 
  width: 80%;
  height: 68px;
  margin: auto;
  color: #26394a;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #83694f;
}
.product-form-l-p>button{
  border: none;
  background: #83694f;
border-radius: 16px;
width: 83px;
height: 32px;
color: #ffffff;
text-align:center;
font-family: "Newheromedium", sans-serif;
font-size: 12px;
font-weight: 500;
margin-top: 28px;
margin-left: 10%;
} */
    .tpo {
        display: none;
    }

    .download-form-div {
        display: none;
    }
}
