*body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #fffdf8;
}

#brandContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* background-color: #FFFDF8; */
    /* background: #FFFDF8; */

    background: #f6f4f2;
}

#brandnavbar1 {
    width: 100%;
    height: 70px;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: fixed; */
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.navTextbox2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.vector2 {
    width: 32px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px;
}

.textHello2 {
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.navtext2 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
}

.topBox1b {
    display: flex;
    /* margin-left: 60px; */
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;

    /* margin-bottom: 15px; */
    /* margin-top: 20px; */
}

.para1b {
    font-size: 45px;
    font-weight: 550;
    font-family: 'NewHero', sans-serif;
}

.conText {
    font-size: 45px;
    font-weight: 550;
    color: #a083bc;
}

.prTextr {
    color: #4da0d6;
}

.cText {
    color: #4da0d6;
}

.pribox11b {
    width: 64%;
    height: 135px;
}

.pribox21b {
    /* width: 26%; */
    height: 52px;

    /* display: flex; */
}

.boxcalender1b {
    width: 26%;
    margin-top: 10px;
}

#pributtonBox10b {
    /* border: 1px solid green; */
    width: 40%;
    height: 40px;
    background-color: black;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.priiconx10b {
    width: 8%;
    height: 28%;
    /* border: 1px solid green; */
}

#pributtonBox10b > p {
    color: white;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    /* border: 1px solid green; */
    font-family: 'NewHero', sans-serif;
}

.botmText {
    /* border: 1px solid red ; */
    margin-top: 0px;
    margin-left: 60px;
    width: 80%;
    margin-bottom: 10px;
    font-size: 50px;
    font-family: 'NewHero', sans-serif;
    font-weight: 500px;
}

.brdText {
    color: #20bed7;
}

.cardbox {
    /* width: 90%; */
    /* height: 180px; */
    margin-top: 10px;
    display: flex;
    /* justify-content: space-evenly; */
    /* margin-left: 140px; */
    padding-left: 90px;
    gap: 50px;
    margin-bottom: 10px;
}

.childBox:hover {
    transform: scale(1.05);

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.linkCard {
    width: 20%;
    text-decoration: none;
}

.childBox {
    /* width: 20%; */
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: center; */
    padding: 20px;
    /* background: #FFFDF8; */
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    background: #fffdf8;
    border-radius: 32px;
    transition:
        transform 0.3s,
        opacity 0.3s,
        box-shadow 0.3s;
    /* Add smooth transitions */
    transform-origin: center center;
    cursor: pointer;
}

.imageBrandBox {
    height: 91px;
    /* width: 90%; */
    margin-bottom: 10px;
}

.CardText {
    font-size: 13px;
    color: #282828;
    font-weight: 700;
    font-family: 'NewHero', sans-serif;
    margin-bottom: 5px;
}

.CardText2 {
    font-size: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #282828;

    margin-bottom: 5px;
}

.CardText3 {
    font-size: 7px;
    font-family: 'NewHero', sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
    color: black;
}

.imagecard {
    width: 100%;
    height: 100%;
    /* background-position: 100% 100%; */
    /* background-size: cover; */
}

.card-container3 {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;

    /* padding-left: 60px; */
    justify-content: space-evenly;
    margin-bottom: 100px;
    background: #fffdf8;
    /* touch-action: pan-y;  */
    touch-action: pan-x;
    /* Enable touch scrolling */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Hide scrollbar in IE/Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.card-container3::-webkit-scrollbar {
    width: 0;
}

.card3:hover {
    transform: scale(1.05);
    /* Scale up the card on hover */

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.card3 {
    flex: 0 0 auto;
    /* margin-right: 10px; */
    width: 20%;
    /* Adjust as needed */
    /* background-color: #fff;
  border: 1px solid #ddd; */
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

    border-radius: 32px;
    padding: 20px;

    background: #fffdf8;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    transition:
        transform 0.3s,
        opacity 0.3s,
        box-shadow 0.3s;
}

.card3 img {
    width: 100%;
    max-width: 100%;
    height: 91px;

    border-radius: 20px;
}

.card-content3 {
    /* padding: 20px; */
    /* border: 1px solid green; */
    margin-top: 20px;
    /* line-height: 15px; */
}

.card3 .title3 {
    font-size: 13px;
    color: #282828;
    font-weight: 700;
    font-family: 'NewHero', sans-serif;
    margin-bottom: 5px;
}

.card3 .descrip3 {
    font-size: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #282828;
    /* word-wrap:nowrap; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}

.card3 .explr3 {
    font-size: 7px;
    font-family: 'NewHero', sans-serif;
    font-weight: 500;
    color: black;
}

.Tnavbar3 {
    display: none;
}

@media (max-width: 767px) {
    #brandContainer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        /* background-color: #FFFDF8; */
        background: #fffdf8;
    }

    .Tnavbar3 {
        display: initial;
        width: 100%;
        height: 60px;

        display: flex;
        background: #fffdf8;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .vectorN3 {
        width: 40px;
        height: 30px;
        /* margin-left: 4%; */
        margin-right: 5px;
        /* justify-content: space-evenly;
 margin: auto; */
    }

    .pBox3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 5px;
        margin: auto;
    }

    .hubx3 {
        font-size: 12px;
        font-weight: 600;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    .hubx4 {
        font-size: 15px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    #brandnavbar1 {
        width: 100%;
        height: 70px;
        background-color: black;
        color: white;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* position: fixed; */
        margin-bottom: 10px;
        position: relative;
        top: 0;
        z-index: 1;
    }

    .navTextbox2 {
        padding-left: 20px;
    }

    .vector2 {
        /* width: 25px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px; */
        display: none;
    }

    .textHello2 {
        font-size: 15px;
        font-weight: 500;
        color: #fffdf8;
        font-family: 'NewHero', sans-serif;
    }

    .navtext2 {
        font-size: 7px;
        font-weight: 400;
        font-family: 'NewHero', sans-serif;
    }

    .topBox1b {
        display: flex;
        flex-direction: column;
        /* margin-left: 60px; */
        justify-content: space-between;
        padding-right: 0px;
        padding-left: 20px;

        /* margin-bottom: 15px; */
        /* margin-top: 20px; */
    }

    .para1b {
        font-size: 25px;
        font-weight: 550;
        font-family: 'NewHero', sans-serif;
    }

    .conText {
        font-size: 25px;
        font-weight: 550;
        color: #a083bc;
    }

    .prTextr {
        color: #4da0d6;
    }

    .cText {
        color: #4da0d6;
    }

    .pribox11b {
        width: 100%;
        height: auto;
    }

    .pribox21b {
        width: 100%;
        height: 52px;

        /* display: flex; */
    }

    .boxcalender1b {
        width: 100%;
        margin-top: 10px;
    }

    .botmText {
        /* border: 1px solid red ; */
        margin-top: 20px;
        margin-left: 20px;
        width: 100%;
        margin-bottom: 0px;
        font-size: 20px;
        font-family: 'NewHero', sans-serif;
        font-weight: 500px;
    }

    .brdText {
        color: #20bed7;
    }

    .cardbox {
        /* width: 90%; */
        /* height: 180px; */
        margin-top: 10px;
        display: flex;
        /* justify-content: space-evenly; */
        /* margin-left: 140px; */
        padding-left: 20px;
        gap: 10px;
        margin-bottom: 10px;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        padding: 10px;
        justify-content: flex-start;

        /* margin-top: 20px; */
    }

    .childBox:hover {
        transform: scale(1.05);

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    /* .linkCard{
    width: 400px;
    text-decoration: none;
  } */
    .childBox {
        /* width: 20%; */
        width: 100%;
        height: 100%;
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        /* align-items: center; */
        padding: 20px;
        /* background: #FFFDF8; */
        border-radius: 18px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        /* box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.10); */
        background: #fffdf8;
        border-radius: 32px;
        transition:
            transform 0.3s,
            opacity 0.3s,
            box-shadow 0.3s;
        /* Add smooth transitions */
        transform-origin: center center;
        cursor: pointer;
    }

    .imageBrandBox {
        height: 91px;
        /* width: 90%; */
        margin-bottom: 10px;
    }

    .CardText {
        font-size: 13px;
        color: #282828;
        font-weight: 700;
        font-family: 'NewHero', sans-serif;
        margin-bottom: 5px;
    }

    .CardText2 {
        font-size: 8px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: #282828;

        margin-bottom: 5px;
    }

    .CardText3 {
        font-size: 7px;
        font-family: 'NewHero', sans-serif;
        font-weight: 500;
        margin-bottom: 5px;
        color: black;
    }

    .imagecard {
        width: 100%;
        height: 100%;
        /* background-position: 100% 100%; */
        /* background-size: cover; */
    }

    .card3 {
        width: 80%;
        /* Full-width on small screens */
    }

    .card-container3 {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 20px;

        /* padding-left: 60px; */
        justify-content: initial;
        gap: 20px;
    }

    #pributtonBox10b {
        /* border: 1px solid green; */
        display: none;
    }
}
