* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* font-size: 16px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scrollbar-width: 0px;
}

#Product-container {
    width: 100%;
    height: auto;
    /* display: none; */

    /* overflow-y: auto; */
    /* background: #C4C4C4; */
    overflow-x: hidden;
    background: #f4f4f4;
}

.Pmain {
    margin: auto;
    width: 100%;
    height: auto;
    /* margin-top: 90px; */

    position: relative;
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    margin-top: 90px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.PbgBox {
    border-radius: 11px;
    width: 88.9%;
    height: 625px;

    margin: auto;

    overflow: hidden;
    position: relative;
    background: #c4c4c4;
    margin-bottom: 88px;
}

.pcdiv {
    width: auto;
    height: auto;
    display: flex;
    margin: auto;
    /* justify-content: space-between; */

    margin-bottom: 82px;
    margin-top: 82px;
    gap: 184px;
}

.pcCat {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    /* height: 44px; */
}

.pclngText {
    display: flex;
    flex-direction: column;
    width: 641px;
}

.pc1t {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* width: 641px; */
    /* height: 126px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    line-height: 21px;
}

.cardbrc {
    width: 100%;
    height: auto;

    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* gap: 15px; */
    /* padding-left: 6%;
  padding-right: 6%; */
}

.product-Hub-logo-div {
    height: 58.2px;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 5.5%;
}

.product-logo-div {
    width: 98px;
    height: 100%;
    background-image: url(../Assests/lo2.jpg);
    background-position: cover;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.product-vertical-line {
    border-style: solid;
    border-color: #000000;
    border-width: 0.5px;

    height: 35px;
    position: relative;
    margin-right: 14px;
}

.product-hub-text {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 30px;
    font-weight: 500;
    position: relative;
}

.product-exp-offering {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
    position: relative;
    padding-left: 5.5%;
    margin-top: 13px;
    margin-bottom: 32px;
}

.product-section-div {
    display: flex;
    justify-content: space-between;
    /* padding-left:auto;
  padding-right:auto; */

    width: 89%;
    margin: auto;
}

.brnd-link {
    width: 23.6%;
    height: 260px;
    text-decoration: none;
}

.cardbr1 {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #fffffe;
    /* color: #000000; */
    /* padding-top: 17px;
    padding-bottom: 17px; */
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.cardbr1 > div {
    height: 148px;
    width: 100%;
    background: grey;
    border-radius: 20px 20px 0px 0px;
}

.br1 {
    color: #151515;
    /* text-align: left; */
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    margin-top: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br2 {
    color: #5f5f5f;
    /* text-align: center; */
    font-family: 'NewheroRegular', sans-serif;
    margin: auto;
    font-size: 13.5px;
    font-weight: 400;
    position: relative;
    width: 265px;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br3a {
    color: #ff5914;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* margin-top: 3px; */
    margin-bottom: 11px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br3a::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #ff5914;
    transition: height 0.3s ease;
    margin-left: 25%;
}

.br3a:hover::after {
    height: 2px;
    background-color: #ff5914;
    bottom: -1px;
    width: 51%;
}

.br3b {
    color: #ff5914;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* margin-top: 3px; */
    margin-bottom: 11px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br3b::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #ff5914;
    transition: height 0.3s ease;
    margin-left: 30%;
}

.br3b:hover::after {
    height: 2px;
    background-color: #ff5914;
    bottom: -1px;
    width: 40%;
}

.br3c {
    color: #ff5914;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* margin-top: 3px; */
    margin-bottom: 11px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br3c::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #ff5914;
    transition: height 0.3s ease;
    margin-left: 25%;
}

.br3c:hover::after {
    height: 2px;
    background-color: #ff5914;
    bottom: -1px;
    width: 50%;
}

.br3d {
    color: #ff5914;
    /* text-align: left; */
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    /* margin-top: 3px; */
    margin-bottom: 11px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.br3d::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #ff5914;
    transition: height 0.3s ease;
    margin-left: 25%;
}

.br3d:hover::after {
    height: 2px;
    background-color: #ff5914;
    bottom: -1px;
    width: 50%;
}

.product-container-f {
    display: flex;

    height: 541px;
    width: 88%;
    margin: auto;
    margin-bottom: 80px;
    /* justify-content: space-between; */
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.p-c-f-divL {
    width: 56.4%;
    height: 100%;
    border-radius: 22px 0px 0px 22px;
    background: grey;
}

.p-c-f-divR {
    width: 43.6%;
    height: 100%;
    border-radius: 0px 22px 22px 0px;
    background: rgba(255, 255, 255, 0.95);
}

.p-c-f-divR > button {
    border: none;
    background: #ff5914;
    border-radius: 19px;
    width: 216px;
    height: 38px;
    position: relative;
    color: #fffefb;
    text-align: center;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 5%;
    margin-top: 60px;
}

.partner-p {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    padding-left: 5%;
    margin-top: 53px;
    word-wrap: break-word;
}

.our-p-approach {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    width: 88%;
    margin: auto;
    margin-top: 24px;
}

.p-c-div-pr {
    width: 90%;
    height: auto;
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    margin-top: 24px;
    column-gap: 35px;
    row-gap: 35px;
    word-wrap: break-word;
}

.p-c-div-pr > div {
    display: flex;
    flex-direction: column;
    height: auto;
}

.p-c-p1 {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    margin-bottom: 5px;
    word-wrap: break-word;
}

.p-c-p2 {
    color: #535353;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    position: relative;
    margin-bottom: 0px;
    word-wrap: break-word;
}

/* .p-c-p3 {
  color: #83694f;
  text-align: left;
  font-family: "Newheromedium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  word-wrap: break-word;
} */
.hrxdiv {
    background: rgba(242, 242, 242, 0.5);
    border-radius: 20px;
    width: 89.1%;
    height: 306px;
    position: relative;
    margin: auto;

    margin-bottom: 80px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;

    /* gap: 20px; */
}

.hrxdiv1 {
    width: 23%;
    height: 259px;

    margin-right: 48px;
    display: flex;
    flex-direction: column;
}

.hrxchdiv {
    width: 75%;
    display: flex;

    justify-content: space-between;
    /* display: flex;
      flex-direction: column; */
}

.hrxdiv2 {
    width: 31.6427783903%;
    height: 259px;

    /* margin-right: 50px; */
    border-radius: 21px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.man1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-bottom: 12px;
    /* display: flex; */
    /* align-items: center;
  justify-content: flex-start; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.man2 {
    color: #151515;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    display: flex;
    width: 278px;
    line-height: 22px;

    /* height: 48px; */
    /* align-items: center;
  justify-content: flex-start; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.man3 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-top: 7px;
    /* width: 278px; */
    /* height: 166px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.hrxdiv2 > .htw {
    width: 100%;
    height: 154px;
    /* border: 1px solid red ; */
    background: grey;
    border-radius: 21px 21px 0px 0px;
}

.dc1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    display: flex;

    margin-left: 16px;
    margin-top: 21px;
    margin-bottom: 25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.dct {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.dc2 {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    /* margin-bottom: 20px; */
}

.dc2::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    /* Initial thickness of underline */
    background-color: #ff5914;
    /* Initial underline color */
    transition: height 0.3s ease;
    /* Transition for thickness change */
}

.dc2:hover::after {
    height: 2px;
    /* Increase thickness on hover */
    background-color: #ff5914;
    /* Color of the thicker underline */
    bottom: -2px;
    width: 100%;
}

.apexpandyour {
    width: 71.4%;
    height: 287px;

    display: flex;
    margin: auto;
    margin-bottom: 80px;
    justify-content: space-between;
}

.apexpand1 {
    width: 64.7%;
    height: 100%;
    background: #ffffff;
    border-radius: 13px;
    box-shadow:
        0px 4px 6px 1px rgba(0, 0, 0, 0.05),
        -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
    padding-left: 74px;
}

.apexpand1p1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    position: relative;
    width: 90%;

    /* margin: auto; */
    margin-top: 48px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apexpand1p2 {
    color: #000000;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    margin-left: 0px;
    margin-top: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apemailinputbox {
    display: flex;

    width: 62.85%;
    justify-content: space-between;
    margin-left: 0px;
    margin-top: 45px;
    height: 32px;
}

#apputbox {
    /* background: transparent; */
    /* background: none; */
    border: none;
    /* Hide all borders */
    border-bottom: 1px solid #ff5914;
    width: 74.4%;
    color: #151515;

    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    font-weight: 500;

    padding-left: 15px;
}

.apemailinputbox > button {
    background: #ff5914;
    border-radius: 16px;
    width: 83px;
    /* height: 32px; */
    position: relative;
    color: #ffffff;
    text-align: center;
    font-family: 'Newheromedium', sans-serif;
    font-size: 12px;
    border: none;
}

.apexpand2 {
    width: 29.4%;
    height: 100%;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    display: flex;
    flex-direction: column;
}

.apexpand2p1 {
    color: #151515;
    text-align: left;
    font-family: 'NewheroRegular', sans-serif;
    font-size: 25px;
    font-weight: 400;
    position: relative;
    width: 77.5%;

    margin-left: 30px;
    margin-top: 48px;
    margin-bottom: 29px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.apexpand2p2 {
    color: #ff5914;
    text-align: left;
    font-family: 'Newheromedium', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    margin-left: 30px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    width: 78%;
}

.apexpand2p2::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0%;
    height: 1px;
    /* Initial thickness of underline */
    background-color: #ff5914;
    /* Initial underline color */
    transition: height 0.3s ease;
    /* Transition for thickness change */
}

.apexpand2p2:hover::after {
    height: 2px;
    /* Increase thickness on hover */
    background-color: #ff5914;
    /* Color of the thicker underline */
    bottom: -1px;
    width: 87%;
}

#topudug {
    display: none;
}

.hgemplov {
    display: none;
}

.tnndiv1lov {
    display: none;
}

.product-section-div-m {
    display: none;
}

.hrxchdiv-mob {
    display: none;
}

.product-form {
    display: none;
}

@media (max-width: 767px) {
    .hrxchdiv-mob {
        display: block;
    }

    .animated-cursor {
        display: none;
    }

    #Product-container {
        width: 100%;
        height: auto;
        /* display: none; */

        /* overflow-y: auto; */
        /* background: #C4C4C4; */
        overflow-x: hidden;
        background: #f4f4f4;
    }

    .Pmain {
        margin: auto;
        width: 100%;
        height: auto;
        /* margin-top: 90px; */

        position: relative;
        display: flex;
        flex-direction: column;
        background: #f4f4f4;
        margin-top: 60px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .PbgBox {
        border-radius: 0px;
        width: 100%;
        height: 658px;

        margin: initial;

        overflow: hidden;
        position: relative;
        background: #c4c4c4;
        margin-bottom: 86px;
    }

    .pcdiv {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        /* justify-content: space-between; */

        margin-bottom: 50px;
        margin-top: 44px;
        gap: 0px;
    }

    .pcCat {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 25px;
        font-weight: 400;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        /* height: 44px; */

        /* padding-left: 10%; */
        margin: auto;
        margin-bottom: 19px;
        width: 80%;
    }

    .pclngText {
        display: flex;
        flex-direction: column;
        width: 80%;

        margin: auto;
    }

    .pc1t {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        /* width: 641px; */
        /* height: 126px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        line-height: 21px;
    }

    .cardbrc {
        width: 88%;
        height: 513px;

        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* gap: 15px; */
        /* padding-left: 6%;
    padding-right: 6%; */
        margin: auto;
        padding-top: 19px;
        border-radius: 20px;
        background: rgba(176, 181, 190, 0.05);
        margin-bottom: 54px;
    }

    .product-Hub-logo-div {
        height: 58.2px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0px;
    }

    .product-logo-div {
        width: 98px;
        height: 100%;
        background-image: url(../Assests/lo2.jpg);
        background-position: cover;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 8px;
    }

    .product-vertical-line {
        border-style: solid;
        border-color: #000000;
        border-width: 0.5px;

        height: 35px;
        position: relative;
        margin-right: 14px;
    }

    .product-hub-text {
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 25px;
        font-weight: 500;
        position: relative;
    }

    .product-exp-offering {
        color: #151515;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 25px;
        line-height: 40px;
        font-weight: 400;
        position: relative;
        padding-left: initial;
        margin-top: 39px;
        margin-bottom: 21px;
        width: 100%;
    }

    .product-section-div-m {
        display: block;
    }

    .product-section-div {
        display: none;
    }

    .brnd-link {
        /* width: 285px;
    height: 260px; */
        text-decoration: none;
    }

    .cardbr1 {
        width: 310px;
        height: 260px;
        border-radius: 21px;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #ffffff;
        color: #000000;
        /* padding-top: 17px;
      padding-bottom: 17px; */
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin: auto;
        margin-bottom: 52px;
    }

    .cardbr1 > div {
        height: 148px;
        width: 100%;
        background: grey;
        border-radius: 21px 21px 0px 0px;
    }

    .br1 {
        color: #151515;
        /* text-align: left; */
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        margin-top: 13px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br2 {
        color: #5f5f5f;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        margin: auto;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 94%;
        line-height: 20px;
        margin-top: 0px;
        margin-bottom: 2px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br3a {
        color: #d00d38;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        /* margin-top: 3px; */
        margin-bottom: 11px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br3a::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #d00d38;
        transition: height 0.3s ease;
        margin-left: 25%;
    }

    .br3a:hover::after {
        height: 2px;
        background-color: #d00d38;
        bottom: -1px;
        width: 51%;
    }

    .br3b {
        color: #d00d38;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 700;
        position: relative;
        /* margin-top: 3px; */
        margin-bottom: 11px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br3b::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #d00d38;
        transition: height 0.3s ease;
        margin-left: 30%;
    }

    .br3b:hover::after {
        height: 2px;
        background-color: #d00d38;
        bottom: -1px;
        width: 40%;
    }

    .br3c {
        color: #d00d38;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 700;
        position: relative;
        /* margin-top: 3px; */
        margin-bottom: 11px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br3c::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #d00d38;
        transition: height 0.3s ease;
        margin-left: 25%;
    }

    .br3c:hover::after {
        height: 2px;
        background-color: #d00d38;
        bottom: -1px;
        width: 50%;
    }

    .br3d {
        color: #d00d38;
        /* text-align: left; */
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 700;
        position: relative;
        /* margin-top: 3px; */
        margin-bottom: 11px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .br3d::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        background-color: #d00d38;
        transition: height 0.3s ease;
        margin-left: 25%;
    }

    .br3d:hover::after {
        height: 2px;
        background-color: #d00d38;
        bottom: -1px;
        width: 50%;
    }

    .product-container-f {
        display: flex;
        flex-direction: column;

        height: auto;
        width: 100%;
        margin: initial;
        margin-bottom: 49px;
        /* justify-content: space-between; */
    }

    .p-c-f-divL {
        width: 88%;
        height: 321px;
        border-radius: initial;
        background: grey;
        margin: auto;
        border-radius: 15px;
    }

    .p-c-f-divR {
        width: 81%;
        height: auto;
        border-radius: initial;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 15px;
        margin: auto;
        margin-bottom: 0px;
        margin-top: -40px;
    }

    .p-c-f-divR > button {
        border: none;
        background: #13c1dd;
        border-radius: 19px;
        width: 216px;
        height: 38px;
        position: relative;
        color: #fffefb;
        text-align: center;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        text-decoration: underline;

        margin-left: 18.5%;

        margin-top: 40px;
        margin-bottom: 40px;
    }

    .partner-p {
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        padding-left: 32px;
        margin-top: 21px;
        word-wrap: break-word;
    }

    .p-c-div-pr {
        width: 80%;
        height: auto;
        display: grid;

        grid-template-columns: repeat(1, 1fr);
        margin: auto;
        margin-top: initial;
        column-gap: initial;
        row-gap: initial;
        word-wrap: break-word;
        margin-top: 15px;
    }

    .p-c-div-pr > div {
        display: flex;
        flex-direction: column;
        height: auto;
        border: none;
        border-bottom: 1px solid #13c1dd;

        margin-top: initial;
    }

    .p-c-p1 {
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 15px;
        font-weight: 500;
        position: relative;
        margin-bottom: initial;
        word-wrap: break-word;
        /* margin-top: 15px; */
        margin-top: 25px;
    }

    .p-c-p2 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        margin-top: 5px;
        position: relative;
        margin-bottom: 25px;
        word-wrap: break-word;
        width: 85%;
    }

    /* .p-c-p3 {
    color: #83694f;
    text-align: left;
    font-family: "Newheromedium", sans-serif;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    word-wrap: break-word;
    margin-bottom: 15px;
  } */
    .hrxdiv {
        background: rgba(242, 242, 242, 0.5);
        border-radius: 20px;
        width: 100%;
        height: auto;
        position: relative;
        margin: initial;

        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: initial;
        padding-left: initial;
        padding-right: initial;

        /* gap: 20px; */
    }

    .hrxdiv1 {
        width: 88%;
        height: auto;

        margin-right: 0px;
        margin: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .hrxchdiv {
        display: none;
    }

    .hrxchdiv-mob {
    }

    .hrxdiv2 {
        width: 300px;
        height: 259px;

        /* margin-right: 50px; */
        border-radius: 21px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        margin: auto;
        margin-bottom: 50px;
    }

    .man1 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-bottom: 6px;
        /* display: flex; */
        /* align-items: center;
    justify-content: flex-start; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .man2 {
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        display: flex;
        width: 278px;
        line-height: 22px;

        /* height: 48px; */
        /* align-items: center;
    justify-content: flex-start; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .man3 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        margin-top: 6px;
        /* width: 278px; */
        /* height: 166px; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .hrxdiv2 > .htw {
        width: 100%;
        height: 154px;
        /* border: 1px solid red ; */
        background: grey;
        border-radius: 21px 21px 0px 0px;
    }

    .dc1 {
        color: #151515;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        display: flex;

        margin-left: 16px;
        margin-top: 21px;
        margin-bottom: 25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    .dct {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .dc2 {
        color: #ff5914;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        /* margin-bottom: 20px; */
    }

    .dc2::after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 0%;
        height: 1px;
        /* Initial thickness of underline */
        background-color: #13c1dd;
        /* Initial underline color */
        transition: height 0.3s ease;
        /* Transition for thickness change */
    }

    .dc2:hover::after {
        height: 2px;
        /* Increase thickness on hover */
        background-color: #13c1dd;
        /* Color of the thicker underline */
        bottom: -2px;
        width: 100%;
    }

    .apexpandyour {
        display: none;
    }

    .product-form {
        width: 88%;
        height: 464px;
        background: #ffffff;
        border-radius: 13px;
        box-shadow:
            0px 4px 6px 1px rgba(0, 0, 0, 0.05),
            -4px -4px 6px 1px rgba(0, 0, 0, 0.05);
        margin: auto;
        display: flex;
        flex-direction: column;

        padding-bottom: 27px;
        margin-bottom: 50px;
    }

    .form-p1 {
        width: 86.6%;
        color: #000000;
        text-align: left;
        font-family: 'NewheroRegular', sans-serif;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin: auto;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .form-p2 {
        color: #000000;
        text-align: left;
        font-family: 'NewHero-Regular', sans-serif;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        width: 86.6%;
        margin: auto;

        margin-bottom: 15px;
    }

    .product-form > input {
        height: 30px;
        width: 86.6%;
        margin: auto;
        margin-bottom: 19px;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form > textarea {
        width: 86.6%;
        height: 68px;
        margin: auto;
        color: #151515;
        text-align: left;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        border: none;
        border-bottom: 1px solid #13c1dd;
    }

    .product-form > button {
        border: none;
        background: #13c1dd;
        border-radius: 16px;
        width: 83px;
        height: 32px;
        color: #ffffff;
        text-align: center;
        font-family: 'Newheromedium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 6.7%;
    }
}
