* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.container {
    width: 100%;
    height: auto;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    /* background-color: #fffdf8; */
}

#navbar {
    width: 100%;
    height: 70px;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 60px;
    background-color: black;
    position: sticky;
    top: 0;
    z-index: 1;
}

#navbar > .textHello {
    /* border: 1px solid green; */
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

#navbar > .navtext {
    /* border: 1px solid green; */
    font-size: 12px;
    font-weight: 400;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.topBox1 {
    display: flex;
    /* margin-left: 60px; */
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;

    /* margin-bottom: 15px; */
    margin-top: 20px;
    /* margin-bottom: 10px; */
}

.para1 {
    font-size: 45px;
    font-weight: 550;
    font-family: 'NewHero', sans-serif;
}

.nutTxt1 {
    font-size: 45px;
    font-weight: 600;
    font-family: 'NewHero', sans-serif;
    color: #a083bc;
}

.pribox11 {
    /* border: 1px solid green; */
    width: 64%;
    height: 135px;
}

.pribox21 {
    /* width: 26%; */
    height: 52px;
    /* border: 1px solid green; */
    /* display: flex; */
}

.boxcalender1 {
    width: 26%;
    margin-top: 10px;
}

#pributtonBox10 {
    /* border: 1px solid green; */
    width: 40%;
    height: 40px;
    background-color: black;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.priiconx10 {
    width: 8%;
    height: 28%;
    /* border: 1px solid green; */
}

#pributtonBox10 > p {
    color: white;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    /* border: 1px solid green; */
    font-family: 'NewHero', sans-serif;
}

.card-link {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 30%;
    /* height: 100%; */
    /* height: 40%; */
    text-decoration: none;
    color: inherit;
}

.card-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
    justify-content: space-evenly;
    margin-bottom: 20px;
    background: #fffdf8;
    /* Hide the horizontal scroll bar */
    scrollbar-width: none;
    /* Firefox */

    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

.card-container::-webkit-scrollbar {
    display: none;
    /* Hide the scrollbar in webkit browsers */
}

.card:hover {
    transform: scale(1.05);
    /* Scale up the card on hover */

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.cLink {
    /* width: 30%; */
    text-decoration: none;
}

.card {
    flex: 0 0 auto;
    /* margin-right: 10px; */
    width: 30%;
    /* Adjust as needed */
    /* background-color: #fff;
  border: 1px solid #ddd; */
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

    border-radius: 32px;
    padding: 30px;
    box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.1);
    background: #fffdf8;

    transition:
        transform 0.3s,
        box-shadow 0.3s;
}

.card img {
    width: 100%;
    max-width: 100%;
    height: 20vh;

    border-radius: 20px;
}

.card-content {
    /* padding: 20px; */
    /* border: 1px solid green; */
    margin-top: 20px;
    line-height: 20px;
}

.card .title {
    font-size: 18px;
    font-family: 'NewHero', sans-serif;
    font-weight: 600;
    color: #282828;
}

.card .descrip {
    font-size: 10px;
    font-weight: 400;
    color: #282828;
    font-family: 'NewHero', sans-serif;
    /* word-wrap:nowrap; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .explr {
    font-size: 11px;
    font-weight: 600;
    color: black;
    font-family: 'NewHero', sans-serif;
}

.horzline {
    width: 90%;
    border: 0.5px solid black;
    margin: auto;
    margin-bottom: 20px;
}

.bottomBox {
    width: 95%;
    /* border: 1px solid ; */
    height: auto;
    display: flex;
    margin-bottom: 50px;
    margin: auto;
    justify-content: space-between;
    align-items: center;

    /* background-image: url(../Assests/botbanner.jpg);
    background-repeat: no-repeat; 
    background-size: 100% 100%; */
    /* background-position: center center;  */
    /* background-color: teal; */
    /* border-top-right-radius: 60px; */

    /* background-color: transparent;  */
    /* border-top-left-radius: 60px; */
}

.botboxL {
    width: 30%;
    height: 100%;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0) 100%); */
    /* backdrop-filter: blur(10px); */
    /* border-top-left-radius: 60px; */
    /* padding-top: 40px; */
}

.botlogobox {
    display: flex;
    width: 100%;
    height: 4rem;
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */
    /* margin-bottom: 15px; */
}

.logodiv {
    width: 30%;
    height: 100%;
}

.logox {
    width: 100%;
    height: 100%;
}

.nuttri {
    font-size: 15px;
    font-weight: 500;
    color: #1e1e1e;
    font-family: 'NewHero', sans-serif;
}

.vertical-line {
    height: 100%;
    width: 1px;
    background-color: #000;
    margin: 0 10px;
}

.trending {
    font-size: 15px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;

    margin-top: 15px;
}

.boxright {
    width: auto;

    display: flex;
    justify-content: space-evenly;
    /* padding-top: 40px; */
    /* align-items: center; */
}

.l1box {
    width: 15%;
    height: 15%;
}

.imagel {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.l2box {
    width: auto;
    /* height: 60%; */
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
}

.longtbox {
    width: 100%;

    height: auto;
    display: flex;
    margin-top: 10px;
}

.longt {
    font-size: 14px;
    font-family: 'NewHero', sans-serif;
    font-weight: 300;
    color: black;
    line-height: 25px;
}

.Tnavbar {
    display: none;
}

@media (max-width: 767px) {
    /* Styles for screens with a maximum width of 767px */
    .container {
        /* Adjust styles for .container at this screen size */
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        background-color: #fffdf8;
    }

    .Tnavbar {
        display: initial;
        width: 100%;
        height: 60px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .vectorN {
        width: 40px;
        height: 30px;
        /* margin-left: 4%; */
        margin-right: 5px;
    }

    .pBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 10px;
    }

    .hub1 {
        font-size: 10px;
        font-weight: 900;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    .hub2 {
        font-size: 13px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    #navbar {
        /* Adjust styles for #navbar at this screen size */
        width: 100%;
        height: 70px;
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 20px;
        background-color: black;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .topBox1 {
        /* Adjust styles for .topBox1 at this screen size */

        display: flex;
        flex-direction: column;
        padding-right: 20px;
        padding-left: 20px;

        /* margin-bottom: 15px; */
        margin-top: 10px;
    }

    .para1 {
        /* Adjust styles for .para1 at this screen size */

        font-size: 25px;
        width: 100%;
        font-weight: 550;
        font-family: 'NewHero', sans-serif;
    }

    .nutTxt1 {
        font-size: 25px;
        font-weight: 600;
        font-family: 'NewHero', sans-serif;
        color: #a083bc;
    }

    .boxcalender1 {
        /* Adjust styles for .boxcalender1 at this screen size */
        /* border: 1px solid teal; */
        width: 100%;
    }

    .pribox11 {
        width: 100%;
        /* border: 2px solid red; */
        height: 70px;
        /* Adjust styles for .pribox11 at this screen size */
    }

    .pribox21 {
        /* width: 26%; */
        height: 40px;

        margin-bottom: 10px;
        /* display: flex; */
    }

    #pributtonBox10 {
        display: none;
        margin-top: 0px;

        /* Adjust styles for #pributtonBox10 at this screen size */
    }

    .cLink {
        /* width: 80%; */
        text-decoration: none;
    }

    .card {
        width: 90%;
        /* Full-width on small screens */

        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .card-container {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 20px;

        /* padding-left: 60px; */
        justify-content: initial;
        gap: 30px;
    }

    .bottomBox {
        display: none;
    }

    .botboxL {
        display: none;
        /* Adjust styles for .botboxL at this screen size */
    }

    .botlogobox {
        display: none;
        /* Adjust styles for .botlogobox at this screen size */
    }

    .l1box {
        /* Adjust styles for .l1box at this screen size */
    }

    .l2box {
        display: none;
        /* Adjust styles for .l2box at this screen size */
    }

    .longtbox {
        display: none;
        /* Adjust styles for .longtbox at this screen size */
    }

    .longt {
        display: none;
        /* Adjust styles for .longt at this screen size */
    }
}

.vertical-line {
    display: none;
}
