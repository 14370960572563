/* BottomNavigationBar.css */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.bottomNavigationBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fffdf8;

    /* padding: 10px;  */
    /* border: 2px solid red; */
    height: 80px;
    width: 100%;
    background: '#FEFCF7';
    box-shadow: '0px 0px 20px rgba(0, 0, 0, 0.15)';
    padding-left: 150px;
    padding-right: 150px;
    /* margin-top: 55vh; */
    /* position: relative; */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    position: fixed;
    /* Set to fixed */
    bottom: 0;
    /* Stick it to the bottom */
    z-index: 1;
}

.navItem {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    /* font-size: 14px;  */
}

.icon {
    /* margin-bottom: 2px;  */
    width: 35px;
    height: 35px;
}

.navBarItem {
    /* width: 34px;
    height: 15px; */
    color: 'black';
    font-size: 8px;
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
}

@media (max-width: 767px) {
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    .bottomNavigationBar {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fffdf8;

        /* padding: 10px;  */
        /* border: 2px solid red; */
        height: 80px;
        width: 100%;
        background: '#FEFCF7';
        box-shadow: '0px 0px 20px rgba(0, 0, 0, 0.15)';
        padding-left: 0px;
        padding-right: 0px;
        /* margin-top: 55vh; */
        /* position: relative; */
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        position: fixed;
        bottom: 0;
        z-index: 1;
    }
}
