* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#PresurgeryContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background: #fffdf8;
}

#preNavbar {
    width: 100%;
    height: 70px;
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: fixed; */
    margin-bottom: 40px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.prnavTextbox {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
}

.prvector {
    width: 32px;
    height: 25px;
    margin-left: 4%;
    margin-right: 5px;
}

.prtextHello1 {
    font-size: 20px;
    font-weight: 500;
    color: #fffdf8;
    font-family: 'NewHero', sans-serif;
}

.prnavtext1 {
    font-size: 12px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
}

.prebox1 {
    width: 100%;
    height: 500px;

    display: flex;
    /* grid-template-columns: repeat(2,1fr); */
    /* flex-direction: column; */
    padding-left: 60px;
    padding-right: 60px;
    gap: 60px;
}

.preboxc1 {
    height: 100%;
    width: 50%;

    background: #c4c4c4;
}

.preboxc2 {
    height: 100%;
    width: 50%;
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
}

.preT1 {
    font-size: 32px;
    font-weight: 600;
    font-family: 'NewHero', sans-serif;
    color: #232323;
}

.icondivo {
    /* border: 1px solid green; */
    /* height: 17px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

.star-icon {
    /* border: 1px solid green; */
    color: gold;
    font-size: 14px;
}

.LongText {
    font-size: 13px;
    font-weight: 400;
    font-family: 'NewHero', sans-serif;
    color: #3a544f;
    margin-bottom: 20px;
}

#slect1 {
    height: 25px;
    font-size: 11px;
    /* margin-bottom: 10px; */
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    background: #fffdf8;
    font-family: 'NewHero', sans-serif;
    border-top: 0.5px solid #a0a0a0;
}

.xc {
    width: 50%;
}

#slect2 {
    height: 25px;
    font-size: 11px;
    /* margin-bottom: 10px; */
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    background: #fffdf8;
    font-family: 'NewHero', sans-serif;
    border-top: 0.5px solid #a0a0a0;
}

#slect3 {
    height: 25px;
    font-size: 11px;
    /* margin-bottom: 10px; */
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    background: #fffdf8;
    font-family: 'NewHero', sans-serif;
    border-top: 0.5px solid #a0a0a0;
}

#slect4 {
    height: 25px;
    font-size: 11px;
    /* margin-bottom: 10px; */
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    background: #fffdf8;
    font-family: 'NewHero', sans-serif;
    border-top: 0.5px solid #a0a0a0;
}

.pox {
    margin-top: 30px;
    margin-bottom: 40px;
    width: 80%;
    margin-left: 60px;
    align-items: center;
    font-size: 55px;
    font-weight: 500;
    font-family: 'NewHero', sans-serif;
    color: #222222;
}

.prdtsc {
    color: #a083bc;
}

.cardContainer1 {
    width: 100%;
    /* height: 600px; */
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    /* display: grid;
   
   grid-template-columns: repeat(3,1fr); */
    padding-left: 60px;
    padding-right: 60px;
    /* padding: 20px; */
    gap: 60px;
    background: #fffdf8;
    /* height: 200px; */
    /* padding-bottom: 20px; */
    /* margin-top: 80px; */

    margin: auto;
    margin-bottom: 130px;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    /* Enable touch scrolling */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Hide scrollbar in IE/Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.CardBox1 {
    /* border: 1px solid green; */
    background: #fffdf8;
}

.ImageCard1:hover {
    transform: scale(1.05);
}

.ImageCard1 {
    height: 250px;
    border-radius: 25px;
    background: #d9d9d9;
}

.Cp11 {
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    font-family: 'NewHero', sans-serif;

    /* margin-top: 25px;
   margin-bottom: 15px; */
    padding-top: 20px;
}

.Cp21 {
    font-size: 12px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
    /* margin-bottom: 20px; */
    padding-top: 10px;
}

.Cp31 {
    font-size: 12px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
    /* margin-bottom: 20px; */
    padding-top: 10px;
    padding-bottom: 40px;
}

.buttondiv1:hover {
    transform: scale(1.05);
}

.buttonlink1 {
    /* width: 45%; */
    text-decoration: none;
}

.buttondiv1 {
    width: 45%;
    border: 1px #a0a0a0 solid;
    background: rgba(217, 217, 217, 0);
    height: 45px;
    border-radius: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 20px; */
}

.Cp41 {
    font-size: 12px;
    font-weight: 400;
    color: black;
    font-family: 'NewHero', sans-serif;
}

.Tnavbar5 {
    display: none;
}

@media (max-width: 767px) {
    .Tnavbar5 {
        display: initial;
        width: 100%;
        height: 60px;

        display: flex;
        background: #fffdf8;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .vectorN5 {
        width: 40px;
        height: 30px;
        /* margin-left: 4%; */
        margin-right: 5px;
    }

    .pBox5 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 5px;
        margin: auto;
    }

    .hubz3 {
        font-size: 12px;
        font-weight: 600;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    .hubz4 {
        font-size: 15px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }

    #preNavbar {
        width: 100%;
        height: 70px;
        background-color: black;
        color: white;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* position: fixed; */
        margin-bottom: 10px;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .prnavTextbox {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding-left: 20px;
    }

    .prvector {
        display: none;

        width: 32px;
        height: 25px;
        margin-left: 4%;
        margin-right: 5px;
    }

    .prebox1 {
        width: 100%;
        height: auto;

        display: flex;
        /* grid-template-columns: repeat(2,1fr); */
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        gap: 20px;
    }

    .preboxc1 {
        height: 350px;
        width: 100%;

        background: #c4c4c4;
    }

    .preboxc2 {
        height: 100%;
        width: 100%;
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
    }

    .preT1 {
        font-size: 25px;
        font-weight: 600;
        font-family: 'NewHero', sans-serif;
        color: #232323;
    }

    .icondivo {
        /* border: 1px solid green; */
        /* height: 17px; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
    }

    .star-icon {
        /* border: 1px solid green; */
        color: gold;
        font-size: 14px;
    }

    .LongText {
        font-size: 9px;
        font-weight: 400;
        font-family: 'NewHero', sans-serif;
        color: #3a544f;
        margin-bottom: 20px;
    }

    #slect1 {
        height: 25px;
        font-size: 11px;
        /* margin-bottom: 10px; */
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        background: #fffdf8;
        font-family: 'NewHero', sans-serif;
        border-top: 0.5px solid #a0a0a0;
    }

    .xc {
        width: 50%;
    }

    #slect2 {
        height: 25px;
        font-size: 11px;
        /* margin-bottom: 10px; */
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        background: #fffdf8;
        font-family: 'NewHero', sans-serif;
        border-top: 0.5px solid #a0a0a0;
    }

    #slect3 {
        height: 25px;
        font-size: 11px;
        /* margin-bottom: 10px; */
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        background: #fffdf8;
        font-family: 'NewHero', sans-serif;
        border-top: 0.5px solid #a0a0a0;
    }

    #slect4 {
        height: 25px;
        font-size: 11px;
        /* margin-bottom: 10px; */
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        background: #fffdf8;
        font-family: 'NewHero', sans-serif;
        border-top: 0.5px solid #a0a0a0;
    }

    .pox {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 80%;
        margin-left: 25px;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
        font-family: 'NewHero', sans-serif;
        color: #222222;
    }

    .prdtsc {
        color: #a083bc;
    }

    .cardContainer1 {
        width: 100%;
        /* height: 600px; */
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        /* display: grid;
  
  grid-template-columns: repeat(3,1fr); */
        padding-left: 30px;
        padding-right: 30px;
        /* padding: 20px; */
        gap: 40px;
        background: #fffdf8;
        /* height: 200px; */
        /* padding-bottom: 20px; */
        /* margin-top: 80px; */

        margin: auto;
        margin-bottom: 130px;
    }

    .cardContainer1::-webkit-scrollbar {
        width: 0;
    }

    .CardBox1 {
        /* border: 1px solid green; */
        background: #fffdf8;
    }

    .ImageCard1:hover {
        transform: scale(1.05);
    }

    .ImageCard1 {
        height: 110px;
        border-radius: 16px;
        background: #d9d9d9;
    }

    .Cp11 {
        font-size: 10px;
        font-weight: 600;
        color: #222222;
        font-family: 'NewHero', sans-serif;

        /* margin-top: 25px;
  margin-bottom: 15px; */
        padding-top: 20px;
        word-wrap: break-word;
    }

    .Cp21 {
        font-size: 8px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
        /* margin-bottom: 20px; */
        padding-top: 10px;
        word-wrap: break-word;
    }

    .Cp31 {
        font-size: 6px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
        /* margin-bottom: 20px; */
        padding-top: 10px;
        padding-bottom: 10px;
        /* white-space: nowrap; */
    }

    .buttondiv1:hover {
        transform: scale(1.05);
    }

    .buttonlink1 {
        /* width: 45%; */
        text-decoration: none;
    }

    .buttondiv1 {
        width: 70%;
        border: 1px #a0a0a0 solid;
        background: rgba(217, 217, 217, 0);
        height: 35px;
        border-radius: 22.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-bottom: 20px; */
    }

    .Cp41 {
        font-size: 10px;
        font-weight: 400;
        color: black;
        font-family: 'NewHero', sans-serif;
    }
}
